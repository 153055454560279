<template>
  <div class="main-content">
    <Header message="Мандатная статистика (Админ)" />
    <BreadCrumps />
    <div class="statistics" >
      <div class="statistics__container" v-if="allAnalytics !== null">
        <div class="statistics__row">
          <!-- Общее количество участников -->
          <div class="statistics__card">
            <div class="statistics-card__title">
              <p class="text-title-20">Общее количество участников</p>
            </div>
            <div class="statistics-card__title">
              <p class="text-title-32">{{ allAnalytics.totalSportsmenCount }} человек</p>
            </div>
            <PieChartIndividual />
          </div>

          <!-- Общее количество ИП -->
          <div class="statistics__card">
            <div class="statistics-card__title">
              <p class="text-title-20">Общее количество ИП</p>
            </div>
            <div class="statistics-card__title">
              <p class="text-title-32">{{ individualTotalCount }} человек</p>
            </div>
            <div class="statistics-card__description">
              <div class="statistics-card__title">
                <p class="text-title-14">Статистика зарегистрированных по разрядам в ИП</p>
              </div>
              <ul class="statistics-list">
                <li class="statistics-list__item" v-for="rank in individualRankCounts" :key="rank.rankName">
                  Общее кол-во {{ rank.rankName }}: {{ rank.count }}
                </li>
              </ul>
            </div>
          </div>

          <!-- Общее количество ГУ -->
          <div class="statistics__card">
            <div class="statistics-card__title">
              <p class="text-title-20">Общее количество ГУ</p>
            </div>
            <div class="statistics-card__title">
              <p class="text-title-32">{{ groupTotalCount }} групп</p>
            </div>
            <div class="statistics__row">
              <div class="statistics-card__title" v-for="type in groupTypes" :key="type.type">
                <img :src="getTypeImage(type.type)" class="statistics-card__img" :alt="type.name" />
                <p class="text-title-16">{{ type.name }}: {{ type.totalCount }}</p>
              </div>
            </div>
            <div class="statistics-card__description">
              <div class="statistics-card__title">
                <p class="text-title-14">Статистика зарегистрированных по разрядам в ГУ</p>
              </div>
              <ul class="statistics-list">
                <li class="statistics-list__item" v-for="rank in groupRankCounts" :key="rank.rankName">
                  Общее кол-во {{ rank.rankName }}: {{ rank.count }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <AppStatistics :app_stat="allAnalytics.registeredSportsmenInCategoryResponses" />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import Header from "../../components/Header.vue";
  import BreadCrumps from "@/components/BreadCrumps.vue";
  import AppStatistics from "@/components/Mandated/Statistics.vue";
  import { actionTypesStatistics } from "@/store/modules/statistics";
  import PieChartIndividual from "@/components/Statistics/PieChartIndividual.vue";

  export default {
    components: { PieChartIndividual, AppStatistics, BreadCrumps, Header },
    name: "AppMandatedStatistics",
    data() {
      return {
        isMandated: true,
      };
    },
    computed: {
      ...mapState({
        isLoading: (state) => state.competition.isLoading,
        allStatistics: (state) => state.statistics.allStatistics,
        allAnalytics: (state) => state.statistics.allAnalytics,
      }),

      // Вычисления на основе данных
      individualTotalCount() {
        const individual = this.allAnalytics.totalCountType.find((item) => item.type === "INDIVIDUAL");
        return individual ? individual.totalCount : 0;
      },

      groupTotalCount() {
        const relevantTypes = ["GROUP", "DUO", "TRIO"];
        return this.allAnalytics.totalCountType
            .filter((item) => relevantTypes.includes(item.type))
            .reduce((sum, item) => sum + item.totalCount, 0);
      },

      individualRankCounts() {
        return this.allAnalytics.individualRankCounts || [];
      },

      groupRankCounts() {
        return this.allAnalytics.groupRankCounts || [];
      },

      groupTypes() {
        return this.allAnalytics.totalCountType.filter((type) =>
            ["GROUP", "TRIO", "DUO"].includes(type.type)
        );
      },
    },
    methods: {
      getTypeImage(type) {
        const images = {
          GROUP: require("../../assets/svg/Group.svg"),
          TRIO: require("../../assets/svg/Trio.svg"),
          DUO: require("../../assets/svg/Duo.svg"),
        };
        return images[type] || "";
      },
    },
    mounted() {
      this.$store
          .dispatch(actionTypesStatistics.getStatistics, this.$route.params.id)
          .catch((err) => console.error("Ошибка загрузки статистики:", err));

      this.$store
          .dispatch(actionTypesStatistics.getAnalytics, this.$route.params.id)
          .catch((err) => console.error("Ошибка загрузки аналитики:", err));
    },
  };
</script>
