<template>
  <tbody>
  <tr>
    <td class="table__icon-btn">
      <span class="table__icon-edit"  @click="editCoach()">✏️</span>
      <span class="table__icon-delete"  @click="deleteCoach()">🗑️</span>
    </td>

    <td class="">
      {{ item_coach.surname }} {{ item_coach.coachName }}
      {{ item_coach.patronymic }}
    </td>
    <td class="">
      {{ item_coach.club }}
    </td>
  </tr>
  </tbody>
</template>

<script>
export default {
  name: "AppItemTableSportsmen",
  data() {
    return {
      checkedP: false,
    };
  },
  props: {
    item_coach: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    checkedCoach: {
      get() {
        return this.value;
      },
      set(val) {
        console.log(val, "val");
        this.checkedP = val;
      },
    },
  },
  methods: {
    onChange() {
      this.$emit("input", this.checkedP);
      this.$emit("updateCheck");
    },
    deleteCoach() {
      this.$emit("deleteCoach", this.item_coach);
    },
    editCoach() {
      this.$emit("editCoach", this.item_coach);
    },
  },
};
</script>

<style></style>
