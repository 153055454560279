<template>
  <transition name="modal" @click="$emit('close')">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <div class="modal__cross" @click="$emit('close')">
              <img src="../../assets/svg/cross.svg" alt="cross" />
            </div>
          </div>
          <form
              @submit.prevent="safeInfoSportsmen"
              id="#modalEditSportsmanPerformance"
          >
            <div class="modal-body">
              <div class="modal-body__title">
                <p class="modal__title">Изменение выступления спортсмена</p>
              </div>
              <div class="modal-scroll-max">
                <div class="input-wrap__row">
                  <div class="input-wrap__label-with-input-row">
                    <div class="input-wrap__label">
                      <p class="text-label">Фамилия:</p>
                    </div>
                    <input
                        type="text"
                        class="input-light-text"
                        placeholder="Заполните фамилию"
                        :class="{
                      'error-border-input':
                        isSubmitted && $v.putData.surname.$error,
                    }"
                        name="surname"
                        v-model="item_sportsmen.surname"
                    />
                    <img
                        src="~@/assets/svg/Error.svg"
                        alt="error"
                        v-if="isSubmitted && $v.putData.surname.$error"
                        class="wrapValidationInput__error"
                    />
                  </div>
                  <div class="input-wrap__label-with-input-row">
                    <div class="input-wrap__label">
                      <p class="text-label">Имя:</p>
                    </div>
                    <input
                        type="text"
                        class="input-light-text"
                        :class="{
                      'error-border-input':
                        isSubmitted && $v.putData.name.$error,
                    }"
                        placeholder="Заполните имя"
                        name="name"
                        v-model="item_sportsmen.name"
                    />
                    <img
                        src="~@/assets/svg/Error.svg"
                        alt="error"
                        v-if="isSubmitted && $v.putData.name.$error"
                        class="wrapValidationInput__error"
                    />
                  </div>
                </div>
                <div class="input-wrap__row">
                  <div class="input-wrap__label-with-input-row">
                    <div class="input-wrap__label">
                      <p class="text-label">Отчество:</p>
                    </div>
                    <input
                        type="text"
                        class="input-light-text"
                        :class="{
                      'error-border-input': isSubmitted && putData.patronymic.$error,
                    }"
                        placeholder="Заполните отчество"
                        name="patronymic"
                        v-model="item_sportsmen.patronymic"
                    />
                    <img
                        src="~@/assets/svg/Error.svg"
                        alt="error"
                        v-if="isSubmitted && $v.putData.patronymic.$error"
                        class="wrapValidationInput__error"
                    />
                  </div>
                </div>
                <div class="input-wrap__row">
                  <div class="input-wrap__label-with-input-row">
                    <div class="input-wrap__label">
                      <p class="text-label">Дата рождения:</p>
                    </div>
                    <input
                        type="text"
                        name="dateOfBirth"
                        class="input-light-text"
                        placeholder="01.01.2000"
                        v-mask="'##.##.####'"
                        :class="{
                      'error-border-input':
                        isSubmitted && $v.putData.dateOfBirth.$error,
                    }"
                        v-model.lazy="item_sportsmen.dateOfBirth"
                        @input="handleInputDate"
                    />

                  </div>
                  <div class="input-wrap__label-with-input-row">
                    <div class="input-wrap__label">
                      <p class="text-label">Разряд при наличии:</p>
                    </div>
                    <custom-select-rank
                        v-if="this.selectRank !== null"
                        @input="safeRank"
                        :default="item_sportsmen.rank"
                        :options="this.selectRank"
                    />
                  </div>
                </div>
                <div class="modal-body__add-coach">
                  <div class="modal-body-add-coach">
                    <div class="modal-body-add-coach__title-with-svg" @click="addCoachEmpty">
                      <p class="modal-body-add-coach__title">Добавить тренера</p>
                      <div class="modal-body-add-coach__wrap-svg">
                        <img src="../../assets/svg/plus.svg" alt="plus" />
                      </div>
                    </div>
                    <AppItemCoachInMandated v-for="(coach, index) in this.item_sportsmen.coaches" :item_coach="coach"  :idx="index" :key="index"  @deleteCoach="deleteCoach(index)"/>
                  </div>
                </div>
                <div class="input-wrap__row">
                  <div class="input-wrap__label-with-input-row">
                    <div class="input-wrap__label">
                      <p class="text-label">Область/регион:</p>
                    </div>
                    <AppRegionSelect @regionSelected="safeRegion"  :default="item_sportsmen.region"/>
                  </div>
                  <div class="input-wrap__label-with-input-row">
                    <div class="input-wrap__label">
                      <p class="text-label">Город:</p>
                    </div>
                    <AppCitySelect :idRegion="region.id"  @citySelected="safeCity"  :default="item_sportsmen.city" />
                  </div>
                </div>
              </div>

            </div>
            <div class="modal-footer">
              <div class="modal-footer__button">
                <button class="mandated__tag dark">Сохранить</button>
              </div>
              <div class="modal-footer__button">
                <button class="mandated__tag button-cancel" @click="$emit('close')">
                  Отменить
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import { actionTypes } from "@/store/modules/registration";
  import { actionTypesMandated } from "@/store/modules/mandated";
  // import { actionTypesEntered } from "@/store/modules/enteredData";
  import { mapState } from "vuex";
  // import TableCoach from "../TableInModal/TableCoach.vue";
  import CustomSelectRank from "../customSelect/CustomSelectRank.vue";
  import { required } from "vuelidate/lib/validators";
  import AppRegionSelect from '@/components/customSelect/RegionSelect.vue'
  import AppCitySelect from '@/components/customSelect/CitySelect.vue'
  import AppItemCoachInMandated from '@/components/Mandated/ItemCoachInMandated.vue'
  export default {
    name: "AppModalEditSportsmanPerformance",
    data() {
      return {
        city:  this.item_sportsmen.city
            ? this.item_sportsmen.city
            : '',
        region:  this.item_sportsmen.region
            ? this.item_sportsmen.region
            : '',
        rank: "",
        selectRegion: ["Новосибирская область"],
        selectCity: ["Новосибирск","Краснообск", "Бердск", "Академгородок"],
        putData: [],
        checkedCoachID: [],
        isSubmitted: false,
      };
    },
    validations: {
      putData: {
        name: {
          required,
        },
        patronymic: {
          // required
        },
        surname: {
          required,
        },
        dateOfBirth: {
          required,
        },
      },
    },
    components: {
      AppItemCoachInMandated,
      AppCitySelect,
      AppRegionSelect,
      // TableCoach,
      CustomSelectRank,
    },
    props: {
      item_sportsmen: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    computed: {
      ...mapState({
        isLoading: (state) => state.registration.isLoading,
        selectRank: (state) => state.registration.selectRank,
      }),
    },
    methods: {
      addCoachEmpty() {
        if ( this.item_sportsmen.coaches.length < 5) {
          this.item_sportsmen.coaches.unshift({
            coachName: '',
            surname: '',
            patronymic: '',
            club: '',
            isNew: true // Указываем, что тренер новый
          });
        } else {
          alert('Максимальное количество тренеров - 5.');
        }
      },
      deleteCoach(index) {
        this.item_sportsmen.coaches.splice(index, 1);
      },
      saveChanges() {
        // Здесь можно добавить логику для сохранения изменений, например, отправку данных на сервер.
        // console.log('Изменения сохранены',  this.item_sportsmen.coaches);
      },
      checkedCoach(item) {
        this.checkedCoachID = item;
      },
      handleInputDate(event) {
        // Удаляем любые символы кроме цифр и точек.
        const cleanValue = event.target.value.replace(/[^0-9.]/g, '');
        // В данном случае формат должен содержать ровно 10 символов (две цифры для дня, месяца, года и две точки).
        if (cleanValue.length > 10) {
          event.target.value = cleanValue.slice(0, 10);
        } else {
          event.target.value = cleanValue;
        }

        this.item_sportsmen.dateOfBirth = event.target.value;
      },
      safeInfoSportsmen() {
        this.putData = {
          name: this.item_sportsmen.name,
          surname: this.item_sportsmen.surname,
          patronymic: this.item_sportsmen.patronymic,
          region: this.region.name || this.region,
          city: this.city,
          gender: this.item_sportsmen.gender,
          dateOfBirth: this.item_sportsmen.dateOfBirth,
          rank: this.rank.id,
          coaches: this.item_sportsmen.coaches,
          id: this.item_sportsmen.regSportsmenId,
        };

        this.isSubmitted = true;
        this.$v.$touch();

        if (this.$v.$invalid) {
          return console.log("Данные не заполнены", this.$v.$invalid);
        } else {
          this.$store
              .dispatch(actionTypesMandated.editPerformance, this.putData)
              .then(() => {
                this.$emit("close");
              })
              .catch((er) => {
                console.log("Ошибка", er);
              });
        }
      },
      addCoach() {
        this.$emit("addCoach");
      },
      safeCity(item) {
        // console.log(item, "city");
        this.city = item;
      },
      safeRegion(item) {
        // console.log(item, "region");
        this.region = item;
      },
      safeRank(item) {
        // console.log(item, "rank");
        this.rank = item;
      },

      //надо сделать getter?!?
      findRank(selectRank) {
        selectRank.map((item) => {
          if (this.item_sportsmen.rank === item.name) {
            return (this.rank = item);
          }
        });
      },
      //найти выбранные город
      findCity() {
        this.selectCity.map((item) => {
          if (this.item_sportsmen.city === item) {
            return (this.city = item);
          }
        });
      },
      findRegion() {
        this.selectRegion.map((item) => {
          // console.log(item);
          // console.log(this.item_sportsmen.region);
          if (this.item_sportsmen.region === item) {
            return (this.region = item);
          }
        });
      },
    },
    mounted() {
      this.findCity();
      this.findRegion();
      //получение все разрядов
      this.$store
          .dispatch(actionTypes.getRank)
          .then((response) => {
            // console.log(response);
            this.findRank(response.result);
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
    },
  };
</script>

<style></style>
