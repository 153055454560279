import mandatedAPi from "@/api/mandated.js";
// import { setItem, getItem } from "@/helpers/persistanceStorage";

const state = {
  isLoadingInfoMandated: false, //ожидание запроса
  isLoadingStatus: false,
  isLoadingStatusGroup: false,
  isLoadingAllSportsmen: false,
  isLoadingAllTrios: false,
  isLoadingAllDuos: false,
  isLoadingChange: false,
  isLoadingChangeGroup: false,
  isLoadingChangeTrio: false,
  validationErrors: null, //ошибки
  isUserRegistration: false,
  allInfoMandate: [],

  allSportsmens: [],
  allJudges: [],
  allCoaches: [],
  allGroups: null,
  allTrios: null,
  allDuos: null,
};

export const mutationTypes = {
  getAllInfoMandatedStart: "[mandated] getAllInfoMandatedStart",
  getAllInfoMandatedSuccess: "[mandated] getAllInfoMandatedSuccess",
  getAllInfoMandatedFailure: "[mandated] getAllInfoMandatedFailure",

  getAllSportsmenIndCategStart: "[mandated] getAllSportsmenIndCategStart",
  getAllSportsmenIndCategSuccess: "[mandated] getAllSportsmenIndCategSuccess",
  getAllSportsmenIndCategFailure: "[mandated] getAllSportsmenIndCategFailure",

  getAllGroupCategStart: "[mandated] getAllGroupCategStart",
  getAllGroupCategSuccess: "[mandated] getAllGroupCategSuccess",
  getAllGroupCategFailure: "[mandated] getAllGroupCategFailure",

  getAllTrioCategStart: "[mandated] getAllTrioCategStart",
  getAllTrioCategSuccess: "[mandated] getAllTrioCategSuccess",
  getAllTrioCategFailure: "[mandated] getAllTrioCategFailure",

  getAllDuoCategStart: "[mandated] getAllDuoCategStart",
  getAllDuoCategSuccess: "[mandated] getAllDuoCategSuccess",
  getAllDuoCategFailure: "[mandated] getAllDuoCategFailure",

  sortitionStart: "[mandated]  sortitionStart",
  sortitionSuccess: "[mandated]  sortitionSuccess",
  sortitionFailure: "[mandated]  sortitionFailure",

  changeStatusPerformanceStart: "[mandated]  changeStatusPerformanceStart",
  changeStatusPerformanceSuccess: "[mandated]  changeStatusPerformanceSuccess",
  changeStatusPerformanceFailure: "[mandated]  changeStatusPerformanceFailure",

  changeCategorySportsmanStart: "[mandated]  changeCategorySportsmanStart",
  changeCategorySportsmanSuccess: "[mandated]  changeCategorySportsmanSuccess",
  changeCategorySportsmanFailure: "[mandated]  changeCategorySportsmanFailure",

  editPerformanceStart: "[mandated]  editPerformanceStart",
  editPerformanceSuccess: "[mandated]  editPerformanceSuccess",
  editPerformanceFailure: "[mandated]  editPerformanceFailure",

  changeStatusPerformanceGroupStart: "[mandated]  changeStatusPerformanceGroupStart",
  changeStatusPerformanceGroupSuccess: "[mandated]  changeStatusPerformanceGroupSuccess",
  changeStatusPerformanceGroupFailure: "[mandated]  changeStatusPerformanceGroupFailure",

  changeStatusPerformanceTrioStart: "[mandated]  changeStatusPerformanceTrioStart",
  changeStatusPerformanceTrioSuccess: "[mandated]  changeStatusPerformanceTrioSuccess",
  changeStatusPerformanceTrioFailure: "[mandated]  changeStatusPerformanceTrioFailure",

  changeStatusPerformanceDuoStart: "[mandated]  changeStatusPerformanceDuoStart",
  changeStatusPerformanceDuoSuccess: "[mandated]  changeStatusPerformanceDuoSuccess",
  changeStatusPerformanceDuoFailure: "[mandated]  changeStatusPerformanceDuoFailure",

  changeCategoryGroupStart: "[mandated]  changeCategoryGroupStart",
  changeCategoryGroupSuccess: "[mandated]  changeCategoryGroupSuccess",
  changeCategoryGroupFailure: "[mandated]  changeCategoryGroupFailure",

  // changeCategoryTrio
  changeCategoryTrioStart: "[mandated]  changeCategoryTrioStart",
  changeCategoryTrioSuccess: "[mandated]  changeCategoryTrioSuccess",
  changeCategoryTrioFailure: "[mandated]  changeCategoryTrioFailure",

  changeCategoryDuoStart: "[mandated]  changeCategoryDuoStart",
  changeCategoryDuoSuccess: "[mandated]  changeCategoryDuoSuccess",
  changeCategoryDuoFailure: "[mandated]  changeCategoryDuoFailure",

  addSportsmanToCategoryStart: "[mandated]  addSportsmanToCategoryStart",
  addSportsmanToCategorySuccess: "[mandated]  addSportsmanToCategorySuccess",
  addSportsmanToCategoryFailure: "[mandated]  addSportsmanToCategoryFailure",

  addGroupToCategoryStart: "[mandated]  addGroupToCategoryStart",
  addGroupToCategorySuccess: "[mandated]  addGroupToCategorySuccess",
  addGroupToCategoryFailure: "[mandated]  addGroupToCategoryFailure",

  addTrioToCategoryStart: "[mandated]  addTrioToCategoryStart",
  addTrioToCategorySuccess: "[mandated]  addTrioToCategorySuccess",
  addTrioToCategoryFailure: "[mandated]  addTrioToCategoryFailure",

  addDuoToCategoryStart: "[mandated] addDuoToCategoryStart",
  addDuoToCategorySuccess: "[mandated]  addDuoToCategorySuccess",
  addDuoToCategoryFailure: "[mandated]  addDuoToCategoryFailure",

  addJudgeToCompetitionStart: "[mandated]  addJudgeToCompetitionStart",
  addJudgeToCompetitionSuccess: "[mandated]  addJudgeToCompetitionSuccess",
  addJudgeToCompetitionFailure: "[mandated]  addJudgeToCompetitionFailure",

  searchSportsmenStart: "[mandated]  searchSportsmenStart",
  searchSportsmenSuccess: "[mandated]  searchSportsmenSuccess",
  searchSportsmenFailure: "[mandated]  searchSportsmenFailure",

  editGroupPerformanceStart: "[mandated]   editGroupPerformanceStart",
  editGroupPerformanceSuccess: "[mandated]   editGroupPerformanceSuccess",
  editGroupPerformanceFailure: "[mandated]   editGroupPerformanceFailure",

  RESET_STATE_BY_CATEGORY: " [mandated] RESET_STATE_BY_CATEGORY"


};
export const actionTypesMandated = {
  getAllInfoMandated: "[mandated] getAllInfoMandated",
  getAllSportsmenIndCateg: "[mandated] getAllSportsmenIndCateg",
  getAllGroupCateg: "[mandated] getAllGroupCateg",
  getAllTrioCateg: "[mandated] getAllTrioCateg",
  getAllDuoCateg: "[mandated] getAllDuoCateg",
  sortition: "[mandated]  sortition",
  changeStatusPerformance: "[mandated]  changeStatusPerformance",
  changeCategorySportsman: "[mandated] changeCategorySportsman",
  editPerformance: "[mandated] editPerformance",
  editGroupPerformance: "[mandated] editGroupPerformance",
  changeStatusPerformanceGroup: "[mandated] changeStatusPerformanceGroup",
  changeStatusPerformanceTrio: "[mandated] changeStatusPerformanceTrio",
  changeStatusPerformanceDuo: "[mandated] changeStatusPerformanceDuo",
  changeCategoryGroup: "[mandated] changeCategoryGroup",
  changeCategoryTrio: "[mandated] changeCategoryTrio",
  changeCategoryDuo: " [mandated] changeCategoryDuo",
  addSportsmanToCategory: "[mandated] addSportsmanToCategory ",
  addGroupToCategory: "[mandated] addGroupToCategory",
  addTrioToCategory: "[mandated] addTrioToCategory",
  addDuoToCategory: "[mandated] addDuoToCategory",

  searchSportsmen: "[mandated] searchSportsmen",
  addJudgeToCompetition: "[mandated] addJudgeToCompetition" ,
  resetStateByCategory: "[mandated] resetStateByCategory" ,

};

const mutations = {
  [mutationTypes.getAllInfoMandatedStart](state) {
    state.validationErrors = null;
    state.isLoadingInfoMandated = true;
  },
  [mutationTypes.getAllInfoMandatedSuccess](state, payload) {
    state.isLoadingInfoMandated = false;
    state.allInfoMandate = payload.result;
  },
  [mutationTypes.getAllInfoMandatedFailure](state, payload) {
    state.isLoadingInfoMandated = false;
    state.validationErrors = payload;
  },
  [mutationTypes.getAllSportsmenIndCategStart](state) {
    state.validationErrors = null;
    state.isLoadingAllSportsmen = true;
    // state.allSportsmens = null;
    // state.allGroups = null;
  },
  [mutationTypes.getAllSportsmenIndCategSuccess](state, payload) {
    state.isLoadingAllSportsmen = false;
    state.allSportsmens = payload.result;
  },
  [mutationTypes.getAllSportsmenIndCategFailure](state, payload) {
    state.isLoadingAllSportsmen = false;
    state.validationErrors = payload;
  },

  [mutationTypes.getAllGroupCategStart](state) {
    state.validationErrors = null;
    state.isLoadingAllSportsmen = true;
  },
  [mutationTypes.getAllGroupCategSuccess](state, payload) {
    state.isLoadingAllSportsmen = false;
    state.allGroups = payload.result;
  },
  [mutationTypes.getAllGroupCategFailure](state, payload) {
    state.isLoadingAllSportsmen = false;
    state.validationErrors = payload;
  },

  // getAllTrioCateg
  [mutationTypes.getAllTrioCategStart](state) {
    state.validationErrors = null;
    state.isLoadingAllTrios= true;
  },
  [mutationTypes.getAllTrioCategSuccess](state, payload) {
    state.isLoadingAllTrios = false;
    state.allTrios = payload.result;
  },
  [mutationTypes.getAllTrioCategFailure](state, payload) {
    state.isLoadingAllTrios = false;
    state.validationErrors = payload;
  },
  // getAllDuoCateg
  [mutationTypes.getAllDuoCategStart](state) {
    state.validationErrors = null;
    state.isLoadingAllDuos= true;
  },
  [mutationTypes.getAllDuoCategSuccess](state, payload) {
    state.isLoadingAllDuos = false;
    state.allDuos = payload.result;
  },
  [mutationTypes.getAllDuoCategFailure](state, payload) {
    state.isLoadingAllDuos = false;
    state.validationErrors = payload;
  },

  [mutationTypes.sortitionStart](state) {
    state.validationErrors = null;
    state.isLoadingSort = true;
  },
  [mutationTypes.sortitionSuccess](state, statusDraw) {
    state.isLoadingSort = false;
    state.allInfoMandate.isDraw = statusDraw;
  },
  [mutationTypes.sortitionFailure](state, payload) {
    state.isLoadingSort = false;
    state.validationErrors = payload;
  },
  [mutationTypes.changeStatusPerformanceStart](state) {
    state.validationErrors = null;
    state.isLoadingStatus = true;
  },
  [mutationTypes.changeStatusPerformanceSuccess](state, result) {
    state.isLoadingStatus = false;
    // console.log(result)
    const index = state.allSportsmens.findIndex(
        (item) => item.sportsmen.id === result.sportsmen.id
    );
    if (index !== -1) {
      state.allSportsmens.splice(index, 1, result);
    }
  },
  [mutationTypes.changeStatusPerformanceFailure](state, payload) {
    state.isLoadingStatus = false;
    state.validationErrors = payload;
  },
  // changeStatusPerformanceGroup
  [mutationTypes.changeStatusPerformanceGroupStart](state) {
    state.validationErrors = null;
    state.isLoadingStatusGroup = true;
  },
  [mutationTypes.changeStatusPerformanceGroupSuccess](state, group) {
    console.log(group,'group')
    state.isLoadingStatusGroup = false;
    const index = state.allGroups.findIndex(
        (item) => item.group.regGroupId === group.group.regGroupId
    );
    if (index !== -1) {

      state.allGroups.splice(index, 1, group);
    }
  },
  [mutationTypes.changeStatusPerformanceGroupFailure](state, payload) {
    state.isLoadingStatusGroup = false;
    state.validationErrors = payload;
  },
  // changeStatusPerformanceTrio
  [mutationTypes.changeStatusPerformanceTrioStart](state) {
    state.validationErrors = null;
    state.isLoadingStatusGroup = true;
  },
  [mutationTypes.changeStatusPerformanceTrioSuccess](state, group) {
    console.log(group,'group')
    state.isLoadingStatusGroup = false;
    const index = state.allTrios.findIndex(
        (item) => item.group.regGroupId === group.group.regGroupId
    );
    if (index !== -1) {

      state.allTrios.splice(index, 1, group);
    }
  },
  [mutationTypes.changeStatusPerformanceTrioFailure](state, payload) {
    state.isLoadingStatusGroup = false;
    state.validationErrors = payload;
  },
  // changeStatusPerformanceDuo
  [mutationTypes.changeStatusPerformanceDuoStart](state) {
    state.validationErrors = null;
    state.isLoadingStatusGroup = true;
  },
  [mutationTypes.changeStatusPerformanceDuoSuccess](state, trio) {
    console.log(trio,'trio')
    state.isLoadingStatusGroup = false;
    const index = state.allDuos.findIndex(
        (item) => item.group.regGroupId === trio.group.regGroupId
    );
    if (index !== -1) {

      state.allDuos.splice(index, 1, trio);
    }
  },
  [mutationTypes.changeStatusPerformanceDuoFailure](state, payload) {
    state.isLoadingStatusGroup = false;
    state.validationErrors = payload;
  },
  //changeCategorySportsman
  [mutationTypes.changeCategorySportsmanStart](state) {
    state.validationErrors = null;
    state.isLoadingChange = true;
  },
  [mutationTypes.changeCategorySportsmanSuccess](state, result) {
    state.isLoadingChange = false;
    const index = state.allSportsmens.findIndex(
        (item) => item.sportsmen.id === result.sportsmen.id
    );
    if (index !== -1) {
      state.allSportsmens.splice(index, 1);
    } else {
      console.warn(`Sportsman with id ${result.sportsmen.id} not found in the list`);
    }
  },
  [mutationTypes.changeCategorySportsmanFailure](state, payload) {
    state.isLoadingChange = false;
    state.validationErrors = payload;
  },
  // editPerformance
  [mutationTypes.editPerformanceStart](state) {
    state.validationErrors = null;
    state.isLoadingChange = true;
  },
  [mutationTypes.editPerformanceSuccess](state, sportsman) {
    state.isLoadingChange = false;
    const index = state.allSportsmens.findIndex(
        (item) => item.id === sportsman.id
    );
    if (index !== -1) {
      state.allSportsmens.splice(index, 1, sportsman);
    }
  },
  [mutationTypes.editPerformanceFailure](state, payload) {
    state.isLoadingChange = false;
    state.validationErrors = payload;
  },
  // editGroupPerformance
  [mutationTypes.editGroupPerformanceStart](state) {
    state.validationErrors = null;
    state.isLoadingChange = true;
  },
  [mutationTypes.editGroupPerformanceSuccess](state, group) {
    state.isLoadingChange = false;
    console.log(group,'edit')
    const index = state.allGroups.findIndex(
        (item) => item.group.regGroupId === group.group.regGroupId
    );
    if (index !== -1) {
      state.allGroups.splice(index, 1, group);
    }
  },
  [mutationTypes.editGroupPerformanceFailure](state, payload) {
    state.isLoadingChange = false;
    state.validationErrors = payload;
  },
  // changeCategoryGroup
  [mutationTypes.changeCategoryGroupStart](state) {
    state.validationErrors = null;
    state.isLoadingChangeGroup = true;
  },
  [mutationTypes.changeCategoryGroupSuccess](state, group) {
    state.isLoadingChangeGroup = false;
    console.log(group,'category')
    const index = state.allGroups.findIndex(
        (item) => item.group.regGroupId === group.group.regGroupId
    );
    if (index !== -1) {
      state.allGroups.splice(index, 1);
    } else {
      console.warn(`Group not found in the list`);
    }
  },
  [mutationTypes.changeCategoryGroupFailure](state, payload) {
    state.isLoadingChangeGroup = false;
    state.validationErrors = payload;
  },
  // changeCategoryTrio
  [mutationTypes.changeCategoryTrioStart](state) {
    state.validationErrors = null;
    state.isLoadingChangeTrio = true;
  },
  [mutationTypes.changeCategoryTrioSuccess](state, trio) {
    state.isLoadingChangeTrio = false;
    console.log(trio,'category')
    const index = state.allTrios.findIndex(
        (item) => item.group.regGroupId === trio.group.regGroupId
    );
    if (index !== -1) {
      state.allTrios.splice(index, 1);
    } else {
      console.warn(`Group not found in the list`);
    }
  },
  [mutationTypes.changeCategoryTrioFailure](state, payload) {
    state.isLoadingChangeTrio = false;
    state.validationErrors = payload;
  },
  // changeCategoryDuo
  [mutationTypes.changeCategoryDuoStart](state) {
    state.validationErrors = null;
    state.isLoadingChangeTrio = true;
  },
  [mutationTypes.changeCategoryDuoSuccess](state, duo) {
    state.isLoadingChangeTrio = false;
    console.log(duo,'category')
    const index = state.allDuos.findIndex(
        (item) => item.group.regGroupId === duo.group.regGroupId
    );
    if (index !== -1) {
      state.allDuos.splice(index, 1);
    } else {
      console.warn(`Group not found in the list`);
    }
  },
  [mutationTypes.changeCategoryDuoFailure](state, payload) {
    state.isLoadingChangeTrio = false;
    state.validationErrors = payload;
  },
  // addSportsmanToCategory

  [mutationTypes.addSportsmanToCategoryStart](state) {
    state.validationErrors = null;
    state.isLoadingAddSportsmen = true;
  },
  [mutationTypes.addSportsmanToCategorySuccess](state, payload) {
    state.isLoadingAddSportsmen = false;
    state.allSportsmens = payload;
  },
  [mutationTypes.addSportsmanToCategoryFailure](state, payload) {
    state.isLoadingAddSportsmen = false;
    state.validationErrors = payload;
  },
  // addGroupToCategory
  [mutationTypes.addGroupToCategoryStart](state) {
    state.validationErrors = null;
    state.isLoadingAddSportsmen = true;
  },
  [mutationTypes.addGroupToCategorySuccess](state, payload) {
    state.isLoadingAddSportsmen = false;
    state.allGroups = payload;
  },
  [mutationTypes.addGroupToCategoryFailure](state, payload) {
    state.isLoadingAddSportsmen = false;
    state.validationErrors = payload;
  },
  // addTrioToCategory
  [mutationTypes.addTrioToCategoryStart](state) {
    state.validationErrors = null;
    state.isLoadingAddSportsmen = true;
  },
  [mutationTypes.addTrioToCategorySuccess](state, payload) {
    state.isLoadingAddSportsmen = false;
    state.allTrios = payload;
  },
  [mutationTypes.addTrioToCategoryFailure](state, payload) {
    state.isLoadingAddSportsmen = false;
    state.validationErrors = payload;
  },
  // addDuoToCategory
  [mutationTypes.addDuoToCategoryStart](state) {
    state.validationErrors = null;
    state.isLoadingAddSportsmen = true;
  },
  [mutationTypes.addDuoToCategorySuccess](state, payload) {
    state.isLoadingAddSportsmen = false;
    state.allDuos = payload;
  },
  [mutationTypes.addDuoToCategoryFailure](state, payload) {
    state.isLoadingAddSportsmen = false;
    state.validationErrors = payload;
  },
  // addJudgeToCompetition
  [mutationTypes.addJudgeToCompetitionStart](state) {
    state.validationErrors = null;
    state.isLoadingAddSportsmen = true;
  },
  [mutationTypes.addJudgeToCompetitionSuccess](state, payload) {
    state.isLoadingAddSportsmen = false;
    state.allJudges = payload;
  },
  [mutationTypes.addJudgeToCompetitionFailure](state, payload) {
    state.isLoadingAddSportsmen = false;
    state.validationErrors = payload;
  },
  // searchSportsmen
  [mutationTypes.searchSportsmenStart](state) {
    state.validationErrors = null;
    state.isLoadingInfoMandated = true;
    // state.allSportsmens = null;
  },
  [mutationTypes.searchSportsmenSuccess](state, payload) {
    state.isLoadingInfoMandated = false;
    state.allSportsmens = payload;
  },
  [mutationTypes.searchSportsmenFailure](state, payload) {
    state.isLoadingInfoMandated = false;
    state.validationErrors = payload;
  },
  [mutationTypes.RESET_STATE_BY_CATEGORY](state, category) {
    // Сбрасываем данные в зависимости от выбранной категории
    state.allSportsmens = category === 'sportsmens' ? state.allSportsmens : null;
    state.allGroups = category === 'groups' ? state.allGroups : null;
    state.allTrios = category === 'trios' ? state.allTrios : null;
    state.allDuos = category === 'duos' ? state.allDuos : null;
  },
};
const actions = {
  [actionTypesMandated.resetStateByCategory](context, category) {
    context.commit(mutationTypes.RESET_STATE_BY_CATEGORY,category);
  },
  [actionTypesMandated.getAllInfoMandated](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getAllInfoMandatedStart);
      mandatedAPi
        .getAllInfoMandated(credentials)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.getAllInfoMandatedSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.getAllInfoMandatedFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.getAllInfoMandatedFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  [actionTypesMandated.getAllSportsmenIndCateg](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getAllSportsmenIndCategStart);
      mandatedAPi
        .getAllSportsmenIndCateg(credentials)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.getAllSportsmenIndCategSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.getAllSportsmenIndCategFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.getAllInfoMandatedFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  [actionTypesMandated.getAllGroupCateg](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getAllGroupCategStart);
      mandatedAPi
        .getAllGroupCateg(credentials)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.getAllGroupCategSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.getAllGroupCategFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.getAllGroupCategFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  // getAllTrioCateg
  [actionTypesMandated.getAllTrioCateg](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getAllTrioCategStart);
      mandatedAPi
          .getAllTrioCateg(credentials)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(
                  mutationTypes.getAllTrioCategSuccess,
                  response.data
              );
              resolve(response.data);
            } else {
              context.commit(
                  mutationTypes.getAllTrioCategFailure,
                  response.data
              );
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.getAllTrioCategFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // getAllDuoCateg

  [actionTypesMandated.getAllDuoCateg](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getAllDuoCategStart);
      mandatedAPi
          .getAllDuoCateg(credentials)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(
                  mutationTypes.getAllDuoCategSuccess,
                  response.data
              );
              resolve(response.data);
            } else {
              context.commit(
                  mutationTypes.getAllDuoCategFailure,
                  response.data
              );
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.getAllDuoCategFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  [actionTypesMandated.sortition](context, idCompetition) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.sortitionStart);
      mandatedAPi
        .sortition(idCompetition)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.sortitionSuccess, true);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.sortitionFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.sortitionFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  // Изменение статуса выступающего
  [actionTypesMandated.changeStatusPerformance](context, idSportsmen) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.changeStatusPerformanceStart);
      mandatedAPi
          .changeStatusPerformance(idSportsmen)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.changeStatusPerformanceSuccess, response.data.result);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.changeStatusPerformanceFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.changeStatusPerformanceFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  //  Изменение статуса выступающей группы
  [actionTypesMandated.changeStatusPerformanceGroup](context, IdGroup) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.changeStatusPerformanceGroupStart);
      mandatedAPi
          .changeStatusPerformanceGroup(IdGroup)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.changeStatusPerformanceGroupSuccess, response.data.result);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.changeStatusPerformanceGroupFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.changeStatusPerformanceGroupFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // changeStatusPerformanceTrio
  [actionTypesMandated.changeStatusPerformanceTrio](context, IdGroup) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.changeStatusPerformanceTrioStart);
      mandatedAPi
          .changeStatusPerformanceTrio(IdGroup)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.changeStatusPerformanceTrioSuccess, response.data.result);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.changeStatusPerformanceTrioFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.changeStatusPerformanceTrioFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // changeStatusPerformanceDuo
  [actionTypesMandated.changeStatusPerformanceDuo](context, IdDuo) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.changeStatusPerformanceDuoStart);
      mandatedAPi
          .changeStatusPerformanceDuo(IdDuo)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.changeStatusPerformanceDuoSuccess, response.data.result);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.changeStatusPerformanceDuoFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.changeStatusPerformanceDuoFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // Изменение категории группы
  [actionTypesMandated.changeCategoryGroup](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.changeCategoryGroupStart);
      mandatedAPi
          .changeCategoryGroup(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.changeCategoryGroupSuccess, response.data.result);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.changeCategoryGroupFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.changeCategoryGroupFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // changeCategoryTrio
  [actionTypesMandated.changeCategoryTrio](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.changeCategoryTrioStart);
      mandatedAPi
          .changeCategoryTrio(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.changeCategoryTrioSuccess, response.data.result);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.changeCategoryTrioFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.changeCategoryTrioFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // changeCategoryDuo
  [actionTypesMandated.changeCategoryDuo](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.changeCategoryDuoStart);
      mandatedAPi
          .changeCategoryDuo(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.changeCategoryDuoSuccess, response.data.result);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.changeCategoryDuoFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.changeCategoryDuoFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // Изменение категории спортмена
  [actionTypesMandated.changeCategorySportsman](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.changeCategorySportsmanStart);
      mandatedAPi
          .changeCategorySportsman(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.changeCategorySportsmanSuccess, response.data.result);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.changeCategorySportsmanFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.changeCategorySportsmanFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // Изменение данных выступающих
  [actionTypesMandated.editPerformance](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.editPerformanceStart);
      mandatedAPi
          .editPerformance(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.editPerformanceSuccess, response.data.result);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.editPerformanceFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.editPerformanceFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  //
  [actionTypesMandated.editGroupPerformance](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.editGroupPerformanceStart);
      mandatedAPi
          .editGroupPerformance(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.editGroupPerformanceSuccess, response.data.result);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.editGroupPerformanceFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.editGroupPerformanceFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // Добавление спортсмена в категорию
  [actionTypesMandated.addSportsmanToCategory](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.addSportsmanToCategoryStart);
      mandatedAPi
          .addSportsmanToCategory(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.addSportsmanToCategorySuccess, response.data.result);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.addSportsmanToCategoryFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.addSportsmanToCategoryFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  [actionTypesMandated.addGroupToCategory](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.addGroupToCategoryStart);
      mandatedAPi
          .addGroupToCategory(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.addGroupToCategorySuccess, response.data.result);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.addGroupToCategoryFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.addGroupToCategoryFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // addDuoToCategory
  [actionTypesMandated.addDuoToCategory](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.addDuoToCategoryStart);
      mandatedAPi
          .addDuoToCategory(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.addDuoToCategorySuccess, response.data.result);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.addDuoToCategoryFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.addDuoToCategoryFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // addTrioToCategory
  [actionTypesMandated.addTrioToCategory](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.addTrioToCategoryStart);
      mandatedAPi
          .addTrioToCategory(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.addTrioToCategorySuccess, response.data.result);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.addTrioToCategoryFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.addTrioToCategoryFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  //addJudgeToCompetition
  [actionTypesMandated.addJudgeToCompetition](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.addJudgeToCompetitionStart);
      mandatedAPi
          .addJudgeToCompetition(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.addJudgeToCompetitionSuccess, response.data.result);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.addJudgeToCompetitionFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.addJudgeToCompetitionFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  [actionTypesMandated.searchSportsmen](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.searchSportsmenStart);
      mandatedAPi
          .searchSportsmen(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.searchSportsmenSuccess, response.data.result);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.searchSportsmenFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.searchSportsmenFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  //
};
export default {
  state,
  mutations,
  actions,
};
