<template>
  <div class="main-content" v-if="this.aboutRegistrApp !== null">
    <Header message="Создание заявки" />
    <BreadCrumps/>
    <div class="loader-overlay" v-if="isLoadingInfo">
      <div class="loader"></div>
    </div>
    <div class="create-application__container">
      <AboutItemCompetition :item_competition="aboutRegistrApp"/>
      <div class="create-application__tab">

        <tab-menu-application
            ref="tabMenu"
            @showModalAddSportsmen="showModalAddSportsmen"
            @showModalAddGroup="showModalAddGroup"
            @showModalAddCoath="showModalAddCoath"
            @showModalAddJuj="showModalAddJuj"
            @submitApplication="submitApplication"
        />
      </div>

    </div>
    <modal-add-sportsmen
        v-if="showAddSportsmen"
        @addCoach="showAddCoach = true"
        @close="showAddSportsmen = false"
    />
    <modal-add-coach
        v-if="showAddCoach"
        @close="showAddCoach = false"
        @closeCoachOpenSport="(showAddCoach = false), (showAddSportsmen = true)"
    />
    <modal-add-juj
        v-if="showAddJuj"
        @close="showAddJuj = false"
        @closeCoachOpenSport="showAddJuj = false"
    />
    <modal-add-group
        v-if="showAddGroup"
        @close="showAddGroup = false"
        @addCoach="showAddCoach = true"
        @addSportsmen="showModalAddSportsmen"
    />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { actionTypes } from "@/store/modules/registration";
  import { actionTypesEntered } from "@/store/modules/enteredData";
  import Header from '../components/Header.vue'
  import BreadCrumps from '@/components/BreadCrumps.vue'
  import ModalAddSportsmen from '../components/Modal/ModalAddSportsmen.vue'
  import TabMenuApplication from '../components/CreateApplication/TabMenuApplication.vue'
  import ModalAddGroup from '../components/Modal/ModalAddGroup.vue'
  import ModalAddJuj from '../components/Modal/ModalAddJuj.vue'
  import ModalAddCoach from '../components/Modal/ModalAddCoach.vue'
  import AboutItemCompetition from '@/components/AboutItemCompetition.vue'

  export default {
    name: "AppCreateApplicationNew",
    components: {
      AboutItemCompetition,
      Header, BreadCrumps,
      ModalAddSportsmen, TabMenuApplication, ModalAddGroup, ModalAddJuj, ModalAddCoach
    },
    data() {
      return {
        showAddSportsmen: false,
        showAddGroup: false,
        showAddCoach: false,
        showAddJuj: false,
        dataSubmit: {},
        isSubmitted: false
      };
    },
    computed: {
      ...mapState({
        isLoadingInfo: state => state.registration.isLoadingInfo,
        isLoadingCreateApp: state => state.registration.isLoadingCreateApp,
        aboutRegistrApp: state => state.registration.aboutRegistrApp,
        infoUser: state => state.auth.infoUser,
        validationErrorsSubmitApplication: state => state.registration.validationErrorsSubmitApplication
      })
    },
    methods: {
      submitApplication() {

        this.dataSubmit = {
          idCompetition: this.$route.params.id,
          userId: this.infoUser.id,
        };
        this.isSubmitted = true; // Установка флага, что попытка отправки была сделана
        this.$store
            .dispatch(actionTypes.submitApplication, this.dataSubmit)
            .then(() => this.$router.push({ path: "/applications" }))
            .catch(error => console.error("Ошибка при отправке: ", error));
      },
      showModalAddSportsmen() {
        this.showAddSportsmen = true;
      },
      showModalAddGroup() {
        this.showAddGroup = true;
      },
      safeCity(item) {
        this.city = item;
      },
      showModalAddCoath() {
        this.showAddCoach = true;
      },
      showModalAddJuj() {
        this.showAddJuj = true;
      },
      safeRegion(item) {
        this.region = item;
      }
    },
    mounted() {
      this.$store
          .dispatch(actionTypes.getInfoAboutRegistrationApp, this.$route.params.id)
          .then(() => {
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
      // получаем всех зарег судей категории
      this.$store
          .dispatch(actionTypesEntered.getJuj)
          .then(() => {})
          .catch((er) => {
            console.log(er, "Error");
          });
    }};
</script>

<style scoped>
/* Ваши стили */
</style>
