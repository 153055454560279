<template>
  <div class="mandated-info__block">
    <div class="mandated-info__wrap">
      <div class="mandated-info__label">
        <p class="label-text-profile">
          Для просмотра трио подавших заявки выберите категорию
        </p>
      </div>
      <div class="mandated-info__select">
        <AppCategorySelect :all-category="listNominationTrio" @categorySelected="getGroup"  :default="defaultCategory"/>
      </div>
    </div>
    <div
        class="mandated-info__table"
        v-if="this.allTrios && this.allTrios.length !== 0"
    >
      <div class="df">
        <p class="mandated__item-block-text-active"> Итого трио в категориии: {{this.allTrios.length }}</p>
      </div>
      <div class="mandated-info__title-with-svg">
        <div class="mandated-info__title">
          <p class="mandated-info__title-text">Добавить трио в категорию</p>
        </div>

        <div class="mandated-info__wrap-svg" @click="openModal('isShowAddTrio')">
          <img src="../../assets/svg/plus.svg" alt="plus" />
        </div>
      </div>
      <table class="table">
        <thead>
        <tr>
          <th class="empty_th"></th>
          <th class="th__name" style="width: 2%">№</th>
          <th class="th__name" style="width: 5%">Название группы</th>
          <th class="th__years" style="width: 15%">Спортсмены</th>
          <th class="th__years" style="width: 15%">Клуб/Организацию</th>
          <th class="th__trainer" style="width: 15%">Тренеры</th>
          <th class="th__section" style="width: 25%">Категория </th>
          <th class="th__section" style="width: 20%">Статус</th>
        </tr>
        </thead>

        <AppItemGroupInMandated  v-for="(applicationGroup, i) in allTrios" :idx="i" :listNominationGroup="listNominationTrio" @editGroup="editGroup" :safeGroupCategories="safeGroupCategories" :item_group="applicationGroup.group" :item_categ="applicationGroup.categ"  :key="`${applicationGroup.group.regGroupId}_${applicationGroup.categ.id}`"/>
      </table>
    </div>
    <div
        class="mandated-info__table"  v-if="this.allTrios && this.allTrios.length === 0"
    >
      <div class="mandated-info__title-with-svg">
        <div class="mandated-info__title">
          <p class="mandated-info__title-text">Добавить трио в категорию</p>
        </div>

        <div class="mandated-info__wrap-svg" @click="openModal('isShowAddTrio')">
          <img src="../../assets/svg/plus.svg" alt="plus" />
        </div>
      </div>
      <p class="mandated__item-block-text-active">
        В данной категории нет заявленных трио!
      </p>

    </div>
    <modal-add-trio
        v-if="isShowAddTrio"
        :category="safeGroupCategories"
        @addCoach="openModal('isShowAddCoach')"
        @addSportsmen="openModal('isShowAddSportsmen')"
        @editSportsmen="editItem('item_sportsmen', 'isShowModifSportsmen', $event)"
        @close="closeModal('isShowAddTrio')"
    />
    <app-modal-add-sportsmen  v-if="isShowAddSportsmen"
                              @close="closeModal('isShowAddSportsmen')"
                              @addCoach="openModal('isShowAddCoach')"/>
    <modal-add-coach
        v-if="isShowAddCoach"
        @close="closeModal('isShowAddCoach')"
    />
    <modal-modification-sportsmen
        v-if="isShowModifSportsmen"
        @addCoach="openModal('isShowAddCoach')"
        @close="closeModal('isShowModifSportsmen')"
        :item_sportsmen="item_sportsmen"
    />
    <app-modal-edit-group-performance v-if="isShowEditTrio"  @editSportsmen="editItem('item_sportsmen', 'isShowModifSportsmen', $event)"   @close="closeModal('isShowEditGroup')" :item_group="safeItemGroup"/>
  </div>
</template>

<script>
  // import CustomSelectCategory from "../../components/customSelect/CustomSelectCategory.vue";
  import { mapState } from "vuex";
  import { actionTypesMandated } from "@/store/modules/mandated";
  import AppItemGroupInMandated from '@/components/Mandated/ItemGroupInMandated.vue'
  import AppModalEditGroupPerformance from '@/components/Modal/ModalEditGroupPerformance.vue'
  import AppCategorySelect from '@/components/customSelect/CategorySelect.vue'
  import AppModalAddSportsmen from '@/components/Modal/ModalAddSportsmen.vue'
  import ModalAddCoach from '@/components/Modal/ModalAddCoach.vue'
  import ModalAddTrio from '@/components/Modal/ModalAddTrio.vue'
  import ModalModificationSportsmen from '@/components/Modal/ModalModificationSportsmen.vue'
  export default {
    components: {
      ModalModificationSportsmen,
      ModalAddTrio,

      ModalAddCoach,
      AppModalAddSportsmen,
      AppCategorySelect,
      AppModalEditGroupPerformance,
      AppItemGroupInMandated, },
    name: "AppInfoAboutTrio",
    data() {
      return {
        dataIdToGroup: null,
        defaultCategory: "Выберите категорию",
        safeGroupCategories: '',
        isShowAddTrio: false,
        isShowAddCoach: false,
        isShowAddSportsmen: false,
        isShowModifSportsmen:false,
        isShowEditTrio: false,
        safeItemGroup: null,
        item_sportsmen: null,
      };
    },
    computed: {
      ...mapState({
        isLoading: (state) => state.mandated.isLoading,
        allTrios: (state) => state.mandated.allTrios,
      }),
    },
    props: {
      listNominationTrio: {
        type: Array,
        default() {
          return {};
        },
      },
    },
    methods: {
      openModal(modalProperty) {
        this[modalProperty] = true;
        document.body.classList.add('no-scroll');
      },
      editGroup(item) {
        console.log('НУЖЕН ЗАПРОС НА ИЗмЕНЕНИЕ ТРИО', item)
        // this.openModal('isShowEditTrio')
        // this.safeItemGroup = item

      },
      editItem(itemName, modalName, item) {
        this[itemName] = item;
        this.openModal(modalName);
      },
      closeModal(modalProperty) {
        // console.log('modal close',modalProperty )
        this[modalProperty] = false;
        // Проверяем, нужно ли оставить скролл заблокированным (если есть другие открытые модальные окна)
        if (!this.anyModalOpen()) {
          document.body.classList.remove('no-scroll');
        }
      },
      anyModalOpen() {
        // Проверяем, открыто ли еще какое-либо модальное окно
        return this.isShowAddSportsmen || this.isShowAddCoach ||
            this.isShowAddTrio || this.isShowModifSportsmen || this.isShowEditTrio;
      },
      getGroup(category) {
        this.safeGroupCategories = category;
        this.dataIdToGroup = {
          categoryId: category.id,
          competitionId: this.$route.params.id,
        };
        this.$store
            .dispatch(actionTypesMandated.getAllTrioCateg, this.dataIdToGroup)
            .then(() => {})
            .catch((er) => {
              console.log(er, "Error");
            });
      },
    },
  };
</script>

<style></style>
