<template>
  <div class="pie">
    <div class="pie__legend">
      <div
          class="pie__legend-item"
          v-for="(label, index) in chartData.labels"
          :key="index"
      >
        <span
            class="pie__legend-color"
            :style="{ backgroundColor: chartData.datasets[0].backgroundColor[index] }"
        ></span>
        <p class="pie__legend-text">{{ label }}</p>
      </div>
    </div>
    <Pie
        v-if="chartData"
        :chart-data="chartData"
        :chart-options="chartOptions"
        style="width: 100%; max-height: 200px;"
    />
    <p v-else>Загрузка данных...</p>
  </div>
</template>

<script>
  import { Pie } from "vue-chartjs";
  import {
    Chart,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale,
  } from "chart.js";
  import { mapState } from 'vuex'

  Chart.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

  export default {
    name: "PieChartIndividual",
    components: { Pie },
    data() {
      return {
        // Локальные данные, которые могут быть заменены на данные с бэка
        localData: [
          { region: "Новосибирская область", count: 40 },
          { region: "Кемеровская область", count: 30 },
          { region: "Алтайский край", count: 20 },
          { region: "Республика Алтай", count: 10 },
        ],
        chartData: null, // Данные для диаграммы
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false, // Отключаем встроенную легенду
            },
            tooltip: {
              callbacks: {
                label: (context) =>
                    `${context.label}: ${context.raw} участников`,
              },
            },
          },
        },
      };
    },
    computed: {
      ...mapState({
        isLoadingAnalytics: (state) => state.statistics.isLoadingAnalytics,
        allAnalytics: (state) => state.statistics.allAnalytics,
      }),
    },
    created() {
      this.generateChartData(); // Генерируем данные при создании компонента
    },
    methods: {
      generateChartData() {
        // Цветовая палитра
        const backgroundColors = [
          "rgba(25, 44, 140, 0.8)",
          "rgba(15, 49, 169, 0.8)",
          "rgba(15, 40, 169, 0.5)",
          "rgba(147, 170, 252, 0.8)",
          "rgba(198, 210, 253, 0.8)",
          "rgba(229, 234, 252, 0.8)",
          "rgba(201, 203, 207, 0.2)",
        ];

        // Формируем данные для диаграммы
        this.chartData = {
          labels: this.allAnalytics.allRegionSportsmenCounts.map((item) => item.region), // Названия регионов
          datasets: [
            {
              label: "Участники",
              data: this.allAnalytics.allRegionSportsmenCounts.map((item) => item.count), // Количество участников
              backgroundColor: backgroundColors,
              hoverBackgroundColor: backgroundColors, // Цвета при наведении
            },
          ],
        };
      },
    },
  };
</script>

<style scoped>
.pie {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.pie__legend {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

.pie__legend-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.pie__legend-color {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.pie__legend-text {
  font-size: 12px;
  color: #333333;
}
</style>
