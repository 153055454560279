<template>
  <div class="perfomance__container">
    <form action="" class="form">
      <fieldset
        class="fieldset"
        :class="{
          'error-fieldset': isSubmitted && $v.groupCategory.$error,
        }"
      >
        <legend class="legend">1. Выберите категорию:</legend>
        <div class="form__row">
          <div class="form__group-row">
            <input
              type="radio"
              id="category-group1"
              name="options"
              class="radio-tag"
              value="A"
              v-model="groupCategory"
            />
            <label for="category-group1"> Категория A</label>
          </div>
          <div class="form__group-row">
            <input
              type="radio"
              id="category-group2"
              name="options"
              class="radio-tag"
              value="B"
              v-model="groupCategory"
            />
            <label for="category-group2"> Категория B</label>
          </div>
        </div>
      </fieldset>

      <fieldset
        class="fieldset"
        :class="{
          'error-fieldset':
            (isSubmitted && $v.fromAge.$error) ||
            (isSubmitted && $v.beforeAge.$error),
        }"
      >
        <legend class="legend">2. Выберите год рождения:</legend>
        <div class="perfomance__row">
          <p class="perfomance__text">от</p>

          <date-picker
            v-model="fromAge"
            input-class="perfomance__input-date"
            value-type="format"
            format="YYYY"
            maxlength="4"
            type="year"
          ></date-picker>

          <p class="perfomance__text">до</p>
          <date-picker
            v-model="beforeAge"
            value-type="format"
            format="YYYY"
            maxlength="4"
            input-class="perfomance__input-date"
            type="year"
          ></date-picker>
          <p class="perfomance__text">Пример: (от 2010 до 2012)</p>
        </div>
      </fieldset>
      <fieldset
          class="fieldset"
          :class="{
          'error-fieldset': isSubmitted && $v.performedTypes.$error,
        }"
      >
        <legend class="legend">3. Выберите тип программы:</legend>
        <div class="form__row">
          <div
              class=""
              v-for="(program,index) in groupPrograms"
              :key="index"
          >
            <div class="form__group-row">
              <input
                  type="radio"
                  :id="index + 'group-programs'"
                  :value="program"
                  :name="index"
                  v-model="programGroup"
              />
              <label :for="index + 'group-rank'"> {{ program }}</label>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset
        class="fieldset"
        :class="{
          'error-fieldset': isSubmitted && $v.performedTypes.$error,
        }"
      >
        <legend class="legend">3. Количество выполняемых видов:</legend>
        <div class="form__row">
          <div
            class=""
            v-for="performed in performedTypesLocal"
            :key="performed.id"
          >
            <div class="form__group-row">
              <input
                :id="performed.id + 'group-performed'"
                type="checkbox"
                :value="performed.id"
                v-model="performedTypes"
              />
              <label :for="performed.id + 'group-performed'"> {{ performed.name }}</label>
            </div>
          </div>
        </div>
        <legend class="legend">
          Выбрано: {{ performedTypes.length }}
          <span v-if="performedTypes.length === 1">вид</span>
          <span
            v-else-if="performedTypes.length > 1 && performedTypes.length < 5"
            >вида</span
          >
          <span
            v-else-if="performedTypes.length < 1 || performedTypes.length > 4"
            >видов</span
          >
        </legend>
      </fieldset>

      <fieldset
        class="fieldset"
        :class="{
          'error-fieldset': isSubmitted && $v.rank.$error,
        }"
      >
        <legend class="legend">4. Выступает по разряду:</legend>
        <template>
          <div class="form__row">
            <div class="" v-for="item in ranks" :key="item.id">
              <div class="form__group-row">
                <input
                  type="radio"
                  :id="item.id + 'group-rank'"
                  :value="item.id"
                  :name="item.name"
                  v-model="rank"
                />
                <label :for="item.id + 'group-rank'"> {{ item.name }}</label>
              </div>
            </div>
          </div>
        </template>
      </fieldset>
      <div class="creation__btn">
        <div class="creation__btn-wrap">
          <button type="button" class="button-green" @click="addCategory">
            Добавить
          </button>
        </div>
        <button
          type="button"
          class="button-transponent"
          @click="switchTabsClass"
        >
          Пропустить
        </button>
      </div>
    </form>
    <div class="perfomance-category">
      <div class="perfomance-category__title">
        <p class="perfomance-category__title-text">собранные категории</p>
      </div>
      <div class="divTable">
        <div class="divTableHeading">
          <div class="divTableRow">
            <div class="divTableHead">Категория</div>
            <div class="divTableHead">Тип</div>
            <div class="divTableHead">Год рождения</div>
            <div class="divTableHead">Выполняемые виды</div>
            <div class="divTableHead">Выступают по разряду</div>
          </div>
        </div>
        <div
          class="divTableBody"
          v-if="competitionGroupCateg && competitionGroupCateg.length"
        >
          <item-collect-competition
            v-for="itemCateg in competitionGroupCateg"
            :key="itemCateg.id"
            v-bind:item_categ="itemCateg"
            @deleteCateg="deleteCateg"
          />
        </div>
        <div class="" v-else>
          <empty-block style="max-width: 100%" />
        </div>
      </div>
    </div>
    <div class="creation__btn">
      <button class="button" @click="endRegistration">
        Закончить регистрацию
      </button>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import { mapState } from "vuex";
import { actionTypes } from "@/store/modules/registrationComp";
import ItemCollectCompetition from "./ItemCollectCompetition.vue";
import EmptyBlock from "./EmptyBlock.vue";
import { required } from "vuelidate/lib/validators";

export default {
  name: "AppGroupPerfomance",
  data() {
    return {
      groupCategory: "",
      fromAge: "",
      beforeAge: "",
      performedTypes: [],
      rank: "",
      categ: {},
      idCateg: {},
      programGroup: '',
      isSubmitted: false,
    };
  },
  props: {
    id: {
      type: [String, Number],
      default() {
        return {};
      },
    },
  },
  validations: {
    groupCategory: {
      required,
    },
    fromAge: {
      required,
    },
    beforeAge: {
      required,
    },
    rank: {
      required,
    },
    performedTypes: {
      required,
    },
    groupPrograms: {
      required
    }
  },
  components: {
    DatePicker,
    ItemCollectCompetition,
    EmptyBlock,
  },
  computed: {
    ...mapState({
      validationErrors: (state) => state.registrationComp.validationErrors,
      isLoading: (state) => state.registrationComp.isLoading,
      performedTypesLocal: (state) =>
        state.registrationComp.performedTypesGroup,
      ranks: (state) => state.registrationComp.ranksGroup,
      competitionGroupCateg: (state) =>
        state.registrationComp.competitionGroupCateg,
      groupPrograms: (state) =>
          state.registrationComp.groupPrograms,
    }),
  },
  methods: {
    addCategory() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return console.log("Данные не заполенены");
      } else {
        this.categ = {
          groupCategory: this.groupCategory,
          fromAge: this.fromAge,
          beforeAge: this.beforeAge,
          performedTypes: this.performedTypes,
          rank: this.rank,
          competitionId: this.id,
          type: this.programGroup
        };
        this.$store
          .dispatch(actionTypes.competitionsRegTrirdStep, this.categ)
          .then(() => {
            this.isSubmitted = false;
            this.groupCategory = "";
            this.fromAge = "";
            this.beforeAge = "";
            this.performedTypes = [];
            this.rank = "";
            this.programGroup = ''
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
      }
    },
    deleteCateg(id) {
      this.idCateg = {
        id: id,
        competitionId: this.id,
      };
      this.$store
        .dispatch(actionTypes.competitionsDeleteTrirdStep, this.idCateg)
        .then(() => {})
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
    switchTabsClass() {
      this.$emit("switchTabsClass", "group");
    },
    endRegistration() {
      this.$store
        .dispatch(actionTypes.endCompetition, this.id)
        .then(() => {
          this.$router.push({
            name: "Competition",
          });
        })
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
  },
  mounted() {
    this.$store
      .dispatch(actionTypes.groupCategory)
      .then(() => {
        this.$store
          .dispatch(actionTypes.getGroupCategoryDraft, this.id)
          .then(() => {})
          .catch((er) => {
            console.log("Ошибка", er);
          });
      })
      .catch((er) => {
        console.log("Ошибка", er);
      });
  },
};
</script>

<style></style>
