<template>
  <div class="detail-competition-category__item-categ">
    <img src="../../assets/svg/groupUser.svg" alt="user" />
    <div class="detail-competition-category__item-categ-wrap">
      <p class="text-description">
       {{defType}}
      </p>
      <p class="text-description">
        {{ item_group_categ.groupCategory }} &nbsp;
      </p>
      <div class="detail-competition-category__item-categ-age">
        <p class="text-description" v-if="item_group_categ.fromAge !== null">
          {{ item_group_categ.fromAge }}-
        </p>
        <p class="text-description" v-if="item_group_categ.beforeAge !== null">
          {{ item_group_categ.beforeAge }} г.р.&nbsp;
        </p>

        <p class="text-description" v-else>г.р.&nbsp;</p>
      </div>
      <p class="text-description">{{ item_group_categ.performedTypes }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppItemGroupCateg",
  props: {
    item_group_categ: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    defType() {
      if (this.item_group_categ && this.item_group_categ.type) {
        if (this.item_group_categ.type === 'GROUP') {
          return 'ГУ';
        } else if (this.item_group_categ.type === 'DUO') {
          return 'ДУЭТ';
        } else if (this.item_group_categ.type === 'TRIO') {
          return 'ТРИО';
        } else {
          return 'Неизвестно'; // Обработка неизвестного типа
        }
      }
      return 'Не указано'; // Значение по умолчанию, если нет `type`
    }
  }

};
</script>

<style></style>
