import infoAPi from "@/api/info.js";
import auth from "../modules/auth.js";
import { setItem } from "@/helpers/persistanceStorage";
// import axios from "axios";
// import router from "@/router";

const state = {
  isLoading: false, //ожидание запроса
  infoProfile: null,
  validationErrors: null,
  // isConfirmedProfile: getItem("isConfirmedProfile") || false,
  isConfirmedProfile: false,
};

export const mutationTypes = {
  profileInfoStart: "[information] profileInfoStart",
  profileInfoSuccess: "[information] profileInfoSuccess",
  profileInfoFailure: "[information] profileInfoFailure",
  editInfoProfileStart: "[information] editInfoProfileStart",
  editInfoProfileSuccess: "[information] editInfoProfileSuccess",
  editInfoProfileFailure: "[information] editInfoProfileFailure",
};
export const actionTypes = {
  profileInfo: "[information] profileInfo",
  editInfoProfile: "[information] editInfoProfile",
};

const mutations = {
  [mutationTypes.profileInfoStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.profileInfoSuccess](state, info) {
    state.isLoading = false;
    state.infoProfile = info.result;
  },
  [mutationTypes.profileInfoFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  [mutationTypes.editInfoProfileStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.editInfoProfileSuccess](state, profileIsEmpty) {
    state.isLoading = false;
    auth.state.infoUser.profileIsEmpty = profileIsEmpty.result;
    setItem("infoUserApplication", auth.state.infoUser);

    // console.log(auth.state.infoUser, "после изменения");
  },
  [mutationTypes.editInfoProfileFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
};
const actions = {
  [actionTypes.profileInfo](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.profileInfoStart);
      infoAPi
        .profileInfo()
        .then((response) => {
          // console.log(response);
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.profileInfoSuccess, response.data);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.profileInfoFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.profileInfoFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  [actionTypes.editInfoProfile](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.editInfoProfileStart);
      infoAPi
        .editInfoProfile(data)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.editInfoProfileSuccess, response.data);
            // setItem("isConfirmedProfile", response.data.result);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.editInfoProfileFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.editInfoProfileFailure, err);
          console.log("ошибка", err);
        });
    });
  },
};
export default {
  state,
  mutations,
  actions,
};
