<template>
  <div class="main__filter-app">
    <div class="filter">
      <div
          class="filter__block2"
          :class="{ filter__block: isActiveTab('individ') }"
          @click="switchTabsClass('individ')"
      >
        <p
            class="filter__text2"
            :class="{ activeText: isActiveTab('individ') }"
        >
          Индивидуальные
        </p>
      </div>
      <div
          class="filter__block2"
          :class="{ filter__block: isActiveTab('group') }"
          v-if="groupCategories && groupCategories.length"
          @click="switchTabsClass('group')"
      >
        <p
            class="filter__text2"
            :class="{ activeText: isActiveTab('group') }"
        >
          Групповые
        </p>
      </div>
      <div
          class="filter__block2"
          :class="{ filter__block: isActiveTab('duet') }"
          v-if="duoCategories && duoCategories.length"
          @click="switchTabsClass('duet')"
      >
        <p class="filter__text2" :class="{ activeText: isActiveTab('duet') }">
          Дуэты
        </p>
      </div>
      <div
          class="filter__block2"
          :class="{ filter__block: isActiveTab('trio') }"
          v-if="trioCategories && trioCategories.length"
          @click="switchTabsClass('trio')"
      >
        <p class="filter__text2" :class="{ activeText: isActiveTab('trio') }">
          Трио
        </p>
      </div>
      <div
          class="filter__block2"
          :class="{ filter__block: isActiveTab('else') }"
          @click="switchTabsClass('else')"
      >
        <p class="filter__text2" :class="{ activeText: isActiveTab('else') }">
          Судьи
        </p>
      </div>
    </div>

    <!-- ИНДИВИДУАЛЬНЫЕ ЗАЯВКИ -->
    <individual-application
        @showModalAddSportsmen="showModalAddSportsmen"
        ref="individulApp"
        v-if="isActiveTab('individ')"
    />
    <!-- ГРУППОВЫЕ ЗАЯВКИ -->
    <group-application
        @showModalAddGroup="showModalAddGroup"
        v-if="isActiveTab('group')"
    />
    <!-- ДУЭТ -->
    <app-duo-application
        @showModalAddGroup="showModalAddGroup"
        v-if="isActiveTab('duet')"
    />
    <!-- ТРИО -->
    <app-trio-application  @showModalAddGroup="showModalAddGroup"
                           v-if="isActiveTab('trio')"/>
    <!-- СУДЕЙКИЕ ЗАЯВКИ -->
    <juj-application
        v-if="isActiveTab('else')"
        @showModalAddJuj="showModalAddJuj"
    />
    <div class="search__btn" v-if="aboutRegistrApp.status !== 'DRAFT'">
      <button class="mandated__tag dark" @click="submitApplication">
        Сохранить изменения
      </button>

    </div>

  </div>
</template>

<script>
  import { actionTypes } from "@/store/modules/registration";
  import { mapState } from "vuex";
  import IndividualApplication from "./IndividualApplication.vue";
  import GroupApplication from "./GroupApplication.vue";
  // import DuetApplication from "./DuetApplication.vue";
  // import TrioApplication from "./TrioApplication.vue";
  import JujApplication from "./JujApplication.vue";
  import AppTrioApplication from '@/components/CreateApplication/TrioApplication.vue'
  import AppDuoApplication from '@/components/CreateApplication/DuoApplication.vue'

  export default {
    name: "AppTabMenu",
    data() {
      return {
        activeTab: 'individ', // Хранит текущее активное состояние
      };
    },
    components: {
      AppDuoApplication,
      AppTrioApplication,
      IndividualApplication,
      GroupApplication,
      // DuetApplication,
      // TrioApplication,
      JujApplication,
    },
    computed: {
      ...mapState({
        isLoadingCreateApp: (state) => state.registration.isLoadingCreateApp,
        aboutRegistrApp: (state) => state.registration.aboutRegistrApp,
        groupCategories: (state) => state.registration.groupCategories,
        trioCategories: (state) => state.registration.trioCategories,
        duoCategories: (state) => state.registration.duoCategories,
      }),
    },
    methods: {
      switchTabsClass(tab) {
        this.activeTab = tab; // Меняет активную вкладку
      },
      isActiveTab(tab) {
        return this.activeTab === tab; // Проверяет, является ли вкладка активной
      },
      showModalAddSportsmen() {
        this.$emit("showModalAddSportsmen");
      },
      showModalAddGroup() {
        this.$emit("showModalAddGroup");
      },
      showModalAddDuet() {
        this.$emit("showModalAddDuet");
      },
      showModalAddTrio() {
        this.$emit("showModalAddTrio");
      },
      showModalAddJuj() {
        this.$emit("showModalAddJuj");
      },
      submitApplication() {
        this.$emit("submitApplication");
      },
    },
    mounted() {
      this.$store
          .dispatch(actionTypes.getIndividualCategories, this.$route.params.id)
          .then(() => {})
          .catch((er) => {
            console.log(er, "Error");
          });
      this.$store
          .dispatch(actionTypes.getGroupCategories, this.$route.params.id)
          .then(() => {})
          .catch((er) => {
            console.log(er, "Error");
          });
    },
  };
</script>
