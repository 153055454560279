<template>
  <div class="hint-text">
    <span v-for="(hint, index) in formattedHints" :key="index" class="hint-item">
      {{ hint.input }} = <span class="hint-value">{{ hint.output }}</span>
      <span v-if="index !== formattedHints.length - 1">, </span>
    </span>
  </div>
</template>

<script>
  export default {
    name: 'TimeHint',
    props: {
      timeInputs: {
        type: Array,
        default: () => ["1", "1.30", "1.45", "2", "3"], // Массив стандартных значений
      },
    },
    computed: {
      // Преобразование значений времени в подсказки
      formattedHints() {
        return this.timeInputs.map((time) => {
          // Разбиваем строку на минуты и секунды
          const [minutes, seconds] = time.split('.').map(Number);
          let totalSeconds = 0;

          if (!isNaN(minutes) && !isNaN(seconds)) {
            totalSeconds = minutes * 60 + seconds;
          } else if (!isNaN(minutes)) {
            totalSeconds = minutes * 60;
          }

          const outputText = `${totalSeconds} секунд`;

          return {
            input: time.includes('.') ? `${minutes} минут ${seconds} секунд` : `${minutes} минут`,
            output: outputText,
          };
        });
      },
    },
  };
</script>

<style scoped>
.hint-text {
  margin-bottom: 10px;
  font-size: 10px;
  color: #888;
}

.hint-item {
  display: inline;
}

.hint-value {
  font-weight: bold;
  color: #42a5f5;
}
</style>
