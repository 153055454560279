<template>
  <div class="search__wrap-block">
    <div class="search__add-coach">
      <select-with-check-juj :checkSelect="item_juj" :tabindex="index" />
    </div>
    <div class="search-edit__wrap">
      <div class="table__icon-btn">
        <span class="table__icon-delete"  @click="deleteItemJuj()">🗑️</span>
      </div>
<!--      <div class="search-edit__wrap-img" @click="deleteItemJuj()">-->
<!--        <img src="../../assets/svg/trash.svg" alt="trash" />-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import SelectWithCheckJuj from "../customSelect/SelectWithCheckJuj.vue";

export default {
  components: { SelectWithCheckJuj },
  name: "AppItemAppJuj",
  data() {
    return {};
  },
  props: {
    item_juj: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {
    deleteItemJuj() {
      this.$emit("deleteItemJuj", this.item_juj);
    },
  },
};
</script>

<style></style>
