<template>
  <transition name="modal" @click="$emit('close')">
    <div class="modal-mask" :style="{ 'z-index': 1100 }">
      <div class="modal-wrapper">
        <div class="modal-mini-container">
          <div class="modal-header">
            <div class="modal__cross" @click="$emit('close')">
              <img src="../../assets/svg/cross.svg" alt="cross" />
            </div>
          </div>
          <form @submit.prevent="safeInfoCoach">
            <div class="modal-body">
              <div class="modal-body__title">
                <p class="modal__title">Добавление тренера</p>
              </div>
              <ValidationErrors
                  v-if="validationErrorsCoach"
                  :validation-errors="validationErrorsCoach.message"
              />
              <div class="modal-scroll-max">
              <div
                class="message-about-empty empty"
                v-if="isSubmitted && this.$v.$invalid"
              >
                <p class="message-about-empty__text">Заполните все данные!</p>
              </div>
              <div class="input-wrap__column">
                <div class="input-wrap__label-with-input-column">
                  <div class="input-wrap__label">
                    <p class="label-text">Фамилия:</p>
                  </div>
                  <input
                    type="text"
                    class="input-light-text"
                    :class="{
                      'error-border-input': isSubmitted && $v.surname.$error,
                    }"
                    placeholder="Заполните фамилию"
                    name="surname"
                    v-model="surname"
                  />
                  <img
                    src="~@/assets/svg/Error.svg"
                    alt="error"
                    v-if="isSubmitted && $v.surname.$error"
                    class="wrapValidationInput__error"
                  />
                </div>
                <div class="input-wrap__label-with-input-column">
                  <div class="input-wrap__label">
                    <p class="label-text">Имя:</p>
                  </div>
                  <input
                    type="text"
                    class="input-light-text"
                    :class="{
                      'error-border-input': isSubmitted && $v.coachName.$error,
                    }"
                    placeholder="Заполните имя"
                    name="coachName"
                    v-model="coachName"
                  />
                  <img
                    src="~@/assets/svg/Error.svg"
                    alt="error"
                    v-if="isSubmitted && $v.coachName.$error"
                    class="wrapValidationInput__error"
                  />
                </div>
                <div class="input-wrap__label-with-input-column">
                  <div class="input-wrap__label">
                    <p class="label-text">Отчество:</p>
                  </div>
                  <input
                    type="text"
                    class="input-light-text"
                    :class="{
                      'error-border-input': isSubmitted && $v.patronymic.$error,
                    }"
                    placeholder="Заполните отчество"
                    name="patronymic"
                    v-model="patronymic"
                  />
                  <img
                    src="~@/assets/svg/Error.svg"
                    alt="error"
                    v-if="isSubmitted && $v.patronymic.$error"
                    class="wrapValidationInput__error"
                  />
                </div>
                <div class="input-wrap__label-with-input-column">
                  <div class="input-wrap__label">
                    <p class="label-text">Клуб:</p>
                  </div>
                  <input
                    type="text"
                    class="input-light-text"
                    :class="{
                      'error-border-input': isSubmitted && $v.club.$error,
                    }"
                    placeholder="Организация/клуб/школа"
                    name="club"
                    v-model="club"
                  />
                  <img
                    src="~@/assets/svg/Error.svg"
                    alt="error"
                    v-if="isSubmitted && $v.club.$error"
                    class="wrapValidationInput__error"
                  />
                </div>
              </div>
            </div>

            </div>
            <div class="modal-footer">
              <div class="modal-footer__button">
                <button class="mandated__tag dark">Сохранить</button>
              </div>
              <div class="modal-footer__button">
                <button
                    type="button"
                    class="mandated__tag button-cancel"
                    @click="$emit('close')"
                >
                  Отменить
                </button>
              </div>
            </div>
          </form>
        </div>

      </div>
    </div>
  </transition>
</template>

<script>
import { actionTypesEntered } from "@/store/modules/enteredData";
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import ValidationErrors from '@/components/ValidationErrors.vue'

export default {
  name: "AppModalAddCoach",
  components: { ValidationErrors },
  data() {
    return {
      coachName: "",
      surname: "",
      patronymic: "",
      club: "",
      isSubmitted: false,
    };
  },

  validations: {
    coachName: {
      required,
    },
    surname: {
      required,
    },
    patronymic: {
      required,
    },
    club: {
      required,
    },
  },

  computed: {
    ...mapState({
      isLoading: (state) => state.enteredData.isLoading,
      validationErrorsCoach: state => state.enteredData.validationErrorsCoach
    }),
  },
  methods: {
    // async safeInfoCoach() {
    //   this.isSubmitted = true;
    //   this.$v.$touch();
    //
    //   if (this.$v.$invalid) {
    //     console.log("Данные не заполнены");
    //     return;
    //   }
    //
    //   // Собираем данные спортсмена
    //   const judgeData = {
    //     coachName: this.coachName,
    //     surname: this.surname,
    //     patronymic: this.patronymic,
    //     club: this.club,
    //   };
    //
    //   try {
    //     // Условие 1: Если есть тип mandate, делаем запрос на actionTypes.registrSportsmenWithCateg
    //     if (this.typeAdd === ' mandate') {
    //       judgeData.idCompetiton = this.$route.params.id; // Привязка к категории
    //       await this.$store.dispatch(actionTypesMandated.addJudgeToApplication, judgeData);
    //     }
    //     // Условие 2: Если есть тип mandate, делаем запрос на actionTypes.registrSportsmenWithCateg
    //     else if(this.typeAdd === 'application') {
    //       judgeData.idCompetiton = this.$route.params.id; // Привязка к категории
    //       await this.$store.dispatch(actionTypes.registrJudgeToApplication, judgeData)
    //           .then(() => {
    //           });
    //     }
    //     // Условие 3: Если нет ни category, ни application_category, делаем запрос на actionTypesEntered.registrSportsmen
    //     else {
    //       await this.$store.dispatch(actionTypesEntered.registrCoach, judgeData);
    //     }
    //
    //     // Очищаем форму после успешного добавления
    //     this.clearForm();
    //     this.$emit("close");
    //   } catch (er) {
    //     console.log("Ошибка", er);
    //   }
    // },
    safeInfoCoach() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return console.log("Данные не заполенены");
      } else {
        this.$store
          .dispatch(actionTypesEntered.registrCoach, {
            coachName: this.coachName,
            surname: this.surname,
            patronymic: this.patronymic,
            club: this.club,
          })
          .then(() => {
            this.name = "";
            this.surname = "";
            this.patronymic = "";
            this.club = "";
            this.$emit("close");
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
      }
    },
    clearForm() {
      this.name = "";
      this.surname = "";
      this.patronymic = "";
      this.club = "";
    },
    closeCoachOpenSport() {
      this.$emit("closeCoachOpenSport");
    },
  },
  mounted() {},
};
</script>

<style></style>
