<template>
  <footer class="footer">
    <p>Версия сайта: {{ version }}</p>
  </footer>
</template>

<script>
  import { version } from "../../package.json";

  export default {
    data() {
      return {
        version
      };
    }
  };
</script>

<style scoped>
.footer {
  text-align: center;
  padding: 10px;
  font-size: 14px;
  color: #666;
}
</style>
