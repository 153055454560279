<template>
  <div class="home">
   <template v-if="token === ''">
    <Header message="Главная" />
   </template>
  <div class="main-content">
    <template v-if="token">
    <Header message="Главная" />
    <AppMiniRouting/>
    </template>
    <competitions-blocks />
  </div>
  </div>
</template>

<script>
import CompetitionsBlocks from "../components/CompetitionsBlocks.vue";
import Header from "../components/Header.vue";
import AppMiniRouting from '@/components/MiniRouting.vue'
import { mapState } from 'vuex'
export default {
  components: { AppMiniRouting, Header, CompetitionsBlocks },
  name: "Home",
  computed: {
    ...mapState({
      token: (state) => state.auth.token,
    })
  }
};
</script>
