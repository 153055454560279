<template>
  <div class="accordion" ref="dropdown">
    <!-- Статичный текст вместо поля ввода -->
    <div class="accordion-header" @click.stop="toggleAccordion">
      <span class="">{{ inputValue || 'Выберите категорию...' }}</span>
    </div>

    <!-- Список категорий с анимацией открытия/закрытия -->
    <ul v-if="isOpen && filteredOptions.length" class="options-list" :class="{ 'is-open': isOpen }">
      <li
          v-for="(option, index) in filteredOptions"
          :key="index"
          class="option-item"
          @click="selectOption(option)"
      >
        {{ option.name }}
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: "AppCategorySelect",
    data() {
      return {
        inputValue: this.default ? this.default : '', // Текущее значение выбранной категории
        isOpen: false, // Статус открытия списка опций
        filteredOptions: [], // Отфильтрованные опции
      };
    },
    props: {
      default: {
        type: [String, Object],
        required: false,
        default: null,
      },
      allCategory: {
        type: Array,
        required: false,
        default: () => [],
      },
    },
    watch: {
      isOpen(newValue) {
        if (newValue) {
          document.addEventListener('click', this.handleClickOutside);
        } else {
          document.removeEventListener('click', this.handleClickOutside);
        }
      },
      allCategory(newOptions) {
        this.filteredOptions = newOptions; // Обновление filteredOptions при изменении options
      },
    },
    methods: {
      toggleAccordion() {
        this.isOpen = !this.isOpen;
      },
      handleClickOutside(event) {
        if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
          this.isOpen = false;
        }
      },
      selectOption(option) {
        this.isOpen = false;
        if(option.name !== this.inputValue) {
          this.$emit('categorySelected', option); // Отправляем событие выбора категории
        }
        this.inputValue = option.name; // Обновляем значение inputValue на выбранную категорию

      },
    },
    mounted() {
      this.filteredOptions = this.allCategory;
      // Если есть дефолтное значение, оно отображается
      if (this.default && typeof this.default === 'string') {
        this.inputValue = this.default;
      } else if (this.default && typeof this.default === 'object' && this.default.name) {
        this.inputValue = this.default.name;
      }
    },
    beforeDestroy() {
      document.removeEventListener('click', this.handleClickOutside);
    },
  };
</script>
