<template>
  <div class="exercises-container">
    <!-- INDIVIDUAL Exercises -->
    <div class="category" v-if="individualExercises && individualExercises.length">
      <div class="category-header" @click="toggleVisibility('individual')">
        <span class="text-title-20">Количество по категориям в Индивидуальной программе</span>
        <span :class="{'arrow-down': showIndividual, 'arrow-right': !showIndividual}"><img src="../../assets/svg/arrow-right.svg" alt=""></span>
      </div>
      <ul v-show="showIndividual" class="exercise-list">
        <li v-for="(exercise, index) in individualExercises" :key="index">
          <p class="tag tag-finish">{{ exercise.rank }}</p>
          <p class="exercise-list__text">{{ exercise.name }}</p>
          <p class="exercise-list__count">Количество заявок:  <span class="bold"> {{ exercise.count }} </span></p>
        </li>
      </ul>
    </div>
    <AppBarChartIndividual/>
    <!-- GROUP Exercises -->
    <div class="category" v-if="groupExercises && groupExercises.length">
      <div class="category-header" @click="toggleVisibility('group')">
        <span class="text-title-20">Количество по категориям в Групповых упражнениях</span>
        <span :class="{'arrow-down': showGroup, 'arrow-right': !showGroup}"><img src="../../assets/svg/arrow-right.svg" alt=""></span>
      </div>
      <ul v-show="showGroup" class="exercise-list">
        <li v-for="(exercise, index) in groupExercises" :key="index">
          <p class="tag tag-finish">{{ exercise.rank }}</p>
          <p class="exercise-list__text">{{ exercise.name }}</p>
          <p class="exercise-list__count">Количество заявок: <span class="bold">{{ exercise.count }} </span></p>
        </li>
      </ul>
    </div>


    <!-- DUO Exercises -->
    <div class="category" v-if="duoExercises && duoExercises.length">
      <div class="category-header" @click="toggleVisibility('duo')">
        <span class="text-title-20">Дуэты</span>
        <span :class="{'arrow-down': showDuo, 'arrow-right': !showDuo}"><img src="../../assets/svg/arrow-right.svg" alt=""></span>
      </div>
      <ul v-show="showDuo" class="exercise-list">
        <li v-for="(exercise, index) in duoExercises" :key="index">
          <p class="tag tag-finish">{{ exercise.rank }}</p>
          <p class="exercise-list__text">{{ exercise.name }}</p>
          <p class="exercise-list__count">Количество заявок: <span class="bold"> {{ exercise.count }} </span></p>
        </li>
      </ul>
    </div>

    <!-- TRIO Exercises -->
    <div class="category" v-if="trioExercises && trioExercises.length">
      <div class="category-header" @click="toggleVisibility('trio')">
        <span class="text-title-20">Трио</span>
        <span :class="{'arrow-down': showTrio, 'arrow-right': !showTrio}"><img src="../../assets/svg/arrow-right.svg" alt=""></span>
      </div>
      <ul v-show="showTrio" class="exercise-list">
        <li v-for="(exercise, index) in trioExercises" :key="index">
          <p class="tag tag-finish">{{ exercise.rank }}</p>
          <p class="exercise-list__text">{{ exercise.name }}</p>
          <p class="exercise-list__count">Количество заявок:  <span class="bold">{{ exercise.count }} </span></p>
        </li>
      </ul>
    </div>
    <AppBarChartGroup/>
  </div>
</template>

<script>
  import AppBarChartGroup from '@/components/Statistics/BarGroup.vue'
  import AppBarChartIndividual from '@/components/Statistics/BarIndividual.vue'

  export default {
    name: "AppStatistics",
    components: { AppBarChartIndividual, AppBarChartGroup },
    data() {
      return {
        showGroup: true,
        showIndividual: true,
        showDuo: true,
        showTrio: true,
      };
    },
    props: {
      app_stat: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      groupExercises() {
        return this.app_stat ? this.app_stat.filter(item => item.typesPrograms === "GROUP") : [];
      },
      individualExercises() {
        return this.app_stat ? this.app_stat.filter(item => item.typesPrograms === "INDIVIDUAL") : [];
      },
      duoExercises() {
        return this.app_stat ? this.app_stat.filter(item => item.typesPrograms === "DUO") : [];
      },
      trioExercises() {
        return this.app_stat ? this.app_stat.filter(item => item.typesPrograms === "TRIO") : [];
      }
    },
    methods: {
      toggleVisibility(type) {
        switch(type) {
          case 'group':
            this.showGroup = !this.showGroup;
            break;
          case 'individual':
            this.showIndividual = !this.showIndividual;
            break;
          case 'duo':
            this.showDuo = !this.showDuo;
            break;
          case 'trio':
            this.showTrio = !this.showTrio;
            break;
        }
      }
    }
  };
</script>

<style scoped>
/* Стили для заголовков категорий */
.category-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  margin-top: 10px;
}

.arrow-down, .arrow-right {
  margin-left: 10px;
  transition: transform 0.3s ease;
  display: flex;
  img {
    width: 30px;
    height: 30px;
  }
}

.arrow-down {
  transform: rotate(90deg);
}

.exercise-list {
  list-style: none;
  padding: 0;
  margin: 10px 0;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;


}
.exercise-list__text {
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  margin: 10px 0px;
}
.exercise-list__count {
  font-size: 14px;
  color: #818181;
}
.exercise-list li {
  background-color: #fff;
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 13px 30px rgba(0, 0, 0, 0.05);
}
.toggle-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.programs-container {
  margin-top: 20px;
}

.program-category {
  margin-bottom: 30px;
}

.category-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.card {
  flex: 1 1 200px;
  padding: 15px;
  background: white;
  box-shadow: 10px 10px 20px #d9d9d9, -10px -10px 20px #ffffff;
  border-radius: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.card-title {
  font-size: 1.1rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
}

.card-count-container {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}

.card-count-label {
  color: #666;
}

.card-count {
  font-weight: bold;
  color: #333;
  margin-left: 5px;
}

/* Адаптивность */
@media (max-width: 768px) {
  .card-container {
    flex-direction: column;
    gap: 15px;
  }
}

@media (max-width: 768px) {
  .category-header {
    font-size: 16px;
  }

  .exercise-list li {
    font-size: 14px;
  }
}
</style>
