<template>
  <!-- Отображение собранных заявок -->
  <div class="loader-container">
    <div class="loader-overlay-mini" v-if="isLoading">
      <div class="loader-spinner"></div>
    </div>
  <div class="search">

    <template v-if="this.aboutRegistrApp">
      <item-app-individual
        v-for="(item, index) in this.aboutRegistrApp.sportsmans"
        :key="index"
        @deleteItemAppSportsmen="deleteItemAppSportsmen"
        :item_sport="item"
        :index="index"
      />
    </template>


    <!-- Создание собранных заявок -->
    <div
      class="search__wrap-block-select"
      v-if="(this.aboutRegistrApp.status !== 'CLOSE_EDIT_APPLICATION')"
    >
<!--      id="step1"-->
      <div class="search__select-categ" >
        <select-with-animate-categ
          @input="safeIndividualCateg"
          :default="this.defaultCategory"
          :options="this.individualCategories"
          v-if="this.individualCategories !== null"
          ref="categSelect"
        />
      </div>
      <div class="search__add-sportsmen" v-if="dataIdToIndSportsmen && dataIdToIndSportsmen.categId" >
        <AppAddPeopleSelect    v-model="selectPeople"
                               :selectIndCateg="this.safeIndividualCategories"
                               @submitAdd="safeIndividualPeople"
                               @openModal="openModal('isShowAddSportsmen')"
                              :default="this.defaultIndividualSporsmen"
                              :options="this.individualSportsmens"
        :windowWidth="isMobile"/>
      </div>
      <div class="search__add-sportsmen-text" v-else>
        <p class="tag tag-open">Для добавления спортсменов выберите категорию</p>
      </div>
    </div>
<!--    <div class="" id="step2">-->
    <div class="button-add__title-with-svg"  v-if="dataIdToIndSportsmen && dataIdToIndSportsmen.categId"  @click="openModal('isShowAddSportsmen')" >
      <div class="button-add__title" >
        <p class="button-add__title-text">Добавить нового спортсмена в категорию</p>
      </div>

      <div class="button-add__wrap-svg">
        <img src="../../assets/svg/plus.svg" alt="plus" />
      </div>
    </div>
<!--    </div>-->
    <div class="validation" v-if="this.validationErrorsAddSportsmen !== null">
      <p class="validation__text">{{ validationErrorsAddSportsmen.message }}</p>
    </div>
    <div class="about-application" v-if="this.aboutRegistrApp">
      <p class="about-application__text">
        Итого в заявке:
        {{ this.aboutRegistrApp.sportsmans.length }} спортсменов
      </p>
    </div>
    <!-- Создание собранных заявок -->
    <app-modal-add-sportsmen
        v-if="isShowAddSportsmen"
        :application_category="dataIdToIndSportsmen"
        @close="closeModal('isShowAddSportsmen')"
        @addCoach="openModal('isShowAddCoach')"
    />
    <modal-add-coach
        v-if="isShowAddCoach"
        @close="closeModal('isShowAddCoach')"
    />
  </div>
  </div>
</template>

<script>
import { actionTypes } from "@/store/modules/registration";
import { mapState } from "vuex";
import SelectWithAnimateCateg from "../customSelect/SelectWithAnimateCateg.vue";
// import introJs from 'intro.js';
// import 'intro.js/introjs.css';
// import CustomSelectAppPeople from "../customSelect/CustomSelectAppPeople.vue";

import ItemAppIndividual from "./ItemAppIndividual.vue";
import AppAddPeopleSelect from '@/components/customSelect/AddPeopleSelect.vue'
import AppModalAddSportsmen from '@/components/Modal/ModalAddSportsmen.vue'
import ModalAddCoach from '@/components/Modal/ModalAddCoach.vue'
export default {
  name: "AppIndividualApplication",
  data() {
    return {
      defaultCategory: "Выберите категорию",
      defaultIndividualSporsmen: "Выберите спортсмена",
      safeIndividualCategories: {},
      dataIdToIndSportsmen: {},
      windowWidth: window.innerWidth, // Инициализируем начальное значение
      isShowAddSportsmen: false,
      isShowAddCoach: false,
      selectPeople: [],
      sportsmenId: [],
      intro: null,

    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.registration.isLoading,
      individualCategories: (state) => state.registration.individualCategories,
      individualSportsmens: (state) => state.registration.individualSportsmens,
      aboutRegistrApp: (state) => state.registration.aboutRegistrApp,
      validationErrorsAddSportsmen: (state) => state.registration.validationErrorsAddSportsmen,
    }),
    isMobile() {
      return this.windowWidth <= 1024; // Логика определения мобильного режима
    },
  },
  components: {
    ModalAddCoach,
    AppModalAddSportsmen,
    AppAddPeopleSelect,
    SelectWithAnimateCateg,
    // CustomSelectAppPeople,
    ItemAppIndividual,
  },
  methods: {
    // startTour() {
    //   this.intro = introJs();
    //   this.intro.setOptions({
    //     steps: [
    //       { element: '#step1', intro: 'Это первый шаг, выберите категорию', position: 'bottom' },
    //       { element: '#step2', intro: 'Здесь вы можете создать нового спортсмена', position: 'bottom' },
    //       { element: '#step3', intro: 'После добавления вы найдете его здесь, поставите галочку и добавится в заявку' },
    //     ],
    //     showProgress: true,  // Показывать прогресс тура
    //     showBullets: false,  // Убрать навигационные буллеты
    //     exitOnOverlayClick: false,  // Отключить выход при нажатии на затемненный фон
    //     nextLabel: 'Следующий',  // Лейблы кнопок
    //     prevLabel: 'Назад',
    //     doneLabel: 'Готово',
    //   });
    //
    //   // Запуск тура
    //   this.intro.start();
    // },
    closeModal(modalProperty) {
      this[modalProperty] = false;
      // Проверяем, нужно ли оставить скролл заблокированным (если есть другие открытые модальные окна)
      if (!this.anyModalOpen()) {
        document.body.classList.remove('no-scroll');
      }
      this.scrollToEndOfPage()
    },
    anyModalOpen() {
      // Проверяем, открыто ли еще какое-либо модальное окно
      return this.isShowAddSportsmen || this.isShowAddCoach
    },
    openModal(modalProperty) {
      this[modalProperty] = true;
      document.body.classList.add('no-scroll');
    },
    scrollToEndOfPage() {
      // Прокрутка страницы вниз до конца
      window.scrollTo({
        top: document.body.scrollHeight, // Прокрутить до конца страницы
        behavior: 'smooth', // Плавная прокрутка
      });
    },
    deleteItemAppSportsmen(item) {
      this.aboutRegistrApp.sportsmans.splice(
        0,
        this.aboutRegistrApp.sportsmans.length,
        ...this.aboutRegistrApp.sportsmans.filter(
          (n) => n.idApplication !== item.idApplication
        )
      );
      this.$store
        .dispatch(actionTypes.deleteSportsmenApp, {
          idCompetition: this.$route.params.id,
          idSportsmen: item.idApplication,
        })
        .then(() => {})
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
    safeIndividualPeople() {

      this.sportsmenId = this.selectPeople.map((item) => item.id);
      this.$store
        .dispatch(actionTypes.addSportsmenApp, {
          idCompetition: this.$route.params.id,
          idSportsmen: this.sportsmenId,
          idCategory: this.safeIndividualCategories.id,
        })
        .then(() => {
          this.safeIndividualCategories = '';
          this.dataIdToIndSportsmen = {}
          this.selectPeople = []
        })
        .catch((er) => {
          console.log(er, "Error");
        });
      this.$refs.categSelect.updateData();
    },
    showModalAddSportsmen() {
      //ОТЧИСТКА ВЫБОРА КАТЕГОРИИ
      this.$refs.categSelect.updateData();
      this.$emit("showModalAddSportsmen");
    },
    safeIndividualCateg(item) {
      this.safeIndividualCategories = item;
      // получение спортсменов по id категории
      this.dataIdToIndSportsmen = {
        categId: item.id,
        idCompetition: this.$route.params.id,
      };
      this.$store
        .dispatch(
          actionTypes.getIndividualSportsmens,
          this.dataIdToIndSportsmen
        )
        .then(() => {

          // this.safeIndividualCategories = '';
        })
        .catch((er) => {
          console.log(er, "Error");
        });
    },
  },
  mounted() {
    // this.startTour();
    // if (!localStorage.getItem('hasSeenTour')) {
    //   this.startTour();
    //   localStorage.setItem('hasSeenTour', 'true');  // Пометка, что тур уже был показан
    // }
  },
};
</script>

<style scoped>

</style>

