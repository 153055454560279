import Vue from "vue";
import Vuex from "vuex";
import auth from "@/store/modules/auth";
import info from "@/store/modules/information";
import registrationComp from "@/store/modules/registrationComp";
import competition from "@/store/modules/competition";
import registration from "@/store/modules/registration";
import enteredData from "@/store/modules/enteredData";
import createStartList from "@/store/modules/createStartList";
import mandated from "@/store/modules/mandated";
import statistics from "@/store/modules/statistics";
import withoutAuth from "@/store/modules/withoutAuthorization"
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    selectCity: ["Новосибирск", "Краснообск","Бердск", "Академгородок"],
    selectRegion: ["Новосибирская область"],
    selectCountry: ["Россия", "Казахстан", "Беларусь"],
    defaultRegion: "Выберите регион",
    defaultCity: "Выберите город",
    defaultCountry: "Выберите страну",
    defaultRang: "Выберите разряд",
  },
  mutations: {},
  actions: {},
  modules: {
    auth,
    info,
    registrationComp,
    competition,
    registration,
    enteredData,
    createStartList,
    mandated,
    withoutAuth,
    statistics
  },
});
