<template>
  <transition name="modal" @click="$emit('close')">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <div class="modal__cross" @click="$emit('close')">
              <img src="../../assets/svg/cross.svg" alt="cross" />
            </div>
          </div>
          <form @submit.prevent="safeInfoGroup" id="#modalModificationGroup">
            <div class="modal-body">
              <div class="modal-body__title">
                <p class="modal__title">Изменение группы</p>
              </div>
              <div class="modal-scroll-max">
              <div class="input-wrap__column">
                <div class="input-wrap__label-with-input-column">
                  <div class="input-wrap__label">
                    <p class="text-label">Название группы:</p>
                  </div>
                  <input
                    type="text"
                    class="input-light-text"
                    :class="{
                      'error-border-input':
                        isSubmitted && $v.putData.name.$error,
                    }"
                    placeholder="Заполните название группы"
                    name="name"
                    v-model="item_group.name"
                  />
                  <img
                    src="~@/assets/svg/Error.svg"
                    alt="error"
                    v-if="isSubmitted && $v.putData.name.$error"
                    class="wrapValidationInput__error"
                  />
                </div>
              </div>

              <div class="modal-body__table">
                <div
                  v-if="isSubmitted && !$v.checkedCoachID.required"
                  class="validation"
                >
                  <p class="validation__text">*Назначьте тренера</p>
                </div>
                <div class="input-wrap__label">
                  <p class="text-label">Тренеры:</p>
                </div>
                <table-coach
                  ref="check"
                  @addCoach="addCoach"
                  @checkedCoach="checkedCoach"
                />
              </div>
                <div v-if="isSubmitted && $v.selectPeople.$error && !$v.selectPeople.minLength"  class="message-about-empty empty">
                  <p class="message-about-empty__text"> Добавлено менее 5 спортсменов в группу!</p>
                </div>
              <div class="input-wrap">
                <div class="input-wrap__label">
                  <p class="text-label">Состав группы</p>
                </div>

                <div
                  class="group-list-in-modal"
                  v-if="selectPeople.length !== 0"
                >
                  <table class="custom-table">
                    <thead>
                      <tr>
                        <th class="empty_th"></th>
                        <th class="th__name">Ф.И.О.</th>
                        <th class="th__years">Год р.</th>
                        <th class="th__section">Разряд</th>
                        <th class="th__trainer">Тренеры</th>
                      </tr>
                    </thead>
                    <tbody>
                      <item-table-sportsmen
                        v-for="(sportsmen, i) in selectPeople"
                        :key="i"
                        :item_sportsmen="sportsmen"
                        @editSportsmen="editSportsmen"
                        @deleteSportsmen="deleteSportsmen"
                      />
                    </tbody>
                  </table>
                </div>
                <AppAddPeopleSelect
                    v-model="selectPeople"
                    :options="allSportsmens"
                    :class="{ 'error-border-input': isSubmitted && $v.selectPeople.$error }"
                    :windowWidth="isMobile"
                />
                <div @click="addSportsmen" class="add-person-button-div">
                  <div class="add-person-div">
                    <img
                        src="../../assets/svg/plus.svg"
                        class="add-person-div__img"
                        alt="cross"
                    />
                  </div>

                  <div class="add-person-div__text">Создать нового спортсмена</div>
                </div>
              </div>
              <div class="input-wrap__row">
                <div class="input-wrap__label-with-input-row">
                  <div class="input-wrap__label">
                    <p class="text-label">Область/регион:</p>
                  </div>
                  <AppRegionSelect @regionSelected="safeRegion"  :default="item_group.region"/>
                </div>
                <div class="input-wrap__label-with-input-row">
                  <div class="input-wrap__label">
                    <p class="text-label">Город:</p>
                  </div>
                  <AppCitySelect :idRegion="region.id"   @citySelected="safeCity"  :default="item_group.city" />
                </div>
              </div>
            </div>
            </div>
            <div class="modal-footer">
              <div class="modal-footer__button">
                <button class="mandated__tag dark">Сохранить</button>
              </div>
              <div class="modal-footer__button">
                <button  type="button" class="mandated__tag button-cancel" @click="$emit('close')">
                  Отменить
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { actionTypes } from "@/store/modules/registration";
import { actionTypesEntered } from "@/store/modules/enteredData";
import { mapState } from "vuex";
import TableCoach from "../TableInModal/TableCoach.vue";
// import CustomSelectAppPeople from "../customSelect/CustomSelectAppPeople.vue";

import ItemTableSportsmen from "../Table/ItemTableSportsmen.vue";
import { minLength, required } from 'vuelidate/lib/validators'
import AppRegionSelect from '@/components/customSelect/RegionSelect.vue'
import AppCitySelect from '@/components/customSelect/CitySelect.vue'
import AppAddPeopleSelect from '@/components/customSelect/AddPeopleSelect.vue'
// import AppAddPeopleSelect from '@/components/customSelect/AddPeopleSelect.vue'
export default {
  name: "AppModalModiGroup",
  data() {
    return {
      name: "",

      city: this.item_group.city || "",
      region: this.item_group.region || "",
      checkedCoachID: [],

      selectPeople: this.item_group.sportsmen || [],
      sportsmenId: [],
      selectRegion: ["Новосибирская область"],
      selectCity: ["Новосибирск","Краснообск", "Бердск", "Академгородок"],
      defaultRegion: "Выберите регион",
      defaultCity: "Выберите город",
      defaultSportsmen: "Добавление спортсмена",
      putData: [],
      isSubmitted: false,
      windowWidth: window.innerWidth, // Инициализируем начальное значение
    };
  },
  props: {
    item_group: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  validations: {
    putData: {
      name: {
        required,
      },
    },
    checkedCoachID: {
      required,
    },
    sportsmenId: {
      required,
    },
    selectPeople: {
      required,
      minLength: minLength(5)
    },
  },
  components: {
    AppAddPeopleSelect,
    AppCitySelect,
    AppRegionSelect,
    TableCoach,
    ItemTableSportsmen,
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.registration.isLoading,
      selectRank: (state) => state.registration.selectRank,
      allSportsmens: (state) => state.enteredData.allSportsmens,
    }),
    isMobile() {
      return this.windowWidth <= 1024; // Логика определения мобильного режима
    },
  },
  methods: {
    safePeople(item) {
      this.selectPeople = item.allInformation;
    },
    deleteSportsmen(item) {
      // console.log(item, 'Удаляю этого')
      this.selectPeople = this.selectPeople.filter((n) => n.id !== item.id);
    },
    addSportsmen() {
      this.$emit("addSportsmen");
    },
    checkedCoach(item) {
      this.checkedCoachID = item;
    },
    safeInfoGroup() {
      this.sportsmenId = this.selectPeople.map((item) => item.id);
      this.putData = {
        name: this.item_group.name,
        region: this.region.name || this.region,
        city: this.city,
        coachId: this.checkedCoachID,
        sportsmenId: this.sportsmenId,
        id: this.item_group.id,
        // groupId: this.item_group.groupId,
      };

      this.isSubmitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        return console.log("Данные не заполнены", this.$v.$invalid);
      } else {
        this.$store
            .dispatch(actionTypesEntered.editGroup, this.putData)
            .then(() => {
              // console.log(res)
              this.$emit("close");
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      }
    },
    addCoach() {
      this.$emit("addCoach");
    },
    editSportsmen(item) {
      this.$emit("editSportsmen", item);
    },
    safeCity(item) {
      // console.log(item, "city");
      this.city = item;
    },
    safeRegion(item) {
      // console.log(item, "region");
      this.region = item;
    },
    safeRank(item) {
      // console.log(item, "rank");
      this.rank = item;
    },
    updateChoiceCheckedCoach() {
      this.$refs.check._data.checkedCoach = this.item_group.coachId;
    },
    findCity() {
      this.selectCity.map((item) => {
        if (this.item_group.city === item) {
          return (this.city = item);
        }
      });
    },
    findRegion() {
      this.selectRegion.map((item) => {
        if (this.item_group.region === item) {
          return (this.region = item);
        }
      });
    },
  },
  mounted() {
    this.findCity();
    this.findRegion();
    //Получение всех тренеров
    this.$store
      .dispatch(actionTypesEntered.getCoaches)
      .then(() => {
        this.updateChoiceCheckedCoach();
      })
      .catch((er) => {
        console.log("Ошибка", er);
      });
    //получение все разрядов
    this.$store
      .dispatch(actionTypes.getRank)
      .then(() => {})
      .catch((er) => {
        console.log("Ошибка", er);
      });
  },
};
</script>

<style></style>
