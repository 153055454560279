<template>
  <tbody>
  <tr>
    <td class="table__icon-btn">
      <span class="table__icon-edit"  @click="editJuj()">✏️</span>
      <span class="table__icon-delete"  @click="deleteJuj()">🗑️</span>
    </td>
    <td class="tb__checkbox">
      {{ item_juj.surname }} {{ item_juj.name }}
      {{ item_juj.patronymic }}
    </td>
    <td class="tb__name">
      {{ item_juj.category }}
    </td>
  </tr>
  </tbody>
</template>

<script>
export default {
  name: "AppItemTableSportsmen",
  data() {
    return {
      checkedP: false,
    };
  },
  props: {
    item_juj: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    onChange() {
      this.$emit("input", this.checkedP);
      this.$emit("updateCheck");
    },
    deleteJuj() {
      this.$emit("deleteJuj", this.item_juj);
    },
    editJuj() {
      this.$emit("editJuj", this.item_juj);
    },
  },
};
</script>

<style></style>
