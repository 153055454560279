<template>
  <transition name="modal" @click="$emit('close')">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-mini-container">
          <div class="modal-header">
            <div class="modal__cross" @click="$emit('close')">
              <img src="../../assets/svg/cross.svg" alt="cross" />
            </div>
          </div>
          <div class="modal-body">
            <div class="modal-body__title">
              <div class="">
                <p class="modal__title-mini">Вы действительно хотите удалить</p>
                <p
                  class="modal__title-mini"
                  v-if="this.mutableRole === 'SPORTSMAN'"
                >
                  {{ this.item.surname }} {{ this.item.name }} ?
                </p>
                <p
                  class="modal__title-mini"
                  v-if="this.mutableRole === 'GROUP'"
                >
                  "{{ this.item.name }}" ?
                </p>
                <p
                    class="modal__title-mini"
                    v-if="this.mutableRole === 'TRIO'"
                >
                  "{{ this.item.name }}" ?
                </p>
                <p
                    class="modal__title-mini"
                    v-if="this.mutableRole === 'DUO'"
                >
                  "{{ this.item.name }}" ?
                </p>
                <p
                  class="modal__title-mini"
                  v-if="this.mutableRole === 'COACH'"
                >
                  {{ this.item.fio }} ?
                </p>
                <p
                  class="modal__title-mini"
                  v-if="this.mutableRole === 'JUDGE'"
                >
                  {{ this.item.surname }} {{ this.item.name }}
                  {{ this.item.patronymic }} ?
                </p>
              </div>
            </div>
            <div class="">
              <p>
                **Удаляя данного представителя из списка, он не удаляется из
                поданных заявок на соревнования.
              </p>
            </div>
          </div>
          <div class="modal-footer">
            <div class="modal-footer__button">
              <button class="mandated__tag dark" @click="deleteItem()">Да</button>
            </div>
            <div class="modal-footer__button">
              <button class="mandated__tag button-cancel" @click="$emit('close')">
                Нет
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from "vuex";
import { actionTypesEntered } from "@/store/modules/enteredData";

export default {
  name: "AppModalDelete",
  data() {
    return {
      mutableRole: this.role,
    };
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    role: {
      type: String,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapState({
      allSportsmens: (state) => state.enteredData.allSportsmens,
      allGroups: (state) => state.enteredData.allGroups,
      coaches: (state) => state.enteredData.allCoaches,
      judges: (state) => state.enteredData.allJuj,
      //Трио
      allTrios: (state) => state.enteredData.allTrios,
      //Дуэты
      allDuos: (state) => state.enteredData.allDuos,
    }),
  },
  methods: {
    deleteItem() {
      if (this.mutableRole === "SPORTSMAN") {
        //   удаление спортсмена
        this.$store
          .dispatch(actionTypesEntered.deleteSportsmen, this.item.id)
          .then(() => {
            this.$emit("close");
            this.allSportsmens.splice(
              0,
              this.allSportsmens.length,
              ...this.allSportsmens.filter((n) => n.id !== this.item.id)
            );
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
      } else if (this.mutableRole === "GROUP") {
        this.$store
          .dispatch(actionTypesEntered.deleteGroup, this.item.id)
          .then(() => {
            this.$emit("close");
            this.allGroups.splice(
              0,
              this.allGroups.length,
              ...this.allGroups.filter((n) => n.id !== this.item.id)
            );
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
      } else if (this.mutableRole === "COACH") {
        this.$store
          .dispatch(actionTypesEntered.deleteCoach, this.item.id)
          .then(() => {
            this.$emit("close");
            this.coaches.splice(
              0,
              this.coaches.length,
              ...this.coaches.filter((n) => n.id !== this.item.id)
            );
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
      } else if (this.mutableRole === "JUDGE") {
        this.$store
          .dispatch(actionTypesEntered.deleteJuj, this.item.id)
          .then(() => {
            this.$emit("close");
            this.judges.splice(
              0,
              this.judges.length,
              ...this.judges.filter((n) => n.id !== this.item.id)
            );
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
      } else if (this.mutableRole === "TRIO") {
        this.$store
            .dispatch(actionTypesEntered.deleteTrios, this.item.id)
            .then(() => {
              this.$emit("close");
              this.allTrios.splice(
                  0,
                  this.allTrios.length,
                  ...this.allTrios.filter((n) => n.id !== this.item.id)
              );
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      } else if (this.mutableRole === "DUO") {
        this.$store
            .dispatch(actionTypesEntered.deleteDuos, this.item.id)
            .then(() => {
              this.$emit("close");
              this.allDuos.splice(
                  0,
                  this.allDuos.length,
                  ...this.allDuos.filter((n) => n.id !== this.item.id)
              );
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      }
    },
  },
};
</script>

<style></style>
