import enteredDataApi from "@/api/enteredData.js";

const state = {
  isLoading: false, //ожидание запроса
  allCoaches: null,
  allSportsmens: null,
  allGroups: null,
  allJuj: null,
  allTrios: null,
  allDuos: null,
  validationErrorsSportsmen: null,
  validationErrorsJuj: null,
  validationErrorsGroup: null,
  validationErrorsCoach: null,
};

export const mutationTypes = {
  getCoachesStart: "[enteredData] getCoachesStart",
  getCoachesSuccess: "[enteredData] getCoachesSuccess",
  getCoachesFailure: "[enteredData] getCoachesFailure",
  //registrSportsmen
  registrSportsmenStart: "[registration]registrSportsmenStart",
  registrSportsmenSuccess: "[registration] registrSportsmenSuccess",
  registrSportsmenFailure: "[registration] registrSportsmenFailure",
  // getSportsmen
  getSportsmensStart: "[enteredData] getSportsmenStart",
  getSportsmensSuccess: "[enteredData] getSportsmenSuccess",
  getSportsmensFailure: "[enteredData] getSportsmensFailure",
  // deleteSportsmen
  deleteSportsmenStart: "[enteredData] deleteSportsmenStart",
  deleteSportsmenSuccess: "[enteredData] deleteSportsmenSuccess",
  deleteSportsmenFailure: "[enteredData] deleteSportsmenFailure",
  // editSportsmen
  editSportsmenStart: "[enteredData] editSportsmenStart",
  editSportsmenSuccess: "[enteredData] editSportsmenSuccess",
  editSportsmenFailure: "[enteredData] editSportsmenFailure",
  // registrCoach
  registrCoachStart: "[enteredData] registrCoachStart",
  registrCoachSuccess: "[enteredData] registrCoachSuccess",
  registrCoachFailure: "[enteredData] registrCoachFailure",
  // deleteCoach
  deleteCoachStart: "[enteredData] deleteCoachStart",
  deleteCoachSuccess: "[enteredData] deleteCoachSuccess",
  deleteCoachFailure: "[enteredData] deleteCoachFailure",
  // editCoach
  editCoachStart: "[enteredData] editCoachStart",
  editCoachSuccess: "[enteredData] editCoachSuccess",
  editCoachFailure: "[enteredData] editCoachFailure",
  // registrGroup
  registrGroupStart: "[enteredData] registrGroupStart",
  registrGroupSuccess: "[enteredData] registrGroupSuccess",
  registrGroupFailure: "[enteredData] registrGroupFailure",
  // getGroups
  getGroupsStart: "[enteredData] getGroupsStart",
  getGroupsSuccess: "[enteredData] getGroupsSuccess",
  getGroupsFailure: "[enteredData] getGroupsFailure",
  // deleteGroup
  deleteGroupStart: "[enteredData] deleteGroupStart",
  deleteGroupSuccess: "[enteredData] deleteGroupSuccess",
  deleteGroupFailure: "[enteredData] deleteGroupFailure",
  // editGroup
  editGroupStart: "[enteredData] editGroupStart",
  editGroupSuccess: "[enteredData] editGroupSuccess",
  editGroupFailure: "[enteredData] editGroupFailure",
  // registrJuj
  registrJujStart: "[enteredData] registrJujStart",
  registrJujSuccess: "[enteredData] registrJujSuccess",
  registrJujFailure: "[enteredData] registrJujFailure",

  // getJuj
  getJujStart: "[enteredData] getJujStart",
  getJujSuccess: "[enteredData] getJujSuccess",
  getJujFailure: "[enteredData] getJujFailure",
  // deleteJuj
  deleteJujStart: "[enteredData] deleteJujStart",
  deleteJujSuccess: "[enteredData] deleteJujSuccess",
  deleteJujFailure: "[enteredData] deleteJujFailure",
  // editJuj
  editJujStart: "[enteredData] editJujStart",
  editJujSuccess: "[enteredData] editJujSuccess",
  editJujFailure: "[enteredData] editJujFailure",

  //ТРИО
  getTriosStart: "[enteredData] getTriosStart",
  getTriosSuccess: "[enteredData] getTriosSuccess",
  getTriosFailure: "[enteredData] getTriosFailure",

  deleteTriosStart: "[enteredData] deleteTriosStart",
  deleteTriosSuccess: "[enteredData] deleteTriosSuccess",
  deleteTriosFailure: "[enteredData] deleteTriosFailure",

  editTriosStart: "[enteredData] editTriosStart",
  editTriosSuccess: "[enteredData] editTriosSuccess",
  editTriosFailure: "[enteredData] editTriosFailure",

  registerTriosStart: "[enteredData] registerTriosStart",
  registerTriosSuccess: "[enteredData] registerTriosSuccess",
  registerTriosFailure: "[enteredData] registerTriosFailure",


  //ДУЭТЫ
  getDuosStart: "[enteredData] getDuosStart",
  getDuosSuccess: "[enteredData] getDuosSuccess",
  getDuosFailure: "[enteredData] getDuosFailure",

  deleteDuosStart: "[enteredData] deleteDuosStart",
  deleteDuosSuccess: "[enteredData] deleteDuosSuccess",
  deleteDuosFailure: "[enteredData] deleteDuosFailure",

  editDuosStart: "[enteredData] editDuosStart",
  editDuosSuccess: "[enteredData] editDuosSuccess",
  editDuosFailure: "[enteredData] editDuosFailure",

  registerDuosStart: "[enteredData] registerDuosStart",
  registerDuosSuccess: "[enteredData] registerDuosSuccess",
  registerDuosFailure: "[enteredData] registerDuosFailure",
};

export const actionTypesEntered = {
  getCoaches: "[enteredData] getCoaches",
  registrSportsmen: "[enteredData] registrSportsmen",
  getSportsmens: "[enteredData] getSportsmens",
  deleteSportsmen: "[enteredData] deleteSportsmen",
  editSportsmen: "[enteredData] editSportsmen",
  registrCoach: "[enteredData] registrCoach",
  deleteCoach: "[enteredData] deleteCoach",
  editCoach: "[enteredData] editCoach",
  registrGroup: "[enteredData] registrGroup",
  getGroups: "[enteredData] getGroups",
  deleteGroup: "[enteredData] deleteGroup",
  editGroup: "[enteredData] editGroup",
  registrJuj: "[enteredData] registrJuj",
  getJuj: "[enteredData] getJuj",
  deleteJuj: "[enteredData] deleteJuj",
  editJuj: "[enteredData] editJuj",
  //Трио
  getTrios: "[enteredData] getTrios",
  registerTrios: "[enteredData] registerTrios",
  deleteTrios: "[enteredData] deleteTrios",
  editTrios: "[enteredData] editTrios",

  //Дуэты
  getDuos: "[enteredData] getDuos",
  registerDuos: "[enteredData] registerDuos",
  deleteDuos: "[enteredData] deleteDuos",
  editDuos: "[enteredData] editDuos",
};

const mutations = {
  //getCoahes
  [mutationTypes.getCoachesStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getCoachesSuccess](state, info) {
    state.isLoading = false;
    state.allCoaches = info.result;
  },
  [mutationTypes.getCoachesFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },

  // deleteCoach
  [mutationTypes.deleteCoachStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.deleteCoachSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.deleteCoachFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // registrCoach
  [mutationTypes.registrCoachStart](state) {
    state.isLoading = true;
    state.validationErrorsCoach = null;
  },
  [mutationTypes.registrCoachSuccess](state, payload) {
    state.isLoading = false;
    state.allCoaches = payload.result;
  },
  [mutationTypes.registrCoachFailure](state, payload) {
    state.isLoading = false;
    state.validationErrorsCoach = payload;
  },
  // registrSportsmen
  [mutationTypes.registrSportsmenStart](state) {
    state.isLoading = true;
    state.validationErrorsSportsmen = null;
  },
  [mutationTypes.registrSportsmenSuccess](state, item) {
    state.isLoading = false;
    state.allSportsmens = item.result;
  },
  [mutationTypes.registrSportsmenFailure](state, payload) {
    state.isLoading = false;
    state.validationErrorsSportsmen = payload;
  },


  // getSportsmen
  [mutationTypes.getSportsmensStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getSportsmensSuccess](state, item) {
    state.isLoading = false;
    state.allSportsmens = item.result;
  },
  [mutationTypes.getSportsmensFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  //   deleteSportsmen
  [mutationTypes.deleteSportsmenStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.deleteSportsmenSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.deleteSportsmenFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // editSportsmen
  [mutationTypes.editSportsmenStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.editSportsmenSuccess](state, item) {
    state.isLoading = false;
    state.allSportsmens = item.result;
  },
  [mutationTypes.editSportsmenFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // editCoach
  [mutationTypes.editCoachStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.editCoachSuccess](state, payload) {
    state.isLoading = false;
    state.allCoaches = payload.result;
  },
  [mutationTypes.editCoachFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // registrGroup
  [mutationTypes.registrGroupStart](state) {
    state.isLoading = true;
    state.validationErrorsGroup = null;
  },
  [mutationTypes.registrGroupSuccess](state, payload) {
    state.isLoading = false;
    state.allGroups = payload.result;
  },
  [mutationTypes.registrGroupFailure](state, payload) {
    state.isLoading = false;
    state.validationErrorsGroup = payload;
  },
  // getGroups
  [mutationTypes.getGroupsStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getGroupsSuccess](state, payload) {
    state.isLoading = false;
    state.allGroups = payload.result;
  },
  [mutationTypes.getGroupsFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // deleteGroup
  [mutationTypes.deleteGroupStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.deleteGroupSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.deleteGroupFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // editGroup
  [mutationTypes.editGroupStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.editGroupSuccess](state, payload) {
    state.isLoading = false;
    state.allGroups = payload.result;
  },
  [mutationTypes.editGroupFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // registrJuj
  [mutationTypes.registrJujStart](state) {
    state.isLoading = true;
    state.validationErrorsJuj = null;
  },
  [mutationTypes.registrJujSuccess](state, payload) {
    state.isLoading = false;
    state.allJuj = payload.result;
  },
  [mutationTypes.registrJujFailure](state, payload) {
    state.isLoading = false;
    state.validationErrorsJuj = payload;
  },
  // getJuj
  [mutationTypes.getJujStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getJujSuccess](state, payload) {
    state.isLoading = false;
    state.allJuj = payload.result;
  },
  [mutationTypes.getJujFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // deleteJuj
  [mutationTypes.deleteJujStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.deleteJujSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.deleteJujFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // editJuj
  [mutationTypes.editJujStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.editJujSuccess](state, payload) {
    state.isLoading = false;
    state.allJuj = payload.result;
  },
  [mutationTypes.editJujFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  //ТРИО ПОЛУЧЕНИЕ ВСЕХ ТРИО

  [mutationTypes.getTriosStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getTriosSuccess](state, payload) {
    state.isLoading = false;
    state.allTrios = payload.result;
  },
  [mutationTypes.getTriosFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  //ТРИО УДАЛЕНИЕ ТРИО
  [mutationTypes.deleteTriosStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.deleteTriosSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.deleteTriosFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  //ТРИО ИЗМЕНЕНИЯ ТРИО
  [mutationTypes.editTriosStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.editTriosSuccess](state,payload) {
    state.isLoading = false;
    state.allTrios = payload.result;
  },
  [mutationTypes.editTriosFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  //ДУЭТЫ ДОБАВЛЕНИЕ ДУЭТА
  [mutationTypes.registerTriosStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.registerTriosSuccess](state,payload) {
    state.isLoading = false;
    state.allTrios = payload.result;
  },
  [mutationTypes.registerTriosFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },



  //ДУЭТЫ ПОЛУЧЕНИЕ ВСЕХ ДУЭТОВ
  [mutationTypes.getDuosStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getDuosSuccess](state, payload) {
    state.isLoading = false;
    state.allDuos = payload.result;
  },
  [mutationTypes.getDuosFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  //ДУЭТЫ УДАЛЕНИЕ ДУЭТА
  [mutationTypes.deleteDuosStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.deleteDuosSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.deleteDuosFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  //ДУЭТЫ ИЗМЕНЕНИЯ ДУЭТА
  [mutationTypes.editDuosStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.editDuosSuccess](state,payload) {
    state.isLoading = false;
    state.allDuos = payload.result;
  },
  [mutationTypes.editDuosFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  //ДУЭТЫ ДОБАВЛЕНИЕ ДУЭТА
  [mutationTypes.registerDuosStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.registerDuosSuccess](state,payload) {
    state.isLoading = false;
    state.allDuos = payload.result;
  },
  [mutationTypes.registerDuosFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
};
const actions = {
  //получение всех спортсменов
  [actionTypesEntered.getSportsmens](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getSportsmensStart);
      enteredDataApi
        .getSportsmens()
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.getSportsmensSuccess, response.data);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.getSportsmensFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.getSportsmensFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  //ДОБАВЛЕНИЕ спортсмена
  [actionTypesEntered.registrSportsmen](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.registrSportsmenStart);
      enteredDataApi
        .registrSportsmen(data)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.registrSportsmenSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.registrSportsmenFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.registrSportsmenFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  //удаление спортсмена
  [actionTypesEntered.deleteSportsmen](context, id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.deleteSportsmenStart);
      enteredDataApi
          .deleteSportsmen(id)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.deleteSportsmenSuccess, response.data);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.deleteSportsmenFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.deleteSportsmenFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  //изменение спортсмена
  [actionTypesEntered.editSportsmen](context, editSportsmen) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.editSportsmenStart);
      enteredDataApi
          .editSportsmen(editSportsmen)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.editSportsmenSuccess, response.data);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.editSportsmenFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.editSportsmenFailure, err);
            console.log("ошибка", err);
          });
    });
  },

  //получение всех тренеров
  [actionTypesEntered.getCoaches](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getCoachesStart);
      enteredDataApi
        .getCoaches()
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.getCoachesSuccess, response.data);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.getCoachesFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.getCoachesFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  // добавление тренера
  [actionTypesEntered.registrCoach](context, infoAboutCoach) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.registrCoachStart);
      enteredDataApi
        .registrCoach(infoAboutCoach)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.registrCoachSuccess, response.data);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.registrCoachFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.registrCoachFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  // удаление тренера
  [actionTypesEntered.deleteCoach](context, id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.deleteCoachStart);
      enteredDataApi
        .deleteCoach(id)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.deleteCoachSuccess, response.data);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.deleteCoachFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.deleteCoachFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  //редактирование тренера
  // editCoach
  [actionTypesEntered.editCoach](context, id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.editCoachStart);
      enteredDataApi
        .editCoach(id)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.editCoachSuccess, response.data);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.editCoachFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.editCoachFailure, err);
          console.log("ошибка", err);
        });
    });
  },

  //добавление группы

  [actionTypesEntered.registrGroup](context, addGroup) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.registrGroupStart);
      enteredDataApi
        .registrGroup(addGroup)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.registrGroupSuccess, response.data);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.registrGroupFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.registrGroupFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  //получение всех групп
  [actionTypesEntered.getGroups](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getGroupsStart);
      enteredDataApi
        .getGroups()
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.getGroupsSuccess, response.data);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.getGroupsFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.getGroupsFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  // Удаление группы
  [actionTypesEntered.deleteGroup](context, id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.deleteGroupStart);
      enteredDataApi
        .deleteGroup(id)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.deleteGroupSuccess, response.data);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.deleteGroupFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.deleteGroupFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  // Изменение групп
  [actionTypesEntered.editGroup](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.editGroupStart);
      enteredDataApi
        .editGroup(data)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.editGroupSuccess, response.data);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.editGroupFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.editGroupFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  // Регистрация групп
  [actionTypesEntered.registrJuj](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.registrJujStart);
      enteredDataApi
        .registrJuj(data)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.registrJujSuccess, response.data);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.registrJujFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.registrJujFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  // Получение всех судей
  [actionTypesEntered.getJuj](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getJujStart);
      enteredDataApi
        .getJuj()
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.getJujSuccess, response.data);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.getJujFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.getJujFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  // удаление судьи
  [actionTypesEntered.deleteJuj](context, idJuj) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.deleteJujStart);
      enteredDataApi
        .deleteJuj(idJuj)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.deleteJujSuccess, response.data);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.deleteJujFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.deleteJujFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  // Редактирование судей

  [actionTypesEntered.editJuj](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.editJujStart);
      enteredDataApi
        .editJuj(data)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.editJujSuccess, response.data);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.editJujFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.editJujFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  //  ПОЛУЧЕНИЕ ВСЕХ ТРИО
  [actionTypesEntered.getTrios](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getTriosStart);
      enteredDataApi
          .getTrios(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.getTriosSuccess, response.data);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.getTriosFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.getTriosFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  //  УДАЛЕНИЕ  ТРИО
  [actionTypesEntered.deleteTrios](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.deleteTriosStart);
      enteredDataApi
          .deleteTrios(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.deleteTriosSuccess, response.data);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.deleteTriosFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.deleteTriosFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  //  ИЗМЕНЕНИЕ  ТРИО
  [actionTypesEntered.editTrios](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.editTriosStart);
      enteredDataApi
          .editTrios(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.editTriosSuccess, response.data);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.editTriosFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.editTriosFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  //  ДОБАВЛЕНИЕ  ТРИО
  [actionTypesEntered.registerTrios](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.registerTriosStart);
      enteredDataApi
          .registerTrios(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.registerTriosSuccess, response.data);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.registerTriosFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.registerTriosFailure, err);
            console.log("ошибка", err);
          });
    });
  },


  //ПОЛУЧЕНИЕ ДУЭТОВ
  [actionTypesEntered.getDuos](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getDuosStart);
      enteredDataApi
          .getDuos(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.getDuosSuccess, response.data);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.getDuosFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.getDuosFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  //УДАЛЕНИЕ ДУЭТА
  [actionTypesEntered.deleteDuos](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.deleteDuosStart);
      enteredDataApi
          .deleteDuos(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.deleteDuosSuccess, response.data);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.deleteDuosFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.deleteDuosFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  //ИЗМЕНЕНИЕ ДУЭТА
  [actionTypesEntered.editDuos](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.editDuosStart);
      enteredDataApi
          .editDuos(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.editDuosSuccess, response.data);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.editDuosFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.editDuosFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  //ДОБАВЛЕНИЕ ДУЭТА
  [actionTypesEntered.registerDuos](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.registerDuosStart);
      enteredDataApi
          .registerDuos(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.registerDuosSuccess, response.data);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.registerDuosFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.registerDuosFailure, err);
            console.log("ошибка", err);
          });
    });
  },
};
export default {
  state,
  mutations,
  actions,
};
