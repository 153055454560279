<template>
  <div class="new-login-page">
    <div class="container background-index">
      <header class="new-login-page__header">
        <router-link to="/">
        <div class="header__logo">
          <img src="../assets/img/LogoRG-hor.png" class="header__logo-img" alt="logo">
        </div>
        </router-link>
        <div class="header__btn">
          <router-link to="/">
            <button class="login-btn background-green color-white ">На главную</button>
          </router-link>
        </div>
      </header>
      <div class="new-login-page__main">

        <div class="loader-overlay" v-if="isLoading">
          <div class="loader"></div>
        </div>
        <div class="new-login-page__100-block">
<!--          <div class="new-login-page__mobile-registration">-->
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb-login">
                <li class="breadcrumb-item">
                  <router-link to="/login" class="breadcrumb-text">Вернуться ко входу</router-link>
                  <img  src="../assets/svg/arrow-right.svg" class="breadcrumb-icon" alt="arrow">
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/forgot-password" class="breadcrumb-text">Восстановление пароля</router-link>
                </li>
              </ol>
            </nav>
<!--          </div>-->
          <div class="new-login-page__forgot-title">
            <p class="title-in-page">Восстановление пароля</p>
          </div>
          <form @submit.prevent="onSubmit" class="submit-form">
            <ValidationErrors
                v-if="validationErrors"
                :validation-errors="validationErrors"
            />
            <div class="validation-success" v-if="messageSuccess !== null">
             <p class="validation-success__text">{{messageSuccess}}</p>
            </div>
            <fieldset class="submit-login__input-wrap">
              <p class="label-text" for="email">Введите свою почту</p>
              <input
                  type="text"
                  class="input-light-text"
                  placeholder="Введите адрес почты"
                  name="email"
                  v-model="email"
              />
            </fieldset>

            <div class="submit-login__button" v-if="messageSuccess === null" >
              <button class="button"> Далее</button>
            </div>
            <div class="submit-login__button" v-else>
              <router-link to="/login">
              <button class="button"> Вернуться на вход </button>
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { actionTypes } from '@/store/modules/auth'
  import ValidationErrors from '@/components/ValidationErrors.vue'
  export default {
    name: "AppFogotPassword",
    components: {   ValidationErrors },
    data() {
      return {
        email: "",

      };
    },
    computed: {
      ...mapState({
        validationErrors: (state) => state.auth.validationErrors,
        isLoading: (state) => state.auth.isLoading,
        messageSuccess: (state) => state.auth.messageSuccess,
      }),
    },
    methods: {
      onSubmit() {
        this.$store
            .dispatch(actionTypes.forgotPassword,
               this.email,
            )
            .then(() => {

            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },
      isShowPass() {
        this.isShowPassword = !this.isShowPassword;
      },
    },
  };
</script>
