<template>
  <div class="mandated__blocks">
    <div
        v-for="(block, index) in blocks"
        :key="index"
        class="mandated__tag"
        @click="handleBlockClick(block.name)"
        :class="{ 'active': activeBlock === block.name }"
    >
      <p class="mandated__tag-text">{{ block.label }}</p>
      <span class="mandated__tag-count">{{ block.count }}</span>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  import { actionTypesMandated } from '@/store/modules/mandated' // Импортируем mutationTypes

  export default {
    name: "AppSelectionBlocks",
    props: {
      activeBlock: String,
      sportsmansCount: Number,
      groupsCount: Number,
      judges: Array,
      duetsCount: Number,
      triosCount: Number
    },
    computed: {
      ...mapState({
        groupCategories: (state) => state.registration.groupCategories,
        trioCategories: (state) => state.registration.trioCategories,
        duoCategories: (state) => state.registration.duoCategories,
      }),
      blocks() {
        return [
          {
            name: 'sportsmens',
            label: 'Инд.выступлений',
            count: this.sportsmansCount
          },
          {
            name: 'duets',
            label: 'Дуэты',
            count: this.duetsCount,
            isVisible: this.duoCategories && this.duoCategories.length > 0
          },
          {
            name: 'trios',
            label: 'Трио',
            count: this.triosCount,
            isVisible: this.trioCategories && this.trioCategories.length > 0
          },
          {
            name: 'clubs',
            label: 'Групп',
            count: this.groupsCount,
            isVisible: this.groupCategories && this.groupCategories.length > 0
          },
          {
            name: 'judges',
            label: 'Судей',
            count: this.judges ? this.judges.length : 0
          }
        ].filter(block => block.isVisible !== false); // Фильтруем блоки по видимости
      }
    },
    methods: {
      handleBlockClick(tab) {
        this.selectCategory(tab); // Очищаем не относящиеся данные
        this.$emit('block-click', tab); // Отправляем событие
      },
      selectCategory(category) {
        this.$store
            .dispatch(actionTypesMandated.resetStateByCategory, category)
            .then(() => {})
            .catch((er) => {
              console.log("Ошибка", er);
            });
      }
    }
  };
</script>
