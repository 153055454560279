<template>
  <div v-if="isVisible" class="modal-registration">
    <div class="modal-content">
      <h2>Спасибо за регистрацию!</h2>
      <p>Вы успешно зарегистрировались.</p>
      <div class="modal-registration__btn">
      <button @click="goToLogin" class="btn background-green">Войти в аккаунт</button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      isVisible: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      goToLogin() {
        this.$emit('close'); // Закрывает модальное окно
        this.$router.push('/login'); // Перенаправление на страницу входа
      }
    }
  };
</script>
