<template>
  <transition name="modal" @click="$emit('close')">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container" :style="dynamicWidthStyle">
          <div class="modal-header">
            <div class="modal__cross" @click="$emit('close')">
              <img src="../../assets/svg/cross.svg" alt="cross" />
            </div>
          </div>

          <div class="modal-body">
            <div class="modal-body__title">
              <p class="modal__title">Заявка</p>
            </div>

            <div class="modal-body__table modal-scroll-max">
              <div class="input-wrap__label">
                <div class="tab-competition">
                  <div
                    class="tab-competition__block"
                    v-if="this.item_app.sportsmans && this.item_app.sportsmans.length"
                    :class="[isActiveBlockInd ? 'tab-active-dark' : 'tab-noactive']"
                    @click="switchTabsClass('individ')"
                  >
                    <p class="tab-competition__text">Инд.выступления</p>
                  </div>
                  <div
                    class="tab-competition__block"
                    v-if="this.item_app.groups && this.item_app.groups.length"
                    :class="[
                      isActiveBlockGroup ? 'tab-active-dark' : 'tab-noactive',
                    ]"
                    @click="switchTabsClass('group')"
                  >
                    <p class="tab-competition__text">Группы</p>
                  </div>
                  <div
                      class="tab-competition__block"
                      v-if="this.item_app.duos && this.item_app.duos.length"
                      :class="[
                      isActiveBlockDuo ? 'tab-active-dark' : 'tab-noactive',
                    ]"
                      @click="switchTabsClass('duos')"
                  >
                    <p class="tab-competition__text">Дуэты</p>
                  </div>
                  <div
                      class="tab-competition__block"
                      v-if="this.item_app.trios && this.item_app.trios.length"
                      :class="[
                      isActiveBlockTrio ? 'tab-active-dark' : 'tab-noactive',
                    ]"
                      @click="switchTabsClass('trios')"
                  >
                    <p class="tab-competition__text">Трио</p>
                  </div>
                  <div
                    class="tab-competition__block"
                    v-if="this.item_app.judges && this.item_app.judges.length"
                    :class="[
                      isActiveBlockJudge ? 'tab-active-dark' : 'tab-noactive',
                    ]"
                    @click="switchTabsClass('judge')"
                  >
                    <p class="tab-competition__text">Судьи</p>
                  </div>
                </div>
              </div>
          <div class="modal-body__table-wrap">
              <table-app-sportsmen
                :allSportsmen="item_app"
                v-if="this.isActiveBlockInd"
              />

              <table-app-group
                :allSportsmen="item_app.duos"
                v-if="this.isActiveBlockDuo"
              />
              <table-app-group
                :allSportsmen="item_app.trios"
                v-if="this.isActiveBlockTrio"
             />
            <table-app-group
                :allSportsmen="item_app.groups"
                v-if="this.isActiveBlockGroup"
            />
              <table-app-judge
                :all-judges="item_app.judges"
                v-if="this.isActiveBlockJudge"
              />
            </div>
            </div>
          </div>
          </div>

        </div>
      </div>
  </transition>
</template>

<script>
import TableAppJudge from "../TableInModal/TableAppJudge.vue";
import TableAppGroup from "../TableInModal/TableAppGroup.vue";

import TableAppSportsmen from "../TableInModal/TableAppSportsmen.vue";

export default {
  name: "AppModalApplication",
  data() {
    return {
      isActiveBlockInd: true,
      isActiveBlockGroup: false,
      isActiveBlockJudge: false,
      isActiveBlockDuo: false,  // Новый флаг для дуэтов
      isActiveBlockTrio: false, // Новый флаг для трио
      windowWidth: window.innerWidth // Инициализируйте с текущей шириной окна
    };
  },
  props: {
    item_app: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    TableAppSportsmen,
    TableAppGroup,
    TableAppJudge,
  },
  computed: {
    dynamicWidthStyle() {
      return {
        width: `${this.windowWidth * 0.9}px` // Вычисляем 80% от ширины окна
      };
    },
  },
  methods: {
    addCoach() {
      this.$emit("addCoach");
    },
    switchTabsClass(tab) {
      // Сбрасываем все флаги активности перед переключением
      this.isActiveBlockInd = false;
      this.isActiveBlockGroup = false;
      this.isActiveBlockJudge = false;
      this.isActiveBlockDuo = false;
      this.isActiveBlockTrio = false;

      // Включаем флаг для активной вкладки
      if (tab === "individ") {
        this.isActiveBlockInd = true;
      } else if (tab === "group") {
        this.isActiveBlockGroup = true;
      } else if (tab === "duos") {
        this.isActiveBlockDuo = true;
      } else if (tab === "trios") {
        this.isActiveBlockTrio = true;
      } else if (tab === "judge") {
        this.isActiveBlockJudge = true;
      }
    },
    handleResize() {
      this.windowWidth = window.innerWidth; // Обновляем ширину при изменении размера окна
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize); // Слушаем изменения размера окна
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize); // Очищаем слушателя при уничтожении компонента
  },
};
</script>

<style></style>
