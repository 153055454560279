<template>
  <div class="table-horizontal">
    <!-- Заголовок потока с номером и временем -->
    <div class="table-horizontal__header">
      <div class="flow-header">
        <h3 class="flow-number">Поток №{{item_flow.flowNumber }} </h3>
        <div class="arrow-container">
          <div class="arrow-button arrow-up" @click="upFlow" v-bind:class="classDisable">
            <div class="arrow-icon">↑</div>
          </div>
          <!-- Button with a down arrow -->
          <div class="arrow-button arrow-down" @click="downFlow">
            <div class="arrow-icon">↑</div>
          </div>
<!--          <div class="arrow-up" @click="upFlow" v-bind:class="classObject">-->
<!--            <img src="../../assets/svg/arrow.svg" alt="Вверх" />-->
<!--          </div>-->
<!--          <div class="arrow-down" @click="downFlow">-->
<!--            <img src="../../assets/svg/arrow.svg" alt="Вниз" />-->
<!--          </div>-->
        </div>

        <span class="flow-time">Время потока: {{ item_flow.timeFrom }} - {{ item_flow.timeTo }}</span>
        <button class="mandated__tag dark" @click="deleteSubmit">Удалить поток</button>
        <button class="mandated__tag active" :disabled="!isChangeFlow" @click="saveChangeAllFlowSubmit">Сохранить поток</button>
      </div>
    </div>
        <div class="table-horizontal__body-break">
         <p class="flow-time"> {{item_flow.title}}</p>
        </div>
    <!-- Draggable контейнер для спортсменов -->
    <draggable
        v-model="item_flow.sportsmen"
        group="sportsmen"
        handle=".drag-handle"
        @end="onDragEnd"
        class="draggable-container"
    >
      <!-- Строка для каждого спортсмена -->
      <div class="table-horizontal__row" v-for="(sport, index) in item_flow.sportsmen" :key="index">
        <div class="table-horizontal__column">
        <!-- Полоса для захвата и перетаскивания -->
        <div class="table-horizontal__cell drag-handle">
          &#9776; <!-- Символ полосок для захвата -->
        </div>

        <!-- Место спортсмена -->
        <div class="table-horizontal__cell table-horizontal__position">{{ sport.position }}</div>

        <!-- ФИО и клуб спортсмена -->
        <div class="table-horizontal__cell table-horizontal__fio">
          <span class="fio">{{ sport.surname }} {{ sport.name }} {{ sport.patronymic }}</span>
        </div>
          <div class="table-horizontal__cell ">
            <span class="club">{{ sport.region }} {{ sport.club }}</span>
          </div>
        </div>
        <!-- Виды спортсмена -->
        <div class="table-horizontal__column">
          <div class="table-horizontal__cell table-horizontal__group" v-for="(type, idx) in sport.performedTypes" :key="idx">
            <div class="average highlighted">
              <span>{{ type }}</span>
            </div>
          </div>
        </div>
      </div>
    </draggable>
    <div class="button" @click="joinFlowInStartListNext()" v-if="this.item_flow.breakId === null">
      Объединить с нижним
    </div>
  </div>
</template>

<script>
  import draggable from "vuedraggable"; // Импорт компонента для перетаскивания
  import { actionTypesStartList } from "@/store/modules/createStartList";

  export default {
    name: "CardPerformance",
    data() {
      return {
        isChangeFlow: false
      }
    } ,

    components: { draggable }, // Регистрация компонента draggable
    props: {
      item_flow: {
        type: Object, // Ожидается объект с массивом спортсменов и временем потока
        required: true,
      },
      idx: {
        type: Number,
        required: true,
      },
      about_start_list: {
        type: Object,
      },
      allList: {
        type: Array,
      }
    },
    computed: {
      classDisable: function () {
        return {
          'disable-arrow': this.item_flow.flowNumber < 2,
        }
      },
      disable() {
        return  this.item_flow.flowNumber < 2
      }
    },
    methods: {
        // Метод для изменения позиции потока или перерыва
        changeFlowPosition(direction) {
          const newPosition = direction === 'up' ? this.item_flow.flowNumber - 1 : this.item_flow.flowNumber + 1;

          // Определяем, какой тип позиции нужно изменить: поток или перерыв
          const actionType = this.item_flow.listType === 'BREAK'
              ? actionTypesStartList.changePositionBreak
              : actionTypesStartList.changePositionFlow;

          // Данные для dispatch в зависимости от типа
          const payload = this.item_flow.listType === 'BREAK'
              ? { breakId: this.item_flow.breakId, newPosition }
              : { flowId: this.item_flow.flowId, newPosition };

          // Вызов dispatch с соответствующим экшеном и данными
          this.$store
              .dispatch(actionType, payload)
              .then(() => {
                this.getStartList()
                console.log(`Позиция ${this.item_flow.listType === 'BREAK' ? 'перерыва' : 'потока'} успешно перемещена ${direction}`);
              })
              .catch((er) => {
                console.error(`Ошибка при изменении позиции ${this.item_flow.listType === 'BREAK' ? 'перерыва' : 'потока'}:`, er);
              });
        },

        // Метод для поднятия потока или перерыва
        upFlow() {
          if(!this.disable) {
            this.changeFlowPosition('up');
          } else console.log('не прошел')
        },

        // Метод для опускания потока или перерыва
        downFlow() {
          this.changeFlowPosition('down');

      },
      joinFlowInStartListNext() {
        const nextFlowNumber = this.item_flow.flowNumber + 1;
        const nextFlow = this.allList.find(flow => flow.flowNumber === nextFlowNumber);

        const joinData = {
          flowFirstId: this.item_flow.flowId,
          flowSecondId: nextFlow ? nextFlow.flowId : null, // Находим flowId следующего потока
          competitionId: this.$route.params.id,
          startDateAndTime: this.about_start_list.startDateAndTime,
        };

        // Проверка, если nextFlow не найден
        if (!nextFlow) {
          console.error('Следующий поток с указанным flowNumber не найден');
        }
        this.$store
            .dispatch(actionTypesStartList.joinFlowInStartList, joinData)
            .then(() => {
              this.getStartList()

            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },
      saveChangeAllFlowSubmit() {
        this.$store
            .dispatch(actionTypesStartList.saveChangeAllFlowSubmit, this.item_flow)
            .then(() => {
              this.getStartList()
              this.isChangeFlow = false
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },
      //
      getStartList() {
        this.$store
            .dispatch(actionTypesStartList.getStartListPerformance, {
              competitionId: this.$route.params.id,
              startDateTime: this.about_start_list.startDateAndTime,
            })
            .then(() => {})
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },
      deleteBreak() {
        this.$store
            .dispatch(actionTypesStartList.deleteBreakFlowProtocol, {
              breakId: this.item_flow.breakId
            })
            .then(() => {
              this.getStartList()
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },
      // Удаление потока
      deleteFlow() {
        this.$store
            .dispatch(actionTypesStartList.deleteFlowProtocol, {
              flowId: this.item_flow.flowId
            })
            .then(() => {
              this.getStartList()
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },

      deleteSubmit() {
        if(this.item_flow.listType === 'FLOW') {
        this.deleteFlow()
        } else {
        this.deleteBreak()
        }
      },
      // Обновление позиций после перетаскивания
      onDragEnd(evt) {
        this.isChangeFlow = true
        console.log("Перетаскивание завершено:", evt);
        this.updatePositions();
      },
      // Обновление позиций спортсменов
      updatePositions() {
        this.item_flow.sportsmen.forEach((sport, index) => {
          sport.position = index + 1; // Обновление позиции в массиве
        });
      },
    },
  };
</script>

<style scoped>

</style>
