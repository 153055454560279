<template>
  <div class="modal-body-add-coach__body">
    <div class="modal-body-add-coach__trash" @click="deleteCoach">
      <img src="../../assets/svg/trash.svg" alt="trash">
    </div>
    <div class="input-wrap__row">
      <div class="input-wrap__label-with-input-row" >
        <div class="input-wrap__label">
          <p class="text-label">Фамилия:</p>
        </div>
        <input
            type="text"
            class="input-light-text"
            placeholder="Заполните фамилию"
            name="surname"
            :disabled="!isEditData && !item_coach.isNew"
            v-model="item_coach.surname"
        />
      </div>
      <div class="input-wrap__label-with-input-row" >
        <div class="input-wrap__label">
          <p class="text-label">Имя:</p>
        </div>
        <input
            type="text"
            class="input-light-text"
            placeholder="Заполните имя"
            name="surname"
            :disabled="!isEditData && !item_coach.isNew"
            v-model="item_coach.coachName"
        />
      </div>
      <div class="input-wrap__label-with-input-row" >
        <div class="input-wrap__label">
          <p class="text-label">Отчество:</p>
        </div>
        <input
            type="text"
            class="input-light-text"
            placeholder="Заполните отчество"
            name="surname"
            :disabled="!isEditData && !item_coach.isNew"
            v-model="item_coach.patronymic"
        />
      </div>
    </div>
    <div class="input-wrap__row">
      <div class="input-wrap__label-with-input-row">
        <div class="input-wrap__label">
          <p class="text-label">Клуб:</p>
        </div>
        <input
            type="text"
            class="input-light-text"
            placeholder="Заполните клуб"
            name="club"
            :disabled="!isEditData && !item_coach.isNew"
            v-model="item_coach.club"
        />
      </div>
      <div v-if="item_coach.isNew">
        <div class="tb__tag-success tag-active" @click="createNewCoach">
          <p class="tb__tag-success-text">Создать нового тренера</p>
        </div>
      </div>
      <div v-else-if="!isEditData">
        <div class="tb__tag-success tag-not-active" @click="editData">
          <p class="tb__tag-success-text">Внести изменения</p>
        </div>
      </div>
      <div v-else>
        <div class="tb__tag-success tag-active" @click="editData">
          <p class="tb__tag-success-text">Сохранить изменения</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AppItemCoachInMandated',
    props: {
      item_coach: {
        type: Object,
        default() {
          return {
            coachName: "",
            surname: "",
            patronymic: '',
            club: '',
            isNew: true // Добавим это для тестирования
          };
        },
      },
      idx: {
        type: Number,
        default() {
          return {};
        },
      }
    },
    data() {
      return {
        isEditData: false, // Если новый тренер, поля активны, если нет — заблокированы.
      };
    },
    watch: {
      'item_coach.isNew': function(newVal) {
        if (!newVal) {
          this.isEditData = false;
        }
      }
    },
    methods: {
      editData() {
        this.isEditData = !this.isEditData;
        if (!this.isEditData) {
          // console.log('Изменения сохранены', this.item_coach);
          // Отправить изменения на сервер или сохранить локально
        }
      },
      deleteCoach() {
        this.$emit('deleteCoach', this.idx);
      },
      createNewCoach() {
        if (this.item_coach.isNew) {
          // Обновляем состояние объекта item_coach
          this.$set(this.item_coach, 'isNew', false);
          delete this.item_coach.isNew; // Удаление свойства isNew
          this.isEditData = false;
        }
      },
    }
  }
</script>

<style scoped>
/* Стили для компонента */
</style>
