<template>
  <transition name="modal" @click="$emit('close')">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <div class="modal__cross" @click="$emit('close')">
              <img src="../../assets/svg/cross.svg" alt="cross" />
            </div>
          </div>
          <form @submit.prevent="safeInfoGroup" id="modalModificationGroup">
            <div class="modal-body">
              <div class="modal-body__title">
                <p class="modal__title">Изменение выступления группы</p>
              </div>
              <div class="modal-scroll-max">
                <div class="input-wrap__column">
                  <div class="input-wrap__label-with-input-column">
                    <div class="input-wrap__label">
                      <p class="text-label">Название группы:</p>
                    </div>
                    <input
                        type="text"
                        class="input-light-text"
                        v-model="item_group.name"
                        :class="{
                        'error-border-input': isSubmitted && $v.putData.name.$error
                      }"
                        placeholder="Заполните название группы"
                        name="name"
                    />
                    <img
                        src="~@/assets/svg/Error.svg"
                        alt="error"
                        v-if="isSubmitted && $v.putData.name.$error"
                        class="wrapValidationInput__error"
                    />
                  </div>
                </div>
                <div class="modal-body__add-coach">
                  <div class="modal-body-add-coach">
                    <div
                        class="modal-body-add-coach__title-with-svg"
                        @click="addCoachEmpty"
                    >
                      <p class="modal-body-add-coach__title">Добавить тренера</p>
                      <div class="modal-body-add-coach__wrap-svg">
                        <img src="../../assets/svg/plus.svg" alt="plus" />
                      </div>
                    </div>
                    <AppItemCoachInMandated
                        v-for="(coach, index) in item_group.coaches"
                        :item_coach="coach"
                        :idx="index"
                        :key="index"
                        @deleteCoach="deleteCoach(index)"
                    />
                  </div>
                </div>
                <div v-if="isSubmitted && $v.selectPeople.$error && !$v.selectPeople.minLength"  class="message-about-empty empty">
                  <p class="message-about-empty__text"> Добавлено менее 5 спортсменов в группу!</p>
                </div>

                <div class="input-wrap">
                  <div class="input-wrap__label">
                    <p class="text-label">Состав группы</p>
                  </div>
                  <div class="group-list-in-modal" v-if="selectPeople.length !== 0">
                    <table class="custom-table">
                      <thead>
                      <tr>
                        <th class="empty_th"></th>
                        <th class="th__name">Ф.И.О.</th>
                        <th class="th__years">Год р.</th>
                        <th class="th__section">Разряд</th>
                        <th class="th__trainer">Тренеры</th>
                      </tr>
                      </thead>
                      <tbody>
                      <item-table-sportsmen
                          v-for="(sportsmen, i) in selectPeople"
                          :key="i"
                          :typeEdit="typeEdit"
                          :item_sportsmen="sportsmen"
                          @deleteSportsmen="deleteSportsmen"
                      />
                      </tbody>
                    </table>
                  </div>
                  <AppAddPeopleSelect
                      v-model="selectPeople"
                      :options="allSportsmens"

                      :class="{ 'error-border-input': isSubmitted && $v.selectPeople.$error }"
                      :windowWidth="isMobile"
                  />
                </div>
                <div class="input-wrap__row">
                  <div class="input-wrap__label-with-input-row">
                    <div class="input-wrap__label">
                      <p class="text-label">Область/регион:</p>
                    </div>
                    <AppRegionSelect @regionSelected="safeRegion" :default="item_group.region" />
                  </div>
                  <div class="input-wrap__label-with-input-row">
                    <div class="input-wrap__label">
                      <p class="text-label">Город:</p>
                    </div>
                    <AppCitySelect
                        :idRegion="region.id"
                        @citySelected="safeCity"
                        :default="item_group.city"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="modal-footer__button">
                <button class="mandated__tag dark">Сохранить</button>
              </div>
              <div class="modal-footer__button">
                <button
                    type="button"
                    class="mandated__tag button-cancel"
                    @click="$emit('close')"
                >
                  Отменить
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import { actionTypes } from "@/store/modules/registration";
  import { actionTypesEntered } from "@/store/modules/enteredData";
  import { mapState } from "vuex";
  import { minLength, required } from 'vuelidate/lib/validators'

  import AppItemCoachInMandated from "@/components/Mandated/ItemCoachInMandated.vue";
  import ItemTableSportsmen from "../Table/ItemTableSportsmen.vue";
  import AppRegionSelect from "@/components/customSelect/RegionSelect.vue";
  import AppCitySelect from "@/components/customSelect/CitySelect.vue";
  import AppAddPeopleSelect from "@/components/customSelect/AddPeopleSelect.vue";
  import { actionTypesMandated } from '@/store/modules/mandated'

  export default {
    name: "AppModalEditGroupPerformance",
    data() {
      return {
        name: "",
        city: this.item_group.city || "",
        region: this.item_group.region || "",
        selectPeople: this.item_group.sportsman || [],
        sportsmenId: [],
        defaultSportsmen: "Добавление спортсмена",
        putData: {},
        isSubmitted: false,
        windowWidth: window.innerWidth, // Инициализируем начальное значение
      };
    },
    props: {
      typeEdit: {
        type: String,
      },
      item_group: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    validations: {
      putData: {
        name: { required },
      },
      sportsmenId: { required },
      selectPeople: {
        required,
        minLength: minLength(5)
      },
    },
    components: {
      AppItemCoachInMandated,
      ItemTableSportsmen,
      AppRegionSelect,
      AppCitySelect,
      AppAddPeopleSelect,
    },
    computed: {
      ...mapState({
        allSportsmens: (state) => state.enteredData.allSportsmens,
      }),
      isMobile() {
        return this.windowWidth <= 1024; // Логика определения мобильного режима
      },
    },
    methods: {
      safePeople(item) {
        this.selectPeople = item.allInformation;
      },
      deleteSportsmen(item) {
        console.log(item, 'Удаляю этого')
        this.selectPeople = this.selectPeople.filter((n) => n.id !== item.id);
      },
      deleteCoach(index) {
        this.item_group.coaches.splice(index, 1);
      },
      editSportsmen(item) {
        this.$emit("editSportsmen", item);
      },
      addCoachEmpty() {
        const MAX_COACHES = 5;
        if (this.item_group.coaches.length < MAX_COACHES) {
          this.item_group.coaches.unshift({ isNew: true });
        } else {
          alert(`Максимальное количество тренеров - ${MAX_COACHES}.`);
        }
      },
      safeInfoGroup() {
        this.sportsmenId = this.selectPeople.map((item) => item.id);
        this.putData = {
          name: this.item_group.name,
          region: this.region.name || this.region,
          city: this.city,
          coaches: this.item_group.coaches,
          sportsmenId: this.sportsmenId,
          groupId: this.item_group.regGroupId,
        };

        this.isSubmitted = true;
        this.$v.$touch();

        if (this.$v.$invalid) {
          return console.log("Данные не заполнены", this.$v.$invalid);
        } else {
          this.$store
              .dispatch(actionTypesMandated.editGroupPerformance, this.putData)
              .then((res) => {
                console.log(res)
                this.$emit("close");
              })
              .catch((er) => {
                console.log("Ошибка", er);
              });
        }
      },
      safeCity(item) {
        this.city = item;
      },
      safeRegion(item) {
        this.region = item;
      },
      findCity() {
        this.city = this.item_group.city;
      },
      findRegion() {
        this.region = this.item_group.region;
      },
    },
    mounted() {
      this.findCity();
      this.findRegion();
      this.$store
          .dispatch(actionTypesEntered.getSportsmens)
          .catch((er) => console.log("Ошибка", er));
      //получение все разрядов
      this.$store
          .dispatch(actionTypes.getRank)
          .catch((er) => console.log("Ошибка", er));
    },

  };
</script>

<style scoped>
/* Добавьте ваши стили сюда */
</style>
