var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"modal"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('div',{staticClass:"modal-mask"},[_c('div',{staticClass:"modal-wrapper"},[_c('div',{staticClass:"modal-container",style:(_vm.dynamicWidthStyle)},[_c('div',{staticClass:"modal-header"},[_c('div',{staticClass:"modal__cross",on:{"click":function($event){return _vm.$emit('close')}}},[_c('img',{attrs:{"src":require("../../assets/svg/cross.svg"),"alt":"cross"}})])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"modal-body__title"},[_c('p',{staticClass:"modal__title"},[_vm._v("Заявка")])]),_c('div',{staticClass:"modal-body__table modal-scroll-max"},[_c('div',{staticClass:"input-wrap__label"},[_c('div',{staticClass:"tab-competition"},[(this.item_app.sportsmans && this.item_app.sportsmans.length)?_c('div',{staticClass:"tab-competition__block",class:[_vm.isActiveBlockInd ? 'tab-active-dark' : 'tab-noactive'],on:{"click":function($event){return _vm.switchTabsClass('individ')}}},[_c('p',{staticClass:"tab-competition__text"},[_vm._v("Инд.выступления")])]):_vm._e(),(this.item_app.groups && this.item_app.groups.length)?_c('div',{staticClass:"tab-competition__block",class:[
                    _vm.isActiveBlockGroup ? 'tab-active-dark' : 'tab-noactive',
                  ],on:{"click":function($event){return _vm.switchTabsClass('group')}}},[_c('p',{staticClass:"tab-competition__text"},[_vm._v("Группы")])]):_vm._e(),(this.item_app.duos && this.item_app.duos.length)?_c('div',{staticClass:"tab-competition__block",class:[
                    _vm.isActiveBlockDuo ? 'tab-active-dark' : 'tab-noactive',
                  ],on:{"click":function($event){return _vm.switchTabsClass('duos')}}},[_c('p',{staticClass:"tab-competition__text"},[_vm._v("Дуэты")])]):_vm._e(),(this.item_app.trios && this.item_app.trios.length)?_c('div',{staticClass:"tab-competition__block",class:[
                    _vm.isActiveBlockTrio ? 'tab-active-dark' : 'tab-noactive',
                  ],on:{"click":function($event){return _vm.switchTabsClass('trios')}}},[_c('p',{staticClass:"tab-competition__text"},[_vm._v("Трио")])]):_vm._e(),(this.item_app.judges && this.item_app.judges.length)?_c('div',{staticClass:"tab-competition__block",class:[
                    _vm.isActiveBlockJudge ? 'tab-active-dark' : 'tab-noactive',
                  ],on:{"click":function($event){return _vm.switchTabsClass('judge')}}},[_c('p',{staticClass:"tab-competition__text"},[_vm._v("Судьи")])]):_vm._e()])]),_c('div',{staticClass:"modal-body__table-wrap"},[(this.isActiveBlockInd)?_c('table-app-sportsmen',{attrs:{"allSportsmen":_vm.item_app}}):_vm._e(),(this.isActiveBlockDuo)?_c('table-app-group',{attrs:{"allSportsmen":_vm.item_app.duos}}):_vm._e(),(this.isActiveBlockTrio)?_c('table-app-group',{attrs:{"allSportsmen":_vm.item_app.trios}}):_vm._e(),(this.isActiveBlockGroup)?_c('table-app-group',{attrs:{"allSportsmen":_vm.item_app.groups}}):_vm._e(),(this.isActiveBlockJudge)?_c('table-app-judge',{attrs:{"all-judges":_vm.item_app.judges}}):_vm._e()],1)])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }