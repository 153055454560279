<template>
  <tbody>
  <tr>
    <td class="edit__btn">
      <img
          class="icon__edit"
          src="../../assets/svg/pain.svg"
          alt="Edit"
          @click="editSportsmen()"
      />
    </td>
    <td class="" style="width: 5%">
      {{ idx + 1 }}
    </td>
    <td class="" style="width: 5%">
      {{item_sportsmen.startPosition}}
    </td>
    <td class=""  style="width: 15%">
      {{ item_sportsmen.surname }} {{ item_sportsmen.name }}
    </td>
    <td class="tb__name" style="width: 10%">
      {{ item_sportsmen.dateOfBirth }}
    </td>
    <td class="tb__name" style="width: 5%">
      {{ item_sportsmen.rank }}
    </td>
    <td class="tb__name" style="width: 10%">
      {{ item_sportsmen.club }}
    </td>

    <td class="tb__name" style="width: 10%">
      {{ item_sportsmen.coachesFio }}
    </td>
    <td class="" style="width: 25%">
      <AppCategorySelect :all-category="listNominationInd" @categorySelected="safeNewCategory"  :default="item_categ.name" :key="item_sportsmen.id"/>
    </td>
    <td class=""  style="width: 20%">
      <div class="tb__tag-success tag-active" @click="changeStatusPerformance()" v-if="item_sportsmen.status === 'PRESENT'">
        <div class="loader-btn" v-if="isLoadingStatus"></div>
        <p class="tb__tag-success-text" v-else>Выступает</p>

      </div>
      <div class="tb__tag-success tag-not-active" @click="changeStatusPerformance()" v-else>
        <div class="loader-btn"  v-if="isLoadingStatus" ></div>
        <p class="tb__tag-success-text" v-else>Не выступает</p>
      </div>
    </td>
  </tr>
  </tbody>
</template>

<script>
  import AppCategorySelect from '@/components/customSelect/CategorySelect.vue'
  import { actionTypesMandated } from '@/store/modules/mandated'
  import { mapState } from 'vuex'

  export default {
    name: 'AppItemSportsmanInMandated',
    components: { AppCategorySelect },
    data() {
      return {
      };
    },
    props: {
      item_sportsmen: {
        type: Object,
        default() {
          return {};
        },
      },
      item_categ: {
        type: Object,
        default() {
          return {};
        },
      },
      idx: {
        type: Number,
        default() {
          return {};
        },
      },
      safeIndividualCategories: {
        type: Object,
        default() {
          return {};
        },
      },
      listNominationInd: {
        type: Array,
        default() {
          return {};
        },
      }
    },
    computed: {
      ...mapState({
        isLoadingStatus: (state) => state.mandated.isLoadingStatus,
      }),
    },
    methods: {
      editSportsmen() {
        this.$emit("editSportsmen", this.item_sportsmen);
      },
      safeNewCategory(category) {
        this.$store
            .dispatch(
                actionTypesMandated.changeCategorySportsman,{sportsmenId: this.item_sportsmen.regSportsmenId, categoryId: category.id }
            )
            .then(() => {
            })
            .catch((er) => {
              console.log(er, "Error");
            });
      },
      changeStatusPerformance() {
        this.$store
            .dispatch(
                actionTypesMandated.changeStatusPerformance,
                this.item_sportsmen.regSportsmenId
            )
            .then((resp) => {
              console.log(resp)
            })
            .catch((er) => {
              console.log(er, "Error");
            });
      },
    }

  }
</script>

<style scoped>
/* Стили для компонента */
</style>
