<template>
<div class="">
  <table class="custom-table">
    <thead>
    <tr>
      <th class="empty_th"></th>
      <th class="" style="width: 95%; text-align: start">ФИО</th>
    </tr>
    </thead>
    <tbody>
      <item-table-coach
        v-for="(coach, i) in this.coaches"
        :key="i"
        :item_coach="coach"
        v-model="checkedCoach"
      />
    </tbody>
  </table>
  <div @click="addCoach" class="add-person-button-div">
    <div class="add-person-div">
      <img
          src="../../assets/svg/plus.svg"
          class="add-person-div__img"
          alt="cross"
      />
    </div>

    <div class="add-person-div__text">Создать нового тренера</div>
  </div>
</div>
</template>

<script>
import { mapState } from "vuex";
import ItemTableCoach from "./ItemTableCoach.vue";
export default {
  components: { ItemTableCoach },
  name: "AppTableCoachInModal",
  data() {
    return {
      checkedCoach: [],
    };
  },
  watch: {
    checkedCoach: function () {
      this.$emit("checkedCoach", this.checkedCoach ? this.checkedCoach : null);
    },
  },
  // props: ["checkID"],
  methods: {
    submitData() {
      this.$emit("checkCoach", this.checkedCoach);
    },
    addCoach() {
      this.$emit("addCoach");
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.registration.isLoading,
      coaches: (state) => state.enteredData.allCoaches,
    }),
  },
};
</script>

<style></style>
