<template>
  <div class="content-card">
    <div class="content-card__subtitle">
      <h2 class="tag tag-open">Создание потоков</h2>
    </div>

    <div class="radio-container">
      <label class="radio-label">
        <input
            type="radio"
            name="orderPerformance"
            value="withViewSpinner"
            v-model="orderPerformance"
            class="radio-input"
        />
        <span class="radio-custom"></span>
        <span class="radio-text">Вертушка</span>
      </label>

      <label class="radio-label">
        <input
            type="radio"
            name="orderPerformance"
            value="noSpinner"
            v-model="orderPerformance"
            class="radio-input"
        />
        <span class="radio-custom"></span>
        <span class="radio-text">Без вертушки</span>
      </label>
    </div>

    <div class="category-select-container">
      <AppValidationErrors v-if="validationErrors && validationErrors.message" :validation-errors="validationErrors.message"/>
      <label for="categorySelectWithSpinner">Категория</label>
      <select id="categorySelectWithSpinner" class="category-select" v-model="selectedCategoryFull" @change="handleCategoryChange" v-if="isGroupNomination !== 'GROUP'">
        <option v-for="category in listNomination" :key="category.individualCategory.id" :value="category.individualCategory">
          {{ category.individualCategory.individualCategory }} - {{ category.individualCategory.rank }} ({{ category.individualCategory.fromAge }} - {{ category.individualCategory.beforeAge }})  - `Количество` - {{category.count}}
        </option>
      </select>
      <select id="categorySelectWithSpinner" class="category-select" v-model="selectedCategoryFull" @change="handleCategoryChange" v-else>
        <option v-for="category in listNomination" :key="category.groupCategory.id" :value="category.groupCategory">
          {{ category.groupCategory.type }}-{{ category.groupCategory.individualCategory }} - {{ category.groupCategory.rank }} ({{ category.groupCategory.fromAge }} - {{ category.groupCategory.beforeAge }}) - `Количество` - {{category.count}}
        </option>
      </select>
    </div>

    <div class="form-group__row" v-if="selectedCategory && selectedCategory.performedTypes.length">
      <!-- Селекторы для двух видов -->
      <div  class="form-group__row-item">
        <label for="viewSelect1">Выберите первый вид:</label>
        <select id="viewSelect1" class="input" v-model="selectedView1">
          <option v-for="type in selectedCategory.performedTypes" :key="type.id" :value="type.id">{{ type.name }}</option>
        </select>
      </div>

      <div class="form-group__row-item">
        <label for="viewSelect2">Выберите второй вид:</label>
        <select id="viewSelect2" class="input" v-model="selectedView2">
          <option v-for="type in selectedCategory.performedTypes" :key="type.id" :value="type.id">{{ type.name }}</option>
        </select>
      </div>
    </div>
    <div class="form-group__row">
      <!-- Поле ввода времени на 1 выступление -->
      <div class="form-group__row-item">
        <TimeHint :timeInputs="timeHints" />
        <label for="timePerPerformanceWithSpinner">Время на 1 выступление (секунды)</label>
        <input type="number" id="timePerPerformanceWithSpinner" class="input" v-model="timePerPerformance" min="0" />
      </div>

      <!-- Поле для ввода количества спортсменов в потоке -->
      <div class="form-group__row-item">
        <label for="numberOfAthletesInStreamWithSpinner">Кол-во выст. в потоке</label>
        <input type="number" id="numberOfAthletesInStreamWithSpinner" class="input" v-model="countPerformance" min="1" max="30" />
      </div>
      <div class="form-group__row-item" v-if="countFreePerformance">
        <label for="" class="">Кол-во выст. оставшихся человек в категории</label>
        <p class="tag tag-open">{{countFreePerformance}}</p>
      </div>
    </div>
    <div class="form-group-btn">
      <!-- Кнопка создания потока -->
      <button class="mandated__tag active" @click="createStreamWithSpinner">Создать новый поток</button>
    </div>
  </div>
</template>
<script >

  import TimeHint from '@/components/TimeHint.vue'
  import { actionTypesStartList } from '@/store/modules/createStartList'
  import { mapState } from 'vuex'
  import AppValidationErrors from '@/components/ValidationErrors.vue'

  export default {
    components: { AppValidationErrors, TimeHint },
    name:'AppCreateFlowWithSpinner',
    data() {
      return {
        selectedCategoryFull: null,
        timePerPerformance: '',
        selectedView1: '',
        selectedView2: '',
        countPerformance: '',
        // orderPerformance: '',
        orderPerformance: "withViewSpinner", // начальное значение
        timeHints: ["1", "1.30", "1.45", "2", "3"], // Изначальные значения подсказок
      };
    },
    computed: {
        ...mapState({
          countFreePerformance: (state) => state.createStartList.countFreePerformance,
          validationErrors: (state) => state.createStartList.validationErrors
        }),
      //
      selectedCategory() {
        // Проверяем, что selectedCategoryFull и id определены
        if (this.selectedCategoryFull && this.selectedCategoryFull.id) {
          // Если isGroupNomination true — ищем по groupCategory.id
          if (this.isGroupNomination === 'GROUP') {
            return this.listNomination.find(
                (category) => category.groupCategory && category.groupCategory.id === this.selectedCategoryFull.id
            );
          }
          // Если isGroupNomination false — ищем по individualCategory.id
          else {
            return this.listNomination.find(
                (category) => category.individualCategory && category.individualCategory.id === this.selectedCategoryFull.id
            );
          }
        }
        // Если условие не выполняется, возвращаем undefined
        return undefined;
      },
    },
    watch: {
      orderPerformance(newValue) {
        console.log("Выбрано значение:", newValue); // Проверка выбранного значения
      },
    },
    props: {
      listNomination: {
        type: Array,
      },
      about_start_list: {
        type: Object,
      },
      isGroupNomination: {
        type: String,
      }
    },
    methods: {
      createStreamWithSpinner() {
        // this.orderPerformance = 'withViewSpinner'

        var createFlow = {
          sportsmenCount: this.countPerformance,
          competitionId:   this.$route.params.id,
          categoryId: this.selectedCategoryFull.id,
          performanceTime: this.timePerPerformance,
          performanceTypes: [this.selectedView1,this.selectedView2],
          protocolLocationType:  this.orderPerformance,
          startDateAndTime:  this.about_start_list.startDateAndTime,
          roundingTime: this.about_start_list.roundingTime,
          typesPrograms: this.isGroupNomination,
        }
        console.log(createFlow)
        this.$store
            .dispatch(actionTypesStartList.addFlowInProtocol, createFlow)
            .then(() => {
              createFlow = {}
              this.$store
                  .dispatch(actionTypesStartList.getStartListPerformance, {
                    competitionId: this.$route.params.id,
                    startDateTime: this.about_start_list.startDateAndTime,
                  })
                  .then(() => {})
                  .catch((er) => {
                    console.log("Ошибка", er);
                  });
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
        console.log('Создание Перерыва', this.selectedView1,this.selectedView2);
      },
      handleCategoryChange() {
        this.selectedView1 = '';
        this.selectedView2 = '';
      },
    },

  }
</script>
<style scoped>
.radio-container {
  display: flex;
  gap: 10px;
  margin: 0px 0px 20px 0px;
}

.radio-label {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  user-select: none;
  font-size: 16px;
  color: #333;
}

.radio-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-custom {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #f5f5f5;
  border: 2px solid #ccc;
  border-radius: 50%;
  margin-right: 10px;
  transition: all 0.2s ease;
}

.radio-input:checked ~ .radio-custom {
  background-color: #4285f4;
  border-color: #4285f4;
}

.radio-input:checked ~ .radio-custom:after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
}

.radio-text {
  font-size: 14px;
  color: #333;
}
</style>
