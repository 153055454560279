<template>
  <div class="accordion-container">
    <div class="accordion" :class="{ 'is-open': isOpen }" ref="excludedElement">
      <div
        class="accordion-header-placeholder "
        @click="toggleAccordion()"
        v-if="selected === this.default"
      >
        {{ selected }}
      </div>
      <div class="accordion-header" @click="toggleAccordion()" v-else>
        {{ selected.name }}
        <p class="selected-text">Заявленная категория</p>
      </div>
      <ul class="options-list"  :class="{ 'is-open': isOpen }">
        <li
            class="option-item"
            v-for="(option, i) of options"
            :key="i"
            @click="safeSelect(option), toggleAccordion()"
        >
          {{ option.name }}

        </li>

      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
      selected: this.default
        ? this.default
        : this.options.length > 0
        ? this.options[0]
        : null,
    };
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  watch: {  isOpen(newValue) {
    if (newValue) {
      document.addEventListener('click', this.handleClickOutside);
    } else {
      document.removeEventListener('click', this.handleClickOutside);
    }
  }},

  methods: {
    safeSelect(option) {
      this.selected = option;
      this.$emit("input", option);
    },
    updateData() {
      this.selected = this.default;
    },
    toggleAccordion() {
      this.isOpen = !this.isOpen;

    },
    handleClickOutside(event) {

      // Проверяем, был ли клик вне исключенного элемента и его детей
      if (this.isOpen && !this.$refs.excludedElement.contains(event.target)) {
        this.isOpen = false;
        console.log(event, 'event fa;se')
      }
    },

  },
  // При уничтожении компонента убедитесь, что вы удалили обработчик событий,
  // чтобы избежать утечек памяти или других потенциальных проблем.
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<style></style>
