<template>
  <div class="main-content">
    <div class="loader-overlay" v-if="isLoadingAllApplication">
    <div class="loader" >
      <LottieAnimation
        ref="anim"
        :animationData="require('@/assets/lottie/loader.json')"
        :autoPlay="true"
        :loop="true"
        :speed="1"
      />
    </div>
    </div>
    <Header message="Заявки" />
    <AppMiniRouting/>
    <div class="applications__container" v-if="!isLoadingAllApplication">
      <div class="tab-competition">
        <div
          class="tab-competition__block"
          :class="[isActiveBlockActiveApp ? 'tab-active' : 'tab-noactive']"
          @click="switchTabsClass('active')"
        >
          <p class="tab-competition__text">Активные</p>
        </div>
        <div
            class="tab-competition__block"
            :class="[isActiveBlockDraftApp ? 'tab-active' : 'tab-noactive']"
            @click="switchTabsClass('draft')"
        >
          <p class="tab-competition__text">Черновики</p>
        </div>
        <div
          class="tab-competition__block"
          :class="[isActiveBlockFinishApp ? 'tab-active' : 'tab-noactive']"
          @click="switchTabsClass('finish')"
        >
          <p class="tab-competition__text">Завершенные</p>
        </div>
      </div>

      <item-application
        v-for="item in this.applications"
        @openModalApp="openModalApp"
        :key="item.id"
        :item_app="item"
      />
      <div
          class="new-competition__wrap"
          v-if="
        !isLoadingAllApplication &&
        (this.applications === null || !this.applications.length)
      "
      >
        <p class="new-competition__title" v-if="isActiveBlockActiveApp">
          На данный момент отправленных заявок нет!
        </p>
        <div class="new-competition__title" v-if="isActiveBlockDraftApp">
          На данный момент вас нет ни одной черновой заявки!
        </div>
        <div class="new-competition__title" v-if="isActiveBlockFinishApp">
          На данный момент вас нет ни одной завершенной заявки!
        </div>
        <img
            src="../assets/img/empty_low.png"
            alt="application"
            class="new-competition__img"
        />
      </div>
    </div>

    <modal-application
      v-if="isOpenModalApplication"
      :item_app="itemOpenApplication"
      @close="closeModalApp"
    />
  </div>
</template>

<script>
import { actionTypes } from "@/store/modules/registration";
import Header from "../components/Header.vue";
import { mapState } from "vuex";
import LottieAnimation from "lottie-web-vue";
import ItemApplication from "../components/Applications/ItemApplication.vue";
import ModalApplication from "../components/Modal/ModalApplication.vue";
import AppMiniRouting from '@/components/MiniRouting.vue'
export default {
  components: { AppMiniRouting, Header, LottieAnimation, ItemApplication, ModalApplication },
  name: "AppApplications",
  data() {
    return {
      isActiveBlockActiveApp: true,
      isActiveBlockDraftApp: false,
      isActiveBlockFinishApp: false,
      isOpenModalApplication: false,
      itemOpenApplication: null,
      allFinishApplication: [],
      allActiveApplication: [],
      allDraftApplication: [],
    };
  },
  methods: {
    switchTabsClass(tab) {
      if (tab === "active") {
        this.isActiveBlockActiveApp = true;
        this.isActiveBlockFinishApp = false;
        this.isActiveBlockDraftApp = false
      } else if (tab === "draft") {
        this.isActiveBlockDraftApp = true;
        this.isActiveBlockActiveApp = false;
        this.isActiveBlockFinishApp = false;
      } else if (tab === "finish") {
        this.isActiveBlockDraftApp = false;
        this.isActiveBlockActiveApp = false;
        this.isActiveBlockFinishApp = true;
      }
    },
    openModalApp(item) {
      this.isOpenModalApplication = true;
      this.itemOpenApplication = item;
      document.body.classList.add('no-scroll');
    },
    closeModalApp() {
      this.isOpenModalApplication = false
      document.body.classList.remove('no-scroll');
    }
  },
  computed: {
    ...mapState({
      allApplications: (state) => state.registration.allApplications,
      isLoadingAllApplication: (state) =>
        state.registration.isLoadingAllApplication,
    }),
    applications() {
      if (this.isActiveBlockActiveApp) {
        return this.allActiveApplication;
      } else if (this.isActiveBlockDraftApp) {
        return this.allDraftApplication;
      } else {
        return this.allFinishApplication;
      }
    },
  },
  mounted() {
    //   Получение всех заявок
    this.$store
      .dispatch(actionTypes.allApplications)
      .then(() => {
        this.allApplications.map((item) => {
          if (item.status ==="ACTIVE") {
            this.allActiveApplication.push(item);
          } else if (item.status ==="END") {
            this.allFinishApplication.push(item);
          } else {
            this.allDraftApplication.push(item);
          }
        });
      })
      .catch((er) => {
        console.log("Ошибка", er);
      });
  },
};
</script>

<style></style>
