import registrationApi from "@/api/registration.js";



const state = {
  isLoading: false, //ожидание запроса
  isLoadingInfo: false,
  isLoadingRegJuj: false,
  isLoadingCreateApp: false, //ожидание создания заявки
  isLoadingAllApplication: false, //ожидание запроса получшения всех заявок
  validationErrorsAddGroup: null,
  allApplications: null, //все заявки
  allCompetition: null,
  allCityAndRegion: null,
  isLoadingAllCityAndRegion:false,
  validationErrorsCityAndRegion: null,
  selectRank: null,
  validationErrors: null,
  validationErrorsAddSportsmen: null,
  validationErrorsSubmitApplication: null,
  validationErrorsAddJuj:null,
  validationErrorsDuo: null,
  validationErrorsTrio: null,
  individualCategories: null,
  groupCategories: null,
  trioCategories: null,
  duoCategories: null,
  individualSportsmens: null,
  aboutRegistrApp: null,
  allCity: null,
  // //черновики заявок
  // draftIndividApp: [],
  // draftGroupApp: [],
};

export const mutationTypes = {
  // getRank
  getRankStart: "[registration] getRankStart",
  getRankSuccess: "[registration] getRankSuccess",
  getRankFailure: "[registration] getRankFailure",

  //getIndividualCategories
  getIndividualCategoriesStart: "[registration] getIndividualCategoriesStart",
  getIndividualCategoriesSuccess:
    "[registration] getIndividualCategoriesSuccess",
  getIndividualCategoriesFailure:
    "[registration] getIndividualCategoriesFailure",
  // getIndividualSportsmen
  getIndividualSportsmensStart: "[registration] getIndividualSportsmenSstart",
  getIndividualSportsmensSuccess:
    "[registration] getIndividualSportsmensSuccess",
  getIndividualSportsmensFailure:
    "[registration] getIndividualSportsmensFailure",
  // getInfoAboutRegistration
  getInfoAboutRegistrationAppStart:
    "[registration] getInfoAboutRegistrationAppSstart",
  getInfoAboutRegistrationAppSuccess:
    "[registration] getInfoAboutRegistrationAppSuccess",
  getInfoAboutRegistrationAppFailure:
    "[registration] getInfoAboutRegistrationAppFailure",
  // addSportsmenApp
  addSportsmenAppStart: "[registration] addSportsmenAppStart",
  addSportsmenAppSuccess: "[registration] addSportsmenAppSuccess",
  addSportsmenAppFailure: "[registration] addSportsmenAppFailure",
  // deleteSportsmenApp
  deleteSportsmenAppStart: "[registration] deleteSportsmenAppStart",
  deleteSportsmenAppSuccess: "[registration] deleteSportsmenAppSuccess",
  deleteSportsmenAppFailure: "[registration] deleteSportsmenAppFailure",
  // getGroupCategories
  getGroupCategoriesStart: "[registration] getGroupCategoriesStart",
  getGroupCategoriesSuccess: "[registration] getGroupCategoriesSuccess",
  getGroupCategoriesFailure: "[registration] getGroupCategoriesFailure",
  // addGroupApp
  addGroupAppStart: "[registration] addGroupAppStart",
  addGroupAppSuccess: "[registration] addGroupAppSuccess",
  addGroupAppFailure: "[registration] addGroupAppFailure",
  // addTrioApp
  addTrioAppStart: "[registration] addTrioAppStart",
  addTrioAppSuccess: "[registration] addTrioAppSuccess",
  addTrioAppFailure: "[registration] addTrioAppFailure",
  // addDuoApp
  addDuoAppStart: "[registration] addDuoAppStart",
  addDuoAppSuccess: "[registration] addDuoAppSuccess",
  addDuoAppFailure: "[registration] addDuoAppFailure",
  // deleteGroupApp
  deleteGroupAppStart: "[registration] deleteGroupAppStart",
  deleteGroupAppSuccess: "[registration] deleteGroupAppSuccess",
  deleteGroupAppFailure: "[registration] deleteGroupAppFailure",
  // deleteDuoApp
  deleteDuoAppStart: "[registration] deleteDuoAppStart",
  deleteDuoAppSuccess: "[registration] deleteDuoAppSuccess",
  deleteDuoAppFailure: "[registration] deleteDuoAppFailure",
  // deleteTrioApp
  deleteTrioAppStart: "[registration] deleteTrioAppStart",
  deleteTrioAppSuccess: "[registration] deleteTrioAppSuccess",
  deleteTrioAppFailure: "[registration] deleteTrioAppFailure",
  // addJujpApp
  addJujAppStart: "[registration] addJujAppStart",
  addJujAppSuccess: "[registration] addJujAppSuccess",
  addJujAppFailure: "[registration] addJujAppFailure",
  // deleteJujApp
  deleteJujAppStart: "[registration] deleteJujAppStart",
  deleteJujAppSuccess: "[registration] deleteJujAppSuccess",
  deleteJujAppFailure: "[registration] deleteJujAppFailure",
  // submitApplication
  submitApplicationStart: "[registration] submitApplicationStart",
  submitApplicationSuccess: "[registration] submitApplicationSuccess",
  submitApplicationFailure: "[registration]submitApplicationFailure",
  // allApplications
  allApplicationsStart: "[registration] allApplicationsStart",
  allApplicationsSuccess: "[registration] allApplicationsSuccess",
  allApplicationsFailure: "[registration] allApplicationsFailure",
  //getAllRegion
  getAllRegionStart: "[registration]getAllRegionStart",
  getAllRegionSuccess: "[registration] getAllRegionSuccess",
  getAllRegionFailure: "[registration] getAllRegionFailure",
  // getAllCity
  getAllCityStart: "[registration] getAllCityStart",
  getAllCitySuccess: "[registration] getAllCitySuccess",
  getAllCityFailure: "[registration] getAllCityFailure",

  // registrSportsmenWithCateg
  registrSportsmenWithCategStart: "[registration] registrSportsmenWithCategStart",
  registrSportsmenWithCategSuccess: "[registration] registrSportsmenWithCategSuccess",
  registrSportsmenWithCategFailure: "[registration] registrSportsmenWithCategFailure",

  // registrGroupWithCateg

  registrGroupWithCategStart: "[registration] registrGroupWithCategStart",
  registrGroupWithCategSuccess: "[registration] registrGroupWithCategSuccess",
  registrGroupWithCategFailure: "[registration] registrGroupWithCategFailure",
  // registrTrioWithCateg
  registrTrioWithCategStart: "[registration] registrTrioWithCategStart",
  registrTrioWithCategSuccess: "[registration] registrTrioWithCategSuccess",
  registrTrioWithCategFailure: "[registration] registrTrioWithCategFailure",
  // registrDuoWithCateg
  registrDuoWithCategStart: "[registration] registrDuoWithCategStart",
  registrDuoWithCategSuccess: "[registration] registrDuoWithCategSuccess",
  registrDuoWithCategFailure: "[registration] registrDuoWithCategFailure",

  registrJudgeToApplicationStart: "[registration] registrJudgeToApplicationStart",
  registrJudgeToApplicationSuccess: "[registration] registrJudgeToApplicationSuccess",
  registrJudgeToApplicationFailure: "[registration] registrJudgeToApplicationFailure",

};
export const actionTypes = {
  getIndividualCategories: "[registration] getIndividualCategories",
  getRank: "[registratin] getRank",
  getIndividualSportsmens: "[registration] getIndividualSportsmens",
  getInfoAboutRegistrationApp: "[registration] getInfoAboutRegistrationApp",
  addSportsmenApp: "[registration] addSportsmenApp",
  deleteSportsmenApp: "[registration] deleteSportsmenApp",
  getGroupCategories: "[registration] getGroupCategories",
  addGroupApp: "[registration] addGroupApp",
  addTrioApp: "[registration] addTrioApp",
  addDuoApp: "[registration] addDuoApp",
  deleteGroupApp: "[registration] deleteGroupApp",
  deleteDuoApp: "[registration] deleteDuoApp",
  deleteTrioApp: "[registration] deleteTrioApp",
  addJujApp: "[registration] addJujApp",
  deleteJujApp: "[registration] deleteJujApp",
  submitApplication: "[registration] submitApplication",
  allApplications: "[registration] allApplications",
  getAllRegion: "[registration] getAllRegion",
  getAllCity: "[registration] getAllCity",
  registrSportsmenWithCateg: "[registration] registrSportsmenWithCateg",
  registrGroupWithCateg: "[registration] registrGroupWithCateg",
  registrDuoWithCateg: "[registration] registrDuoWithCateg",
  registrTrioWithCateg: "[registration] registrTrioWithCateg",
  registrJudgeToApplication: "[registration] registrJudgeToApplication",
};

const mutations = {
  // getIndividualCategories
  [mutationTypes.getIndividualCategoriesStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.getIndividualCategoriesSuccess](state, info) {
    state.isLoading = false;
    state.individualCategories = info.result;
  },
  [mutationTypes.getIndividualCategoriesFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // getIndividualSportsmen
  [mutationTypes.getIndividualSportsmensStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.getIndividualSportsmensSuccess](state, info) {
    state.isLoading = false;
    state.individualSportsmens = info.result;
  },
  [mutationTypes.getIndividualSportsmensFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // getRank
  [mutationTypes.getRankStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.getRankSuccess](state, info) {
    state.isLoading = false;
    state.selectRank = info.result;
  },
  [mutationTypes.getRankFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // getInfoAboutRegistration
  [mutationTypes.getInfoAboutRegistrationAppStart](state) {
    state.isLoadingInfo = true;
    state.validationErrors = null;
  },
  [mutationTypes.getInfoAboutRegistrationAppSuccess](state, info) {
    state.isLoadingInfo = false;
    state.aboutRegistrApp = info.result;
  },
  [mutationTypes.getInfoAboutRegistrationAppFailure](state, payload) {
    state.isLoadingInfo = false;
    state.validationErrors = payload;
  },
  // addSportsmenApp
  [mutationTypes.addSportsmenAppStart](state) {
    state.isLoading = true;
    state.validationErrorsAddSportsmen = null;
  },
  [mutationTypes.addSportsmenAppSuccess](state, info) {
    state.isLoading = false;
    if (state.aboutRegistrApp) {
      // Обновляем только sportsmen, сохраняя остальные поля неизменными
      state.aboutRegistrApp = {
        ...state.aboutRegistrApp,
        sportsmans: info.result
      };
    } else {
      // Если aboutRegistrApp еще не существует, создаём его
      state.aboutRegistrApp = {
        sportsmans: info.result
      };
    }
  },
  [mutationTypes.addSportsmenAppFailure](state, payload) {
    state.isLoading = false;
    state.validationErrorsAddSportsmen = payload;
  },
  // Регистрация группы в отпределенную категорию
  [mutationTypes.registrSportsmenWithCategStart](state) {
    state.isLoading = true;
    state.validationErrorsSportsmen = null;
  },
  [mutationTypes.registrSportsmenWithCategSuccess](state, item) {
    state.isLoading = false;
      // Обновляем только sportsmen, сохраняя остальные поля неизменными
      state.aboutRegistrApp = {
        ...state.aboutRegistrApp,
        sportsmans: item.result // Заменяем только объект sportsmen
      };
  },
  [mutationTypes.registrSportsmenWithCategFailure](state, payload) {
    state.isLoading = false;
    state.validationErrorsSportsmen = payload;
  },

  [mutationTypes.registrGroupWithCategStart](state) {
    state.isLoading = true;
    state.validationErrorsSportsmen = null;
  },
  [mutationTypes.registrGroupWithCategSuccess](state, item) {
    state.isLoading = false;
    // Обновляем только sportsmen, сохраняя остальные поля неизменными
    state.aboutRegistrApp = {
      ...state.aboutRegistrApp,
      groups: item.result // Заменяем только объект groups
    };
  },
  [mutationTypes.registrGroupWithCategFailure](state, payload) {
    state.isLoading = false;
    state.validationErrorsSportsmen = payload;
  },
  // registrTrioWithCateg
  [mutationTypes.registrTrioWithCategStart](state) {
    state.isLoading = true;
    state.validationErrorsTrio = null;
  },
  [mutationTypes.registrTrioWithCategSuccess](state, item) {
    state.isLoading = false;
    // Обновляем только sportsmen, сохраняя остальные поля неизменными
    state.aboutRegistrApp = {
      ...state.aboutRegistrApp,
      trios: item.result // Заменяем только объект groups
    };
  },
  [mutationTypes.registrTrioWithCategFailure](state, payload) {
    state.isLoading = false;
    state.validationErrorsTrio = payload;
  },
  // registrDuoWithCateg
  [mutationTypes.registrDuoWithCategStart](state) {
    state.isLoading = true;
    state.validationErrorsDuo = null;
  },
  [mutationTypes.registrDuoWithCategSuccess](state, item) {
    state.isLoading = false;
    // Обновляем только sportsmen, сохраняя остальные поля неизменными
    state.aboutRegistrApp = {
      ...state.aboutRegistrApp,
      duos: item.result // Заменяем только объект duos
    };
  },
  [mutationTypes.registrDuoWithCategFailure](state, payload) {
    state.isLoading = false;
    state.validationErrorsDuo = payload;
  },
  // registrJudgeToApplication
  [mutationTypes.registrJudgeToApplicationStart](state) {
    state.isLoading = true;
    state.validationErrorsSportsmen = null;
  },
  [mutationTypes.registrJudgeToApplicationSuccess](state, item) {
    state.isLoading = false;
    // Обновляем только sportsmen, сохраняя остальные поля неизменными
    state.aboutRegistrApp = {
      ...state.aboutRegistrApp,
      judges: item.result // Заменяем только объект groups
    };
  },
  [mutationTypes.registrJudgeToApplicationFailure](state, payload) {
    state.isLoading = false;
    state.validationErrorsSportsmen = payload;
  },
  // deleteSportsmenApp
  [mutationTypes.deleteSportsmenAppStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.deleteSportsmenAppSuccess](state, info) {
    state.isLoading = false;
    // Обновляем только sportsmen, сохраняя остальные поля неизменными
    state.aboutRegistrApp = {
      ...state.aboutRegistrApp,
      sportsmans: info.result // Заменяем только объект sportsmen
    };
  },
  [mutationTypes.deleteSportsmenAppFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // getGroupCategories
  [mutationTypes.getGroupCategoriesStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.getGroupCategoriesSuccess](state, payload) {
    state.isLoading = false;

    // Фильтрация элементов по типам
    const groupCategories = payload.result.filter(item => item.type === 'GROUP');
    const trioCategories = payload.result.filter(item => item.type === 'TRIO');
    const duoCategories = payload.result.filter(item => item.type === 'DUO');

    // Обновляем state
    state.groupCategories = groupCategories;
    state.trioCategories = trioCategories;
    state.duoCategories = duoCategories;

  },
  [mutationTypes.getGroupCategoriesFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // addGroupApp
  [mutationTypes.addGroupAppStart](state) {
    state.isLoading = true;
    state.validationErrorsAddGroup = null;
  },
  [mutationTypes.addGroupAppSuccess](state, info) {
    state.isLoading = false;
    state.aboutRegistrApp = {
      ...state.aboutRegistrApp,
      groups: info.result // Заменяем только объект groups
    };
  },
  [mutationTypes.addGroupAppFailure](state, payload) {
    state.isLoading = false;
    state.validationErrorsAddGroup = payload;
  },
  // addTrioApp
  [mutationTypes.addTrioAppStart](state) {
    state.isLoading = true;
    state.validationErrorsTrio = null;
  },
  [mutationTypes.addTrioAppSuccess](state, info) {
    state.isLoading = false;
    state.aboutRegistrApp = {
      ...state.aboutRegistrApp,
      trios: info.result // Заменяем только объект trios
    };
  },
  [mutationTypes.addTrioAppFailure](state, payload) {
    state.isLoading = false;
    state.validationErrorsTrio = payload;
  },
  // addDuoApp
  [mutationTypes.addDuoAppStart](state) {
    state.isLoading = true;
    state.validationErrorsDuo = null;
  },
  [mutationTypes.addDuoAppSuccess](state, info) {
    state.isLoading = false;
    state.aboutRegistrApp = {
      ...state.aboutRegistrApp,
      duos: info.result // Заменяем только объект groups
    };
  },
  [mutationTypes.addDuoAppFailure](state, payload) {
    state.isLoading = false;
    state.validationErrorsDuo = payload;
  },
  // deleteGroupApp
  [mutationTypes.deleteGroupAppStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.deleteGroupAppSuccess](state, info) {
    state.isLoading = false;
    state.aboutRegistrApp = {
      ...state.aboutRegistrApp,
      groups: info.result // Заменяем только объект groups
    };
  },
  [mutationTypes.deleteGroupAppFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // deleteDuoApp
  [mutationTypes.deleteDuoAppStart](state) {
    state.isLoading = true;
    state.validationErrorsDuo = null;
  },
  [mutationTypes.deleteDuoAppSuccess](state, info) {
    state.isLoading = false;
    state.aboutRegistrApp = {
      ...state.aboutRegistrApp,
      duos: info.result // Заменяем только объект groups
    };
  },
  [mutationTypes.deleteDuoAppFailure](state, payload) {
    state.isLoading = false;
    state.validationErrorsDuo = payload;
  },
  // deleteTrioApp
  [mutationTypes.deleteTrioAppStart](state) {
    state.isLoading = true;
    state.validationErrorsTrio = null;
  },
  [mutationTypes.deleteTrioAppSuccess](state, info) {
    state.isLoading = false;
    state.aboutRegistrApp = {
      ...state.aboutRegistrApp,
      trios: info.result // Заменяем только объект groups
    };
  },
  [mutationTypes.deleteTrioAppFailure](state, payload) {
    state.isLoading = false;
    state.validationErrorsTrio = payload;
  },
  // addJujApp
  [mutationTypes.addJujAppStart](state) {
    state.isLoadingRegJuj = true;
    state.validationErrorsAddJuj = null;
  },
  [mutationTypes.addJujAppSuccess](state, info) {
    state.isLoadingRegJuj = false;
    state.aboutRegistrApp = {
      ...state.aboutRegistrApp,
      judges: info.result // Заменяем только объект groups
    };
    // state.aboutRegistrApp = info.result;
  },
  [mutationTypes.addJujAppFailure](state, payload) {
    state.isLoadingRegJuj = false;
    state.validationErrorsAddJuj = payload;
  },
  // deleteJujApp
  [mutationTypes.deleteJujAppStart](state) {
    state.isLoadingRegJuj = true;
    state.validationErrors = null;
  },
  [mutationTypes.deleteJujAppSuccess](state, info) {
    state.isLoadingRegJuj = false;
    state.aboutRegistrApp = {
      ...state.aboutRegistrApp,
      judges: info.result // Заменяем только объект groups
    };
  },
  [mutationTypes.deleteJujAppFailure](state, payload) {
    state.isLoadingRegJuj = false;
    state.validationErrors = payload;
  },
  // submitApplication
  [mutationTypes.submitApplicationStart](state) {
    state.isLoadingCreateApp = true;
    state.validationErrorsSubmitApplication = null;
  },
  [mutationTypes.submitApplicationSuccess](state) {
    state.isLoadingCreateApp = false;
  },
  [mutationTypes.submitApplicationFailure](state, payload) {
    state.isLoadingCreateApp = false;
    state.validationErrorsSubmitApplication = payload;
  },
  // allApplications
  [mutationTypes.allApplicationsStart](state) {
    state.isLoadingAllApplication = true;
    state.validationErrors = null;
  },
  [mutationTypes.allApplicationsSuccess](state, info) {
    state.isLoadingAllApplication = false;
    state.allApplications = info.result;
  },
  [mutationTypes.allApplicationsFailure](state, payload) {
    state.isLoadingAllApplication = false;
    state.validationErrors = payload;
  },
  // getAllRegion
  [mutationTypes.getAllRegionStart](state) {
    state.isLoadingAllCityAndRegion = true;
    state.validationErrorsCityAndRegion = null;
  },
  [mutationTypes.getAllRegionSuccess](state, info) {
    state.isLoadingAllCityAndRegion = false;
    state.allCityAndRegion = info.result;
  },
  [mutationTypes.getAllRegionFailure](state, payload) {
    state.isLoadingAllCityAndRegion = false;
    state.validationErrorsCityAndRegion = payload;
  },
  // getAllCity
  [mutationTypes.getAllCityStart](state) {
    state.isLoadingAllCityAndRegion = true;
    state.validationErrorsCityAndRegion = null;
  },
  [mutationTypes.getAllCitySuccess](state, info) {
    state.isLoadingAllCityAndRegion = false;
    state.allCity = info.result;
  },
  [mutationTypes.getAllCityFailure](state, payload) {
    state.isLoadingAllCityAndRegion = false;
    state.validationErrorsCityAndRegion = payload;
  },
};
const actions = {
  // getRank
  [actionTypes.getRank](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getRankStart);
      registrationApi
        .getRank()
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.getRankSuccess, response.data);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.getRankFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.getRankFailure, err);
          console.log("ошибка", err);
        });
    });
  },

  // individualCategories
  [actionTypes.getIndividualCategories](context, id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getIndividualCategoriesStart);
      registrationApi
        .getIndividualCategories(id)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.getIndividualCategoriesSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.getIndividualCategoriesFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.getIndividualCategoriesFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  // getIndividualSportsmens
  [actionTypes.getIndividualSportsmens](context, id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getIndividualSportsmensStart);
      registrationApi
        .getIndividualSportsmens(id)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.getIndividualSportsmensSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.getIndividualSportsmensFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.getIndividualSportsmensFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  // getInfoAboutRegistration
  [actionTypes.getInfoAboutRegistrationApp](context, id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getInfoAboutRegistrationAppStart);
      registrationApi
        .getInfoAboutRegistrationApp(id)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.getInfoAboutRegistrationAppSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.getInfoAboutRegistrationAppFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.getInfoAboutRegistrationAppFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  // addSportsmenApp
  [actionTypes.addSportsmenApp](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.addSportsmenAppStart);
      registrationApi
        .addSportsmenApp(data)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.addSportsmenAppSuccess, response.data);
            resolve(response.data);
          } else {
            console.log(response.data, 'ОШИБКА ДОБАВЛЕНИЯ')
            context.commit(mutationTypes.addSportsmenAppFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.addSportsmenAppFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  [actionTypes.registrSportsmenWithCateg](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.registrSportsmenWithCategStart);
      registrationApi
          .registrSportsmenWithCateg(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(
                  mutationTypes.registrSportsmenWithCategSuccess,
                  response.data
              );
              resolve(response.data);
            } else {
              context.commit(
                  mutationTypes.registrSportsmenWithCategFailure,
                  response.data
              );
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.registrSportsmenWithCategFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // registrGroupWithCateg
  [actionTypes.registrGroupWithCateg](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.registrGroupWithCategStart);
      registrationApi
          .registrGroupWithCateg(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(
                  mutationTypes.registrGroupWithCategSuccess,
                  response.data
              );
              resolve(response.data);
            } else {
              context.commit(
                  mutationTypes.registrGroupWithCategFailure,
                  response.data
              );
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.registrGroupWithCategFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // registrTrioWithCateg
  [actionTypes.registrTrioWithCateg](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.registrTrioWithCategStart);
      registrationApi
          .registrTrioWithCateg(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(
                  mutationTypes.registrTrioWithCategSuccess,
                  response.data
              );
              resolve(response.data);
            } else {
              context.commit(
                  mutationTypes.registrTrioWithCategFailure,
                  response.data
              );
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.registrTrioWithCategFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // registrDuoWithCateg
  [actionTypes.registrDuoWithCateg](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.registrDuoWithCategStart);
      registrationApi
          .registrDuoWithCateg(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(
                  mutationTypes.registrDuoWithCategSuccess,
                  response.data
              );
              resolve(response.data);
            } else {
              context.commit(
                  mutationTypes.registrDuoWithCategFailure,
                  response.data
              );
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.registrDuoWithCategFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // registrJudgeToApplication
  [actionTypes.registrJudgeToApplication](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.registrJudgeToApplicationStart);
      registrationApi
          .registrJudgeToApplication(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(
                  mutationTypes.registrJudgeToApplicationSuccess,
                  response.data
              );
              resolve(response.data);
            } else {
              context.commit(
                  mutationTypes.registrJudgeToApplicationFailure,
                  response.data
              );
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.registrJudgeToApplicationFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // deleteSportsmenApp
  [actionTypes.deleteSportsmenApp](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.deleteSportsmenAppStart);
      registrationApi
        .deleteSportsmenApp(data)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.deleteSportsmenAppSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.deleteSportsmenAppFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.deleteSportsmenAppFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  // getGroupCategories
  [actionTypes.getGroupCategories](context, id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getGroupCategoriesStart);
      registrationApi
        .getGroupCategories(id)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.getGroupCategoriesSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.getGroupCategoriesFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.getGroupCategoriesFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  //добавление группы в заявку
  [actionTypes.addGroupApp](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.addGroupAppStart);
      registrationApi
        .addGroupApp(data)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.addGroupAppSuccess, response.data);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.addGroupAppFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.addGroupAppFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  //Добавление трио в заявку
  [actionTypes.addTrioApp](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.addTrioAppStart);
      registrationApi
          .addTrioApp(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.addTrioAppSuccess, response.data);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.addTrioAppFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.addTrioAppFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // Добавление дуэта в заявку
  [actionTypes.addDuoApp](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.addDuoAppStart);
      registrationApi
          .addDuoApp(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.addDuoAppSuccess, response.data);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.addDuoAppFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.addDuoAppFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // deleteGroupApp
  [actionTypes.deleteGroupApp](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.deleteGroupAppStart);
      registrationApi
        .deleteGroupApp(data)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.deleteGroupAppSuccess, response.data);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.deleteGroupAppFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.deleteGroupAppFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  // deleteDuoApp
  [actionTypes.deleteDuoApp](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.deleteDuoAppStart);
      registrationApi
          .deleteDuoApp(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.deleteDuoAppSuccess, response.data);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.deleteDuoAppFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.deleteDuoAppFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // deleteTrioApp
  [actionTypes.deleteTrioApp](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.deleteTrioAppStart);
      registrationApi
          .deleteTrioApp(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.deleteTrioAppSuccess, response.data);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.deleteTrioAppFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.deleteTrioAppFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // addJujApp
  [actionTypes.addJujApp](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.addJujAppStart);
      registrationApi
        .addJujApp(data)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.addJujAppSuccess, response.data);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.addJujAppFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.addJujAppFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  // Удаление судей из заявки на соревнования
  [actionTypes.deleteJujApp](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.deleteJujAppStart);
      registrationApi
        .deleteJujApp(data)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.deleteJujAppSuccess, response.data);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.deleteJujAppFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.deleteJujAppFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  // submitApplication
  [actionTypes.submitApplication](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.submitApplicationStart);
      registrationApi
        .submitApplication(data)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.submitApplicationSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.submitApplicationFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.submitApplicationFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  // allApplications Получение всех заявок
  [actionTypes.allApplications](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.allApplicationsStart);
      registrationApi
        .allApplications()
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.allApplicationsSuccess, response.data);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.allApplicationsFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.allApplicationsFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  [actionTypes.getAllRegion](context, prefix) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getAllRegionStart);
      registrationApi
          .getAllRegion(prefix)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.getAllRegionSuccess, response.data);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.getAllRegionFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.getAllRegionFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // getAllCity
  [actionTypes.getAllCity](context, prefix) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getAllCityStart);
      registrationApi
          .getAllCity(prefix)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.getAllCitySuccess, response.data);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.getAllCityFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.getAllCityFailure, err);
            console.log("ошибка", err);
          });
    });
  },

};

export default {
  state,
  mutations,
  actions,
};
