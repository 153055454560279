<template>
  <div class="competition__item" @click="routeDelail">
    <div class="header__block-brown" v-if="item_ver_comp.status === 'REGISTRATION_APP_CLOSE'">
      <p class="header__text">Приём заявок окончен!</p>
    </div>
    <div class="header__block" v-if="item_ver_comp.status === 'OPEN'">
      <p class="header__text">Приём заявок до {{ dateOfPrismApp }}</p>
    </div>
    <div class="header__block-brown" v-if="item_ver_comp.status === 'FINISH'">
      <p class="header__text">Соревновани окончены!</p>
    </div>

    <div class="info-about">
      <div class="competition-card__title">
        <p class="competition-title-text">
          {{ item_ver_comp.title }}
        </p>
      </div>
      <div class="info-about__description">
        <div class="title-description">
          <p class="competition-label">Дата проведения:</p>
          <p class="competition-description">
            {{ dateOfFromComp }} - {{ dateOfToComp }}
          </p>
        </div>
        <div class="title-description">
          <p class="competition-label">Место проведения:</p>
          <p class="competition-description">{{ item_ver_comp.place }}</p>
        </div>
        <div class="title-description">
          <p class="competition-label">Главный судья:</p>
          <p class="competition-description">{{ item_ver_comp.mainJudge }}</p>
        </div>
      </div>
      <div class="info-about__btn">
        <button @click="routeDelail" class="mandated__tag active">Подробнее</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppItemVerificationCompetition",
  props: {
    item_ver_comp: {
      type: Object,
      default() {
        return {};
      },
    },
    isMandated: {
      type: Boolean,
      default() {
        return {};
      },
    },
  },
  computed: {
    dateOfPrismApp() {
      return this.$moment(this.item_ver_comp.applicationsBefore)
        .locale("ru")
        .format("LL");
    },
    dateOfFromComp() {
      return this.$moment(this.item_ver_comp.fromDate)
        .locale("ru")
        .format("LL");
    },
    dateOfToComp() {
      return this.$moment(this.item_ver_comp.toDate).locale("ru").format("LL");
    },
  },
  methods: {
    routeDelail() {
      if (this.isMandated) {
        this.$router.push({
          name: "ItemMandated",
          params: { id: this.item_ver_comp.id, item: this.item_ver_comp },
        });
      } else {
        this.$router.push({
          name: "Competition",
          params: { id: this.item_ver_comp.id, item: this.item_ver_comp },
        });
      }
    },
  },
};
</script>

<style></style>
