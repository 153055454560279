<template>
  <div style="width: 100%" ref="dropdown">
    <div class="accordion" :class="{ 'is-open': isOpen }">
      <!-- Поле поиска всегда отображается -->
      <div class="pr" @click.stop="toggleAccordion">
        <input
            type="text"
            class="input-light-text"
            v-model="inputValue"
            @input="filterOptions"
            :placeholder="windowWidth ? 'Выберите группу' : 'Выберите группу - введите название группы из списка'"
        />
        <div class="input-light-text__img">
          <img src="../../assets/svg/ArrowDown.svg" alt="Arrow Down">
        </div>
      </div>

      <!-- Оборачиваем список и кнопку в контейнер -->
      <div ref="body">
        <!-- Список групп -->
        <ul class="options-list" :class="{ 'is-open': isOpen }">
          <!-- Кнопка как элемент списка с фиксацией -->
          <li v-if="selectedIds.length" class="submit-button-list-item sticky">
            <button class="button-primary" @click="emitSelected">
              Добавить выбранные группы
            </button>
          </li>

          <!-- Если опций нет, показываем сообщение -->
          <li v-if="filteredOptions.length === 0" class="option-item no-options">
            Нет подходящих групп!
          </li>
<!--          <li v-if="filteredOptions.length === 0">-->
<!--              <button class="button-primary sticky">Добавить группу</button>-->
<!--            </li>-->
          <!-- Список групп -->
          <li v-for="(option, index) in filteredOptions" :key="index" class="option-item">
            <label>
              <input
                  type="checkbox"
                  :value="option.id"
                  v-model="selectedIds"
                  @click.stop="selectOption(option)"
              />
              {{ option.name }} / {{ option.sportsmen.length }}
            </label>
          </li>
          <li v-if="selectGroupCateg.type === 'GROUP'">
            <button class="button-primary df-center-between" @click="openModal">
              Создать новую группу
              <img src="../../assets/svg/plus.svg" alt="plus" />
            </button>
          </li>
          <li v-if="selectGroupCateg.type === 'DUO'">
            <button class="button-primary df-center-between" @click="openModal">
              Создать новый дуэт
              <img src="../../assets/svg/plus.svg" alt="plus" />
            </button>
          </li>
          <li v-if="selectGroupCateg.type === 'TRIO'">
            <button class="button-primary df-center-between" @click="openModal">
              Создать новое трио
              <img src="../../assets/svg/plus.svg" alt="plus" />
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "AppAddGroupSelect",
    data() {
      return {
        isOpen: false,
        selected: this.default
            ? this.default
            : this.options.length > 0
                ? this.options[0]
                : null,
        selectWithCateg: {},
        filteredOptions: '',
        inputValue: '',
        selectedIds: [], // Для хранения ID выбранных групп
      };
    },
    props: {
      options: {
        type: Array,
        required: true,
        default: () => [], // Возвращает пустой массив по умолчанию
      },
      windowWidth: {
        type: Boolean,
        required: true,
      },
      default: {
        type: String,
        required: false,
        default: null,
      },
      selectGroupCateg: {
        type: Object,
      },
      color: {
        type: String,
      },
    },
    watch: {
      options(newOptions) {
        // Обновляем список групп при изменении options
        this.filteredOptions = newOptions;
      }
    },
    methods: {
      openModal() {
        this.$emit('openModal');
        this.isOpen = false;
      },
      selectOption(option) {
        // Логика выбора опции, если нужно что-то добавить
        this.selected = option;
      },
      emitSelected() {
        // Отправляем объект с категорией и выбранными группами
        const selectedObj = {
          categ: this.selectGroupCateg,
          groups: this.selectedIds,
        };
        this.toggleAccordion();
        this.selectedIds = []
        this.$emit('input', selectedObj);
      },
      filterOptions() {
        if (this.inputValue.trim() !== '') {
          this.isOpen = true;
          const query = this.inputValue.toLowerCase();
          this.filteredOptions = query
              ? this.options.filter(group => group.name.toLowerCase().includes(query))
              : this.options;
        } else {
          this.isOpen = false;
        }
      },
      toggleAccordion() {
        this.isOpen = !this.isOpen;
      },
      handleClickOutside(event) {
        // Проверяем, был ли клик вне компонента
        if (!this.$refs.dropdown.contains(event.target)) {
          this.isOpen = false;
        }
      },
    },
    mounted() {
      this.filteredOptions = this.options;
      // Добавляем обработчик клика для закрытия списка, если клик был вне компонента
      document.addEventListener("click", this.handleClickOutside);
    },
    beforeDestroy() {
      // Удаляем обработчик клика при уничтожении компонента
      document.removeEventListener("click", this.handleClickOutside);
    },
  };
</script>
