import axios from "@/api/axios";

const getStatistics = (competitionId) => {
  return axios.get(`/statistics/register-sportsmen-in-category/${competitionId}`);
};
const getAnalytics = (competitionId) => {
  return axios.get(`/statistics/get/page-analytic/${competitionId}`);
}
export default {
  getStatistics,
  getAnalytics
};
