<template>
  <div style="width: 100%" ref="dropdown">
    <div class="accordion" :class="{ 'is-open': isOpen }">
      <!-- Поле поиска всегда отображается -->
      <div class="pr" @click.stop="toggleAccordion">
        <input
            type="text"
            class="input-light-text"
            v-model="inputValue"
            @input="filterOptions"
            :placeholder="windowWidth ? 'Добавьте судью' : 'Добавьте судью - введите фамилию или имя судьи из списка'"
        />
        <div class="input-light-text__img">
          <img src="../../assets/svg/ArrowDown.svg" alt="Arrow Down">
        </div>
      </div>

      <!-- Оборачиваем список и кнопку в контейнер -->
      <div ref="body">
        <!-- Список судей -->
        <ul class="options-list" :class="{ 'is-open': isOpen }">
          <!-- Кнопка как элемент списка с фиксацией -->
          <li v-if="selectedIds.length" class="submit-button-list-item sticky">
            <button class="button-primary" @click="emitSelected">
              Добавить выбранных судей
            </button>
          </li>

          <!-- Если опций нет, показываем сообщение -->
          <li v-if="filteredOptions.length === 0" class="option-item no-options">
            Нет подходящих судей!
          </li>

          <!-- Список судей -->
          <li v-for="(option, index) in filteredOptions" :key="index" class="option-item">
            <label>
              <input
                  type="checkbox"
                  :value="option.id"
                  v-model="selectedIds"
                  @click.stop="selectOption(option)"
              />
              {{ option.surname }} {{ option.name }} {{ option.patronymic }} / {{ option.category }}
            </label>
          </li>

<!--          &lt;!&ndash; Добавление нового судьи &ndash;&gt;-->
<!--          <li class="add">-->
<!--            <img src="../../assets/svg/plus.svg" alt="plus" />-->
<!--            <p class="add-text" @click="showModalAddJuj, toggleAccordion">Добавить судью</p>-->
<!--          </li>-->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "AppAddJudgeSelect",
    data() {
      return {
        isOpen: false,
        inputValue: '',
        filteredOptions: '',
        selectedIds: [], // Для хранения ID выбранных судей
      };
    },
    props: {
      options: {
        type: Array,
        required: true,
        default: () => [],
      },
      windowWidth: {
        type: Boolean,
        required: true,
      },
      default: {
        type: String,
        required: false,
        default: null,
      },
      color: {
        type: String,
      },
    },
    watch: {
      options(newOptions) {
        // Обновляем список судей при изменении options
        this.filteredOptions = newOptions;
      }
    },
    methods: {
      selectOption(option) {
        // Логика выбора опции, если нужно что-то добавить
        this.selected = option;
      },
      emitSelected() {
        // Отправляем выбранных судей
        const selectedObj = {
          judges: this.selectedIds,
        };
        this.toggleAccordion();
        this.selectedIds = []
        this.$emit('input', selectedObj);
      },
      filterOptions() {
        if (this.inputValue.trim() !== '') {
          this.isOpen = true;
          const query = this.inputValue.toLowerCase();
          this.filteredOptions = query
              ? this.options.filter(judge => `${judge.surname} ${judge.name}`.toLowerCase().includes(query))
              : this.options;
        } else {
          this.isOpen = false;
        }
      },
      toggleAccordion() {
        this.isOpen = !this.isOpen;
      },
      handleClickOutside(event) {
        // Проверяем, был ли клик вне компонента
        if (!this.$refs.dropdown.contains(event.target)) {
          this.isOpen = false;
        }
      },
    },
    mounted() {
      this.filteredOptions = this.options;
      document.addEventListener("click", this.handleClickOutside);
    },
    beforeDestroy() {
      // Удаляем обработчик клика при уничтожении компонента
      document.removeEventListener("click", this.handleClickOutside);
    },
  };
</script>
