<template>
  <div class="competition-card">
    <div class="competition-card__info">
      <div class="competition-card__title">
        <p class="competition-title-text">
          {{ item_competition.title }}
        </p>
      </div>

      <div class="competition-card__dates">
        <div class="competition-card__date-from">
          <p class="competition-label">Дата проведения:</p>
          <p class="competition-description">
            {{ dateOfFromComp }} - {{ dateOfToComp }}
          </p>
        </div>
        <div class="competition-card__date-to">
          <p class="competition-label">Приём заявок до:</p>
          <p class="competition-description">{{ dateOfPrismApp }}</p>
        </div>
      </div>

      <div class="competition-status">
        <p :class="statusClass">{{ statusMessage }}</p>
      </div>
    </div>
  </div>
</template>
<script >
  import { mapState } from "vuex";

  export default {
    name: "AboutItemCompetition",
    data() {
      return {}
    },
    props: {
      item_competition: {
        type: Object,
        default() {
          return {};
        },
      }
    },
    computed: {
      ...mapState({
        isLoading: (state) => state.competition.isLoading,
      }),

        statusMessage() {
          const messages = {
            'OPEN_NOT_APPLICATION': 'Прием заявок на соревнования открыт',
            'OPEN_EDIT_APPLICATION': 'Прием заявок на соревнования открыт, при необходимости измените заявку',
            'CLOSE_EDIT_APPLICATION': 'Прием заявок окончен, при необходимости измените заявку',
            'CLOSE_NOT_APPLICATION': 'Прием заявок окончен',
            'FINISH': 'Соревнования полностью завершены',
            'NOT_FOUND_STATUS': 'Неизвестный статус'
          };
          return messages[this.item_competition.status] || 'Статус не определен';
        },
      statusClass() {
        const statusClasses = {
          'OPEN_NOT_APPLICATION': 'tag tag-open',
          'OPEN_EDIT_APPLICATION': 'tag tag-edit',
          'CLOSE_EDIT_APPLICATION': 'tag tag-close',
          'CLOSE_NOT_APPLICATION': 'tag tag-close-not',
          'FINISH': 'tag tag-finish',
          'NOT_FOUND_STATUS': 'tag tag-unknown'
        };
        return statusClasses[this.item_competition.status] || 'tag tag-default';
      },
      dateOfFromComp() {
        return this.$moment(this.item_competition.fromDate)
            .locale("ru")
            .format("LL");
      },
      dateOfToComp() {
        return this.$moment(this.item_competition.toDate).locale("ru").format("LL");
      },
      dateOfPrismApp() {
        return this.$moment(this.item_competition.applicationsBefore)
            .locale("ru")
            .format("LL");
      },
    },

  };
</script>
<style scoped>

</style>
