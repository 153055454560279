<template>
  <table class="table">
    <thead>
      <tr>
        <th class="th__name background-green color-white">Категория(выступает)</th>
        <th class="th__years background-green color-white">Название</th>
        <th class="th__section background-green color-white">Тренеры</th>
      </tr>
    </thead>
    <tbody>
      <item-table-app-group
        v-for="(group, i) in this.allSportsmen"
        :key="i"
        :item_group="group"
      />
    </tbody>
  </table>
</template>

<script>
import { mapState } from "vuex";
import ItemTableAppGroup from "./ItemTableAppGroup.vue";
import { actionTypesEntered } from "@/store/modules/enteredData";
export default {
  components: { ItemTableAppGroup },
  name: "AppTableAppGroup",
  props: {
    allSportsmen: {
      type: Array,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    submitData() {
      this.$emit("checkCoach", this.checkedCoach);
    },
    addCoach() {
      this.$emit("addCoach");
    },
    editSportsmen(item) {
      this.$emit("editSportsmen", item);
    },
    deleteSportsmen(item) {
      // удаление спортсмена

      this.$store
        .dispatch(actionTypesEntered.deleteSportsmen, item.id)
        .then(() => {
          this.allSportsmens.splice(
            0,
            this.allSportsmens.length,
            ...this.allSportsmens.filter((n) => n.id !== item.id)
          );
        })
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.registration.isLoading,
      // allSportsmens: (state) => state.enteredData.allSportsmens,
    }),
  },
};
</script>

<style></style>
