<template>
  <div class="bar">
    <h2 class="text-title-20">Статистика по регионам в ИП </h2>
    <Bar
        :chart-data="chartData"
        :chart-options="chartOptions"
        class="statistics-chart-bar"

    />
  </div>
</template>

<script>
  import { Bar } from "vue-chartjs";
  import {
    Chart,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
  } from "chart.js";
  import { mapState } from 'vuex'

  Chart.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

  export default {
    name: "AppBarChartIndividual",
    components: { Bar },

    data() {
      return {
        chartData: null, // Данные диаграммы, генерируются на основе dataBarStat
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false, // Легенда отключена
            },
            tooltip: {
              callbacks: {
                label: (context) => `${context.label}: ${context.raw} человек`,
              },
            },
          },
          scales: {
            x: {
              ticks: {
                font: {
                  size: 12,
                },
                maxRotation: 45, // Угол наклона
                minRotation: 45, // Угол наклона
              },
            },
            y: {
              beginAtZero: true,
              ticks: {
                font: {
                  size: 12,
                },
              },
            },
          },
        },
      };
    },
    computed: {
      ...mapState({
        isLoadingAnalytics: (state) => state.statistics.isLoadingAnalytics,
        allAnalytics: (state) => state.statistics.allAnalytics,
      }),
  },
    created() {
      this.generateChartData();
    },
    methods: {
      generateChartData() {
        // Формирование chartData для диаграммы
        this.chartData = {
          labels: this.allAnalytics.individualRegionAndCitySportsmenCounts.map((item) => item.regionAndCity), // Метки оси X
          datasets: [
            {
              label: "Статистика по регионам в ИП",
              barPercentage: 0.5,
              barThickness: 100,
              maxBarThickness: 40,
              minBarLength: 2,
              backgroundColor: [
                "rgba(25, 44, 140, 0.8)",
                "rgba(15, 49, 169, 0.8)",
                "rgba(15, 40, 169, 0.5)",
                "rgba(147, 170, 252, 0.8)",
                "rgba(198, 210, 253, 0.8)",
                "rgba(229, 234, 252, 0.8)",
                "rgba(201, 203, 207, 0.2)",
                "rgba(15, 40, 169, 0.5)",
                "rgba(147, 170, 252, 0.8)",
                "rgba(198, 210, 253, 0.8)",
                "rgba(229, 234, 252, 0.8)",
                "rgba(201, 203, 207, 0.2)",
              ],
              borderColor: [
                "rgb(25, 44, 140)",
                "rgb(15, 49, 169)",
                "rgb(15, 49, 169)",
                "rgb(147, 170, 252)",
                "rgb(198, 210, 253)",
                "rgb(229, 234, 252)",
                "rgb(201, 203, 207)",
                "rgb(15, 49, 169)",
                "rgb(147, 170, 252)",
                "rgb(198, 210, 253)",
                "rgb(229, 234, 252)",
                "rgb(201, 203, 207)",
              ],
              borderWidth: 2,
              borderRadius: 5,
              data: this.allAnalytics.individualRegionAndCitySportsmenCounts.map((c) => c.count),
              pointRadius: 10,
            },
          ],
        };
      },
    },
  };
</script>

<style scoped>
.bar {
  display: flex;
  flex-direction: column;

  gap: 20px;
}

.bar__title {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 20px 0;
}
</style>
