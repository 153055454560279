<template>
  <div class="search__wrap-block">
    <div class="search__select-categ">
      <select-with-check-categ :checkSelect="item_sport.categ" />
    </div>
    <div class="search__add-sportsmen">
      <select-with-check-people :checkSelect="item_sport.sportsmen" />
    </div>
    <div class="search-edit__wrap">
      <!-- <div class="search-edit__wrap-img">
        <img src="../../assets/svg/pain.svg" alt="pain" />
      </div> -->
      <div class="table__icon-btn">
        <span class="table__icon-delete"  @click="deleteItemAppSportsmen()">🗑️</span>
      </div>
    </div>
  </div>
</template>

<script>
import SelectWithCheckCateg from "../customSelect/SelectWithCheckCateg.vue";
import SelectWithCheckPeople from "../customSelect/SelectWithCheckPeople.vue";
export default {
  components: { SelectWithCheckCateg, SelectWithCheckPeople },
  name: "AppItemAppIndividual",
  data() {
    return {};
  },
  props: {
    item_sport: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {
    deleteItemAppSportsmen() {
      this.$emit("deleteItemAppSportsmen", this.item_sport);
    },
  },
};
</script>

<style></style>
