<template>
  <div class="select-component">
    <input
        type="text"
        class="input-light-text"
        v-model="inputValue"
        @input="filterOptions"
        placeholder="Введите для поиска..."
    />
    <ul v-if="filteredOptions.length" class="options-list"  :class="{ 'is-open': isOpen }">
      <li
          v-for="(option, index) in filteredOptions"
          :key="index"
          class="option-item"
          style="display: flex; align-items: center;"
          @click="selectOption(option.name)"
      >
        <p style="width: 70%;"> {{ option.name }}</p>
        <button class="button-primary-mini" type="button" style="width: 30%;">Выбрать</button>
      </li>
    </ul>
  </div>
</template>

<script>
  import { actionTypes } from '@/store/modules/registration'

  export default {
    name: "AppCitySelect",
    data() {
      return {
        inputValue: this.default
            ? this.default
            : '', // Текущее значение в поле ввода
        isOpen: false, // Статус открытия списка опций
        filteredOptions: [], // Отфильтрованные опции на основе ввода
      };
    },
    props: {
      idRegion: {
        type: [Number,null],
        required: false,
        default: null,
      },
      default: {
        type: String,
        required: false,
        default: null,
      },
    },
    methods: {
      filterOptions() {
        // Проверяем, не пустой ли ввод, и открываем список опций
        if (this.inputValue.trim() !== '') {
          this.isOpen = true;
          // Отправка запроса на сервер при каждом изменении inputValue
          this.$store
              .dispatch(actionTypes.getAllCity, { query: this.inputValue ,regionId: this.idRegion })
              .then((response) => {
                this.filteredOptions = response.result;
              })
              .catch((er) => {
                console.log("Ошибка при получении данных", er);
              });
        } else {
          // Закрытие списка опций, если ввод пустой
          this.isOpen = false;
        }
      },
      selectOption(option) {
        // Выбор опции и закрытие списка опций
        // inputValue
        this.inputValue = option;
        this.isOpen = false;
        this.$emit('citySelected', this.inputValue);
      },
    },
  };
</script>


