<template>
  <transition name="modal" @click="$emit('close')">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <div class="modal__cross" @click="$emit('close')">
              <img src="../../assets/svg/cross.svg" alt="cross" />
            </div>
          </div>
          <form @submit.prevent="safeInfoJuj">
            <div class="modal-body">
              <div class="modal-body__title">
                <p class="modal__title">Добавление судьи</p>
              </div>
              <ValidationErrors
                  v-if="validationErrorsJuj"
                  :validation-errors="validationErrorsJuj.message"
              />
              <div class="modal-scroll-max">

              <div
                class="message-about-empty empty"
                v-if="isSubmitted && this.$v.$invalid"
              >
                <p class="message-about-empty__text">Заполните все данные!</p>
              </div>
              <div class="input-wrap__row">
                <div class="input-wrap__label-with-input-row-3">
                  <div class="input-wrap__label">
                    <p class="text-label">Фамилия:</p>
                  </div>
                  <input
                    type="text"
                    class="input-light-text"
                    placeholder="Фамилия"
                    :class="{
                      'error-border-input': isSubmitted && $v.surname.$error,
                    }"
                    name="surname"
                    v-model="surname"
                  />
                  <img
                    src="~@/assets/svg/Error.svg"
                    alt="error"
                    v-if="isSubmitted && $v.surname.$error"
                    class="wrapValidationInput__error"
                  />
                </div>
                <div class="input-wrap__label-with-input-row-3">
                  <div class="input-wrap__label">
                    <p class="text-label">Имя:</p>
                  </div>
                  <input
                    type="text"
                    class="input-light-text"
                    :class="{
                      'error-border-input': isSubmitted && $v.name.$error,
                    }"
                    placeholder="Имя"
                    name="name"
                    v-model="name"
                  />
                  <img
                    src="~@/assets/svg/Error.svg"
                    alt="error"
                    v-if="isSubmitted && $v.name.$error"
                    class="wrapValidationInput__error"
                  />
                </div>
                <div class="input-wrap__label-with-input-row-3">
                  <div class="input-wrap__label">
                    <p class="text-label">Отчество:</p>
                  </div>
                  <input
                    type="text"
                    class="input-light-text"
                    placeholder="Отчество"
                    :class="{
                      'error-border-input': isSubmitted && $v.patronymic.$error,
                    }"
                    name="patronymic"
                    v-model="patronymic"
                  />
                  <img
                    src="~@/assets/svg/Error.svg"
                    alt="error"
                    v-if="isSubmitted && $v.patronymic.$error"
                    class="wrapValidationInput__error"
                  />
                </div>
              </div>

              <div class="input-wrap__column">
                <div v-if="isSubmitted && $v.phone.$error" class="validation">
                  <span
                    class="validation__text"
                    v-if="!$v.phone.minLength || !$v.phone.maxLength || $v.phone.$error"
                    >*Номер телефона должен состоять из 12 символов</span
                  >
                </div>
                <div class="input-wrap__label-with-input-column">
                  <div class="input-wrap__label">
                    <p class="text-label">Телефон:</p>
                  </div>
                  <input
                      type="tel"
                      class="input-light-text"
                      :class="{'error-border-input': isSubmitted && $v.phone.$error}"
                      placeholder="+7 (***)***-****"
                      name="phone"
                      v-model="phone"
                      v-mask="'+7##########'"
                  />
                  <img
                    src="~@/assets/svg/Error.svg"
                    alt="error"
                    v-if="isSubmitted && $v.phone.$error"
                    class="wrapValidationInput__error"
                  />
                </div>
              </div>
                <div class="input-wrap__column">
                  <div class="input-wrap__label-with-input-column">
                    <div class="input-wrap__label">
                      <p class="text-label">Судейская категория:</p>
                    </div>
                    <custom-select-rank
                        @input="safeRank"
                        :class="{
                      'error-border-input': isSubmitted && $v.rank.$error,
                    }"
                        :default="this.defaultCateg"
                        :options="this.selectJujCateg"
                    />
                    <!-- <custom-select @input="safeRank" :options="this.selectRank" /> -->
                  </div>
                </div>
              <div class="input-wrap__row">
                <div class="input-wrap__label-with-input-row">
                  <div class="input-wrap__label">
                    <p class="text-label">Область/регион:</p>
                  </div>
                  <AppRegionSelect @regionSelected="safeRegion" :default="infoUser.region" />
                </div>
                <div class="input-wrap__label-with-input-row" v-if="this.region" ref="cityBlock">
                  <div class="input-wrap__label">
                    <p class="text-label">Город:</p>
                  </div>
                  <AppCitySelect :idRegion="this.region.id"  @citySelected="safeCity" :default="infoUser.city"  />
                </div>
              </div>
            </div>

            </div>
            <div class="modal-footer">
              <div class="modal-footer__button">
                <button class="mandated__tag dark">Сохранить</button>
              </div>
              <div class="modal-footer__button">
                <button
                    type="button"
                    class="mandated__tag button-cancel"
                    @click="$emit('close')"
                >
                  Отменить
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { actionTypesEntered } from "@/store/modules/enteredData";
import { mapState } from "vuex";
import CustomSelectRank from "../customSelect/CustomSelectRank.vue";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import ValidationErrors from '@/components/ValidationErrors.vue'
import AppRegionSelect from '@/components/customSelect/RegionSelect.vue'
import AppCitySelect from '@/components/customSelect/CitySelect.vue'
import { actionTypesMandated } from '@/store/modules/mandated'
import { actionTypes } from '@/store/modules/registration'
export default {
  name: "AppModalAddJuj",
  data() {
    return {
      name: "",
      surname: "",
      patronymic: "",
      phone: "",
      rank: "",
      city: "",
      region: "",
      selectRegion: ["Новосибирская область"],
      selectJujCateg: [
        { id: 1, name: "СС1К" },
        { id: 2, name: "СС2К" },
        { id: 3, name: "СС3К" },
        { id: 4, name: "ССВК" },
        { id: 5, name: "юный судья" },
        { id: 6, name: "б/к" },
      ],
      selectCity: ["Новосибирск","Краснообск", "Бердск", "Академгородок"],
      defaultRegion: "Выберите регион",
      defaultCity: "Выберите город",
      defaultCateg: "Выберите категорию",
      isSubmitted: false,
    };
  },
  props: {
    typeAdd: {
      type: String,
      default() {
        return {};
      },
    }
  },
  validations: {
    name: {
      required,
    },
    surname: {
      required,
    },
    patronymic: {
      required,
    },
    rank: {
      required,
    },
    phone: {
      required,
      minLength: minLength(12),
      maxLength: maxLength(12),
    },
    city: {
      required,
    },
    region: {
      required,
    },
  },
  components: {
    AppCitySelect,
    AppRegionSelect,
    ValidationErrors,
    CustomSelectRank,
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.registration.isLoading,
      infoUser: (state) => state.auth.infoUser,
      selectRank: (state) => state.registration.selectRank,
      validationErrorsJuj: state => state.enteredData.validationErrorsJuj
    }),
  },
  methods: {
    safeCity(item) {
      console.log(item, "city");
      this.city = item;
    },
    safeRegion(item) {
      console.log(item, "region");
      this.region = item;
      this.scrollToCityBlock();
    },
    scrollToCityBlock() {
      this.$nextTick(() => {
        if (this.$refs.cityBlock) {
          this.$refs.cityBlock.scrollIntoView({
            behavior: 'smooth', // Плавный скролл
            block: 'start',
          });
        }
      });
    },
    safeRank(item) {
      console.log(item, "rank");
      this.rank = item;
    },
    async safeInfoJuj() {
      this.isSubmitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        console.log("Данные не заполнены");
        return;
      }

      // Собираем данные спортсмена
      const judgeData = {
        name: this.name,
        surname: this.surname,
        phone: this.phone,
        patronymic: this.patronymic,
        region: this.region.name || this.region,
        city: this.city,
        category: this.rank.name,
      };

      try {
        // Условие 1: Если есть тип mandate, делаем запрос на actionTypes.registrSportsmenWithCateg
        if (this.typeAdd === ' mandate') {
          judgeData.idCompetition = this.$route.params.id; // Привязка к категории
          await this.$store.dispatch(actionTypesMandated.addJudgeToCompetition, judgeData);
        }
        // Условие 2: Если есть тип mandate, делаем запрос на actionTypes.registrSportsmenWithCateg
        else if(this.typeAdd === 'application') {
          judgeData.idCompetition = this.$route.params.id; // Привязка к категории
          await this.$store.dispatch(actionTypes.registrJudgeToApplication, judgeData)
              .then(() => {
              });
        }
        // Условие 3: Если нет ни category, ни application_category, делаем запрос на actionTypesEntered.registrSportsmen
        else {
          await this.$store.dispatch(actionTypesEntered.registrJuj, judgeData);
        }

        // Очищаем форму после успешного добавления
        this.clearForm();
        this.$emit("close");
      } catch (er) {
        console.log("Ошибка", er);
      }
    },
    clearForm() {
      this.name = "";
      this.surname = "";
      this.patronymic = "";
      this.region = "";
      this.city = "";
      this.phone = "";
      this.rank = "";
    },
    // safeInfoJuj() {
    //   this.isSubmitted = true;
    //   this.$v.$touch();
    //   if (this.$v.$invalid) {
    //     return console.log("Данные не заполенены");
    //   } else {
    //     this.$store
    //       .dispatch(actionTypesEntered.registrJuj, {
    //         name: this.name,
    //         surname: this.surname,
    //         phone: this.phone,
    //         patronymic: this.patronymic,
    //         region: this.region.name,
    //         city: this.city,
    //         category: this.rank.name,
    //       })
    //       .then(() => {
    //         this.$emit("close");
    //         this.name = "";
    //         this.surname = "";
    //         this.patronymic = "";
    //         this.region = "";
    //         this.city = "";
    //         this.phone = "";
    //         this.rank = "";
    //       })
    //       .catch((er) => {
    //         console.log("Ошибка", er);
    //       });
    //   }
    // },
  },
  mounted() {
    if (this.infoUser) {
      this.city = this.infoUser.city || '';
      this.region = this.infoUser.region || '';
    }
  }
};
</script>

<style></style>
