<template>
  <div class="accordion-container">
    <div class="accordion" :class="{ 'is-open': isOpen }" ref="excludedElement">
      <!-- Заголовок аккордеона -->
      <div
          class="accordion-header"
          @click="toggleAccordion()"
          v-if="selected === this.default"
      >
        {{ selected }}
      </div>
      <div class="accordion-header" @click="toggleAccordion()" v-else>
        {{ selected.name }}
      </div>

      <!-- Тело аккордеона с опциями -->
      <ul class="options-list" :class="{ 'is-open': isOpen }" ref="body">
        <li
            class="option-item"
            v-for="(option, i) in options"
            :key="i"
            @click="safeSelect(option)"
        >
          {{ option.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        isOpen: false,
        selected: this.default
            ? this.default
            : this.options.length > 0
                ? this.options[0]
                : null,
      };
    },
    props: {
      options: {
        type: Array,
        required: true,
      },
      default: {
        type: String,
        required: false,
        default: null,
      },
    },
    methods: {
      // Выбор элемента из списка
      safeSelect(option) {
        this.selected = option;
        this.$emit("input", option);
        this.toggleAccordion(); // Закрываем аккордеон после выбора
      },
      // Открыть/закрыть аккордеон
      toggleAccordion() {
        this.isOpen = !this.isOpen;

      },
      // Закрытие аккордеона при клике вне компонента
      handleClickOutside(event) {
        if (this.isOpen && !this.$refs.excludedElement.contains(event.target)) {
          this.isOpen = false;
        }
      },
    },
    mounted() {
      // Обработчик клика для закрытия по клику вне компонента
      document.addEventListener("click", this.handleClickOutside);
    },
    beforeDestroy() {
      // Удаление обработчика клика
      document.removeEventListener("click", this.handleClickOutside);
    },
  };
</script>

<style scoped>
.options-list {
  max-height: 150px !important;
}
</style>
