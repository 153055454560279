<template>
  <table class="table">
    <thead class="background-green">
      <tr >
        <th class="th__name background-green color-white">Категория(выступает)</th>
        <th class="th__name background-green color-white">Ф.И.О.</th>
        <th class="th__years background-green color-white">Год р.</th>
        <th class="th__section background-green color-white">Разряд(имеет)</th>
      </tr>
    </thead>
    <tbody>
      <item-table-app-sportsmen
        v-for="(sportsman, i) in this.allSportsmen.sportsmans"
        :key="i"
        :item_sportsmen="sportsman"
        @deleteSportsmen="deleteSportsmen"
        @editSportsmen="editSportsmen"
      />
    </tbody>
  </table>
</template>

<script>
import { mapState } from "vuex";
import ItemTableAppSportsmen from "./ItemTableAppSportsmen.vue";
import { actionTypesEntered } from "@/store/modules/enteredData";
export default {
  components: { ItemTableAppSportsmen },
  name: "AppTableAppSportsmen",
  props: {
    allSportsmen: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    submitData() {
      this.$emit("checkCoach", this.checkedCoach);
    },
    addCoach() {
      this.$emit("addCoach");
    },
    editSportsmen(item) {
      this.$emit("editSportsmen", item);
    },
    deleteSportsmen(item) {
      // удаление спортсмена

      this.$store
        .dispatch(actionTypesEntered.deleteSportsmen, item.id)
        .then(() => {
          this.allSportsmens.splice(
            0,
            this.allSportsmens.length,
            ...this.allSportsmens.filter((n) => n.id !== item.id)
          );
        })
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.registration.isLoading,
      // allSportsmens: (state) => state.enteredData.allSportsmens,
    }),
  },
};
</script>

<style></style>
