<template>
  <div style="width: 100%" ref="dropdown">
    <div class="accordion" :class="{ 'is-open': isOpen }" ref="excludedElement">
      <!-- Поле поиска всегда отображается -->
      <div class="pr" @click.stop="toggleAccordion">
        <input
            type="text"
            class="input-light-text"
            v-model="inputValue"
            @input="filterOptions"
            :placeholder="windowWidth ? 'Выбрать спортсмена' : 'Выбрать спортсмена - введите фамилию или выберите из списка'"
        />
        <div class="input-light-text__img">
          <img src="../../assets/svg/ArrowDown.svg" alt="Arrow Down" />
        </div>
      </div>

      <!-- Список спортсменов -->
      <div ref="body">
        <ul class="options-list" :class="{ 'is-open': isOpen }">
          <!-- Кнопка подтверждения -->
          <li v-if="selectedInfo.length" class="submit-button-list-item sticky">
            <button class="button-primary" @click.prevent="emitSelected">
              Добавить выбранных спортсменов
            </button>
          </li>

          <!-- Если нет подходящих спортсменов -->
          <li v-if="filteredOptions.length === 0" class="option-item no-options">
            Нет подходящих спортсменов!
          </li>

          <!-- Выбор спортсменов -->
          <li v-for="(option, index) in filteredOptions" :key="index" class="option-item">
            <label>
              <input
                  type="checkbox"
                  :value="option"
                  :checked="isSelected(option)"
                  @change="toggleSelection(option)"
              />
              {{ option.surname }} {{ option.name }} / {{ option.dateOfBirth }}
              <span v-if="!windowWidth">/ {{ option.rank }}</span>
            </label>
          </li>
          <li v-if="filteredOptions.length === 0" >
            <button class="button-primary df-center-between" @click="openModal">
              Создать нового спортсмена
              <img src="../../assets/svg/plus.svg" alt="plus" />
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "AppAddPeopleSelect",
    data() {
      return {
        isOpen: false,
        filteredOptions: [],
        inputValue: '',
        selectedInfo: [], // Массив выбранных спортсменов
        selectedIds: [],  // ID выбранных спортсменов
      };
    },
    props: {
      options: {
        type: Array,
        default: () => [],  // По умолчанию пустой массив
      },
      windowWidth: {
        type: Boolean,
        required: true,
      },
      default: {
        type: String,
        required: false,
        default: null,
      },
      selectIndCateg: {
        type: Object,
      },
      value: {
        type: Array,
        default: () => [],  // По умолчанию пустой массив
      },
    },
    watch: {
      options(newOptions) {
        // Если options null, делаем его пустым массивом
        this.filteredOptions = newOptions || [];
      },
      value(newValue) {
        // Обновляем selectedInfo при изменении value
        this.selectedInfo = newValue || [];
        this.selectedIds = this.selectedInfo.map(obj => obj.id);
      },
      isOpen(newValue) {
        if (newValue) {
          document.addEventListener('click', this.handleClickOutside);
        } else {
          document.removeEventListener('click', this.handleClickOutside);
        }
      }
    },
    methods: {
      openModal() {
        this.$emit('openModal');
        this.isOpen = false;
      },
      isSelected(option) {
        return this.selectedInfo.some(item => item.id === option.id);
      },
      toggleSelection(option) {
        // const exists = this.selectedInfo.some(item => item.sportsmenId === option.id);
        const exists = this.selectedInfo.some(item => item.id === option.id);
        if (exists) {
          // this.selectedInfo = this.selectedInfo.filter(item => item.sportsmenId !== option.id);
          this.selectedInfo = this.selectedInfo.filter(item => item.id !== option.id);
          // this.$emit('input', this.selectedInfo); //УДАЛЯЕТ ПО v-model
        } else {
          this.selectedInfo = [...this.selectedInfo, option];
          // this.selectedInfo.push(option); СРАБАТЫВАЕТ СРАЗУ ПО v-model
        }
        // Обновляем selectedIds
        this.selectedIds = this.selectedInfo.map(obj => obj.id);
      },
      emitSelected() {
        // Отправляем объект с категорией и выбранными спортсменами
        this.$emit('input', this.selectedInfo);  // Обновляем v-model
        if(this.selectIndCateg && this.selectIndCateg.id) {
          this.$emit('submitAdd');  // Обновляем v-model
        }
        this.toggleAccordion();
      },
      filterOptions() {
        if(!this.isOpen) {
          this.toggleAccordion()
        }
        const query = this.inputValue.toLowerCase();
        this.filteredOptions = query
            ? this.options.filter(sportsmen => sportsmen.surname.toLowerCase().includes(query))
            : this.options;
      },
      toggleAccordion() {
        this.isOpen = !this.isOpen;
        this.$emit('input', this.selectedInfo);
      },
      handleClickOutside(event) {
        // Проверяем, был ли клик вне исключенного элемента и его детей
        if (this.isOpen && !this.$refs.excludedElement.contains(event.target)) {
          this.isOpen = false;
          console.log(event, 'event false')
        }
      },
    },
    mounted() {
      this.filteredOptions = this.options || [];  // Если options null, устанавливаем пустой массив
      this.selectedInfo = this.value || [];  // Используем переданное значение через v-model
      this.selectedIds = this.selectedInfo.map(obj => obj.sportsmenId);
    },
    beforeDestroy() {
      document.removeEventListener('click', this.handleClickOutside);
    },
  };
</script>
