// import axios from "@/api/axios";
import axios from 'axios';
const axiosInstanceWithoutAuth = axios.create({
  // baseURL: "http://109.111.185.150:9041/api/1",
  baseURL: "https://reg-gym.ru/api/1",


});
const allCompetitions = () => {
  return axiosInstanceWithoutAuth.get(`/competition/get/all`);
};
const idCompetition =(id) => {
  return axiosInstanceWithoutAuth.get(`/get/info_competition/${id}`);
}

export default {
  allCompetitions,
  idCompetition

};
