<template>
  <!-- Используем tbody как корневой элемент для компонента -->
  <tbody>
  <!-- Основная строка группы -->
  <tr>
    <td class="table__icon-btn">
      <span class="table__icon-edit"  @click="editGroup()">✏️</span>
      <span class="table__icon-delete"  @click="deleteGroup()">🗑️</span>
    </td>

    <td class="td__open-group" @click="openSelect()">
      <div class="open-group">
        <img
            src="../../assets/svg/ArrowDown.svg"
            alt="down"
            class="open-group__img"
            :class="{ 'rotate': isOpenSelect }"
        />
        <p class="open-group__text limited-text">{{ this.item_group.name }}</p>
      </div>
    </td>
    <td class="limited-text">{{ this.item_group.coachGroup }}</td>
  </tr>

  <!-- Вложенные строки для спортсменов в группе -->
  <template  v-if="isOpenSelect" >
  <tr v-for="(sportsmen, i) in this.item_group.sportsmen" :key="i" class="sub-row">
    <td colspan="3" class="sub-cell">
      <div class="sub-row-content">
        <span class="sub-col name">{{ sportsmen.surname }} {{ sportsmen.name }}</span>
        <span class="sub-col date">{{ formatDate(sportsmen.dateOfBirth) }}</span>
        <span class="sub-col rank" v-if="type ==='GROUP'">{{ sportsmen.rank }}</span>
        <span class="sub-col coach limited-text" v-if="type ==='GROUP'" > {{ sportsmen.coachNames }}</span>
      </div>
    </td>
  </tr>
  </template>
  </tbody>
</template>

<script>
  export default {
    name: "AppItemTableGroup",
    data() {
      return {
        isOpenSelect: false, // Управление раскрытием группы
      };
    },
    props: {
      item_group: {
        type: Object,
        default() {
          return {};
        },

      },
      type: {
        type: String,
        default() {
          return {};
        },
      }
    },
    methods: {
      deleteGroup() {
        this.$emit("deleteGroup", this.item_group);
      },
      editGroup() {
        this.$emit("editGroup", this.item_group);
      },
      openSelect() {
        this.isOpenSelect = !this.isOpenSelect;
      },
      formatDate(date) {
        if (date) {
          return date.split(".").reverse().join(".");
        }
        return "";
      },
    },
  };
</script>

<style scoped>
/* Основные стили компонента */
.edit__btn img {
  cursor: pointer;
  margin-right: 10px;
  opacity: 0.7;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.edit__btn img:hover {
  opacity: 1;
  transform: scale(1.1);
}

.td__open-group {
  cursor: pointer;
}

.open-group {
  display: flex;
  align-items: center;
}

.open-group__img {
  width: 12px;
  height: 12px;
  margin-right: 10px;
  transition: transform 0.3s ease;
}

.open-group__img.rotate {
  transform: rotate(180deg); /* Поворот при открытии */
}

/* Вложенные строки */
.sub-row {
  background: #f9f9f9; /* Легкий серый фон для вложенных строк */
}

.sub-cell {
  padding: 10px 20px;
  border-top: 1px solid #e0e0e0;
}

.sub-row-content {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Центрирование по вертикали */
  padding-left: 20px;
}

/* Каждая колонка span в строке спортсмена */
.sub-row-content .sub-col {
  display: flex;
  justify-content: start;
  padding: 0 10px;
  font-size: 14px;
  color: #666666;
}

/* Ширина каждой колонки */
.sub-row-content .name {
  width: 200px;
}

.sub-row-content .date {
  width: 150px;
  text-align: center;
}

.sub-row-content .rank {
  width: 100px;
  text-align: center;
}

.sub-row-content .coach {
  width: 250px;
}

.sub-row-content span {
  margin-right: 20px;
}

.sub-row-content span:last-child {
  margin-right: 0;
}
</style>
