<template>
<div class="content-card">
<div class="content-card__subtitle">
  <h2 class="tag tag-open">Без вертушки</h2>
</div>
<div class="category-select-container">
  <label for="categorySelectNoSpinner" >Категория</label>
  <select id="categorySelectNoSpinner" class="category-select" v-model="selectedCategoryFull" @change="handleCategoryChange"  v-if="!isGroupNomination">
    <option v-for="category in listNomination" :key="category.individualCategory.id" :value="category.individualCategory.id">
      {{ category.individualCategory.individualCategory }} - {{ category.individualCategory.rank }} ({{ category.individualCategory.fromAge }} - {{ category.individualCategory.beforeAge }})
    </option>
  </select>
  <select id="categorySelectNoSpinner" class="category-select" v-model="selectedCategoryFull" @change="handleCategoryChange" v-else>
    <option v-for="category in listNomination" :key="category.groupCategory.id" :value="category.groupCategory.id">
      {{ category.groupCategory.type }}- {{ category.groupCategory.individualCategory }} - {{ category.groupCategory.rank }} ({{ category.groupCategory.fromAge }} - {{ category.groupCategory.beforeAge }})
    </option>
  </select>
</div>

<!-- Селектор для выбора видов -->
<div v-if="selectedCategory && selectedCategory.performedTypes.length" class="form-group checkboxes-group">
  <label>Виды</label>
  <div class="checkboxes">
    <div v-for="type in selectedCategory.performedTypes" :key="type.id" class="checkbox-item">
      <input
          type="checkbox"
          :id="`type-${type.id}`"
          :value="type.name"
          v-model="selectedViews"
      />
      <label :for="`type-${type.id}`">{{ type.name }}</label>
    </div>
  </div>
</div>

<!-- Поле ввода времени на 1 выступление -->
<div class="form-group__row">

  <div class="form-group__row-item">
    <TimeHint :timeInputs="timeHints" />
    <label for="timePerPerformance">Время на 1 выступление (секунды)</label>
    <input type="number" id="timePerPerformance" class="input" v-model="timePerPerformance" min="0" />
  </div>

  <!-- Поле для ввода диапазона количества спортсменов в потоке -->
  <div class="form-group__row-item">
    <label>Кол-во выст. в потоке</label>
    <div class="range-inputs">
      <input
          type="number"
          id="minNumberOfAthletes"
          class="input range-input"
          v-model.number="countPerformance"
          placeholder="1"
          min="1"
          max="100"
      />
    </div>
  </div>
</div>
<div class="form-group-btn">
  <!-- Кнопка создания потока -->
  <button class="mandated__tag active" @click="createStreamNoSpinner">Создать новый поток</button>
</div>
</div>
</template>
<script >

import TimeHint from '@/components/TimeHint.vue'

export default {
  components: { TimeHint },
  name:'AppCreateFlowNoSpinner',
  data() {
    return {
      selectedCategoryFull: null,
      selectedViews: [],
      timePerPerformance: '',
      countPerformance: '',
      orderPerformance: '',
      timeHints: ["1", "1.30", "1.45", "2", "3"], // Изначальные значения подсказок
    };
  },
  computed: {
    selectedCategory() {
      // Проверяем, что selectedCategoryFull и id определены
      if (this.selectedCategoryFull && this.selectedCategoryFull.id) {
        // Если isGroupNomination true — ищем по groupCategory.id
        if (this.isGroupNomination) {
          return this.listNomination.find(
              (category) => category.groupCategory && category.groupCategory.id === this.selectedCategoryFull.id
          );
        }
        // Если isGroupNomination false — ищем по individualCategory.id
        else {
          return this.listNomination.find(
              (category) => category.individualCategory && category.individualCategory.id === this.selectedCategoryFull.id
          );
        }
      }
      // Если условие не выполняется, возвращаем undefined
      return undefined;
    },
  },
  props: {
    listNomination: {
      type: Array,
    },
    about_start_list: {
      type: Object,
    },
    isGroupNomination: {
          type: Boolean,
    }
  },
  methods: {
    createStreamNoSpinner() {
      this.orderPerformance = 'noSpinner'
      console.log('Создание потока без вертушки', this.selectedViews);
    },
    handleCategoryChange() {
      this.selectedViews = [];
      this.selectedView1 = '';
      this.selectedView2 = '';
    },
  },

}
</script>
