<template>
  <!-- Отображение собранных заявок -->
  <!-- this.aboutRegistrApp.judges -->
  <div class="loader-container">
    <div class="loader-overlay-mini" v-if="isLoadingRegJuj">
      <div class="loader-spinner"></div>
    </div>
  <div class="search">
    <item-app-juj
      v-for="(item, index) in this.aboutRegistrApp.judges"
      :key="index"
      @deleteItemJuj="deleteItemJuj"
      :item_juj="item"
      :index="index"
    />

    <!-- Создание собранных заявок -->
    <div
      class="search__wrap-block-select"
      v-if="(this.aboutRegistrApp.status !== 'CLOSE_EDIT_APPLICATION')"
    >
      <div class="search__add-coach">
        <app-add-judge-select
            @input="safeJujPeople"
                               :default="this.defaultCoach"
                               :options="this.allJuj"  :windowWidth="isMobile"/>
      </div>
    </div>
    <div class="button-add__title-with-svg"  @click="openModal('isShowAddJudge')">
      <div class="button-add__title">
        <p class="button-add__title-text">Добавить нового судью в заявку</p>
      </div>

      <div class="button-add__wrap-svg">
        <img src="../../assets/svg/plus.svg" alt="plus" />
      </div>
    </div>
        <div class="validation" v-if="this.validationErrorsAddJuj !== null">
          <p class="validation__text">{{ validationErrorsAddJuj.message }}</p>
        </div>
    <div class="about-application" v-if="this.aboutRegistrApp">
      <p class="about-application__text">
        Итого в заявке:
        {{ this.aboutRegistrApp.judges.length }}
        судей
      </p>
    </div>
    <app-modal-add-juj  v-if="isShowAddJudge"  type-add="application"  @close="closeModal('isShowAddJudge')"/>
  </div>
  </div>
</template>

<script>
import { actionTypes } from "@/store/modules/registration";
import { mapState } from "vuex";
import ItemAppJuj from "./ItemAppJuj.vue";
import AppAddJudgeSelect from '@/components/customSelect/AddJudgeSelect.vue'
import AppModalAddJuj from '@/components/Modal/ModalAddJuj.vue'
export default {
  name: "AppJujApplication",
  data() {
    return {
      defaultCategory: "Категория группы",
      defaultCoach: "Выберите судью из списка ",
      dataIdToIndSportsmen: {},
      isShowAddJudge: false,
      windowWidth: window.innerWidth, // Инициализируем начальное значение
    };
  },

  computed: {
    ...mapState({
      isLoading: (state) => state.registration.isLoading,
      isLoadingRegJuj: (state) => state.registration.isLoadingRegJuj,
      individualCategories: (state) => state.registration.individualCategories,
      individualSportsmens: (state) => state.registration.individualSportsmens,
      aboutRegistrApp: (state) => state.registration.aboutRegistrApp,
      allJuj: (state) => state.enteredData.allJuj,
      validationErrorsAddJuj: (state) => state.registration.validationErrorsAddJuj,
    }),
    isMobile() {
      return this.windowWidth <= 1024; // Логика определения мобильного режима
    },
  },
  components: {
    AppModalAddJuj,
    AppAddJudgeSelect,
    ItemAppJuj,
  },
  methods: {
    closeModal(modalProperty) {
      this[modalProperty] = false;
      // Проверяем, нужно ли оставить скролл заблокированным (если есть другие открытые модальные окна)
        document.body.classList.remove('no-scroll');
      this.scrollToEndOfPage()
    },
    openModal(modalProperty) {
      this[modalProperty] = true;
      document.body.classList.add('no-scroll');
    },
    scrollToEndOfPage() {
      // Прокрутка страницы вниз до конца
      window.scrollTo({
        top: document.body.scrollHeight, // Прокрутить до конца страницы
        behavior: 'smooth', // Плавная прокрутка
      });
    },

    deleteItemJuj(item) {
      console.log(item);
      this.aboutRegistrApp.judges.splice(
        0,
        this.aboutRegistrApp.judges.length,
        ...this.aboutRegistrApp.judges.filter((n) => n.id !== item.id)
      );
      this.$store
        .dispatch(actionTypes.deleteJujApp, {
          idCompetition: this.$route.params.id,
          appIdJudges: item.id,
          // idSportsmen: item.sportsmen.id,
        })
        .then(() => {})
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
    safeJujPeople(item) {
      console.log(item)
      this.$store
        .dispatch(actionTypes.addJujApp, {
          idCompetition: this.$route.params.id,
          idJudge: item.judges,
        })
        .then(() => {})
        .catch((er) => {
          console.log(er, "Error");
        });
    },
    showModalAddJuj() {
      this.$emit("showModalAddJuj");
    },
  },
};
</script>

<style></style>
