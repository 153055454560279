import createStartList from "@/api/createStartList.js";

const state = {
  isLoading: false, //ожидание запроса
  isLoadingDeleteBreak: false,
  isLoadingAddBreak: false,
  validationErrors: null, //ошибки
  aboutCompetitionForStartList: null,
  listNomination: [],
  listGroupNomination: [],
  newList: [],
  newListGroup: [],
  listPerformance: [],
  countFreePerformance: null,
};

export const mutationTypes = {
  aboutCompStartListStart: "[createStartList] aboutCompStartListStart",
  aboutCompStartListSuccess: "[createStartList] aboutCompStartListSuccess",
  aboutCompStartListFailure: "[createStartList] aboutCompStartListFailure",

  getNominationsListStart: "[createStartList]  getNominationsListStart",
  getNominationsListSuccess: "[createStartList]  getNominationsListSuccess",
  getNominationsListFailure: "[createStartList]  getNominationsListFailure",

  getNominationGroupListStart: "[createStartList]  getNominationGroupListStart",
  getNominationGroupListSuccess:
    "[createStartList]  getNominationGroupListSuccess",
  getNominationGroupListFailure:
    "[createStartList]  getNominationGroupListFailure",

  postAllInfoAboutStartListStart:
    "[createStartList] postAllInfoAboutStartListStart",
  postAllInfoAboutStartListSuccess:
    "[createStartList] postAllInfoAboutStartListSuccess",
  postAllInfoAboutStartListFailure:
    "[createStartList] postAllInfoAboutStartListFailure",

  //
  addFlowInProtocolStart:
      "[createStartList] addFlowInProtocolStart",
  addFlowInProtocolSuccess:
      "[createStartList] addFlowInProtocolSuccess",
  addFlowInProtocolFailure:
      "[createStartList] addFlowInProtocolFailure",
  deleteFlowProtocolStart:
      "[createStartList] deleteFlowProtocolStart",
  deleteFlowProtocolSuccess:
      "[createStartList] deleteFlowProtocolSuccess",
  deleteFlowProtocolFailure:
      "[createStartList] deleteFlowProtocolFailure",
  getStartListPerformanceStart:
      "[createStartList] getStartListPerformanceStart",
  getStartListPerformanceSuccess:
      "[createStartList] getStartListPerformanceSuccess",
  getStartListPerformanceFailure:
      "[createStartList] getStartListPerformanceFailure",
  addBreakFlowInProtocolStart: "[createStartList] addBreakFlowInProtocolStart",
  addBreakFlowInProtocolSuccess:"[createStartList] addBreakFlowInProtocolSuccess",
  addBreakFlowInProtocolFailure: "[createStartList] addBreakFlowInProtocolFailure",

  deleteBreakFlowProtocolStart: "[createStartList] deleteBreakFlowProtocolStart",
  deleteBreakFlowProtocolSuccess:"[createStartList] deleteBreakFlowProtocolSuccess",
  deleteBreakFlowProtocolFailure: "[createStartList] deleteBreakFlowProtocolFailure",

  saveChangeAllFlowSubmitStart: "[createStartList] saveChangeAllFlowSubmitStart",
  saveChangeAllFlowSubmitSuccess:"[createStartList] saveChangeAllFlowSubmitSuccess",
  saveChangeAllFlowSubmitFailure: "[createStartList] saveChangeAllFlowSubmitFailure",


  changePositionFlowStart: "[createStartList] changePositionFlowStart",
  changePositionFlowSuccess:"[createStartList] changePositionFlowSuccess",
  changePositionFlowFailure: "[createStartList] changePositionFlowFailure",

  changePositionBreakStart: "[createStartList] changePositionBreakStart",
  changePositionBreakSuccess:"[createStartList] changePositionBreakSuccess",
  changePositionBreakFailure: "[createStartList] changePositionBreakFailure",

  joinFlowInStartListStart: "[createStartList] joinFlowInStartListStart",
  joinFlowInStartListSuccess: "[createStartList] joinFlowInStartListSuccess",
  joinFlowInStartListFailure:"[createStartList] joinFlowInStartListFailure",


};
export const actionTypesStartList = {
  aboutCompStartList: "[createStartList] aboutCompStartList",
  getNominationsList: "[createStartList] getNominationsList",
  postAllInfoAboutStartList: " [createStartList] postAllInfoAboutStartList",
  getNominationGroupList: "[createStartList] getNominationGroupList",
  //НОВЫЕ
  addFlowInProtocol:  "[createStartList] addFlowInProtocol",
  deleteFlowProtocol: "[createStartList] deleteFlowInProtocol",
  getStartListPerformance: "[createStartList] getStartListPerformance",

  addBreakFlowInProtocol: "[createStartList] addBreakFlowInProtocol",
  deleteBreakFlowProtocol: "[createStartList] deleteBreakFlowProtocol",
  saveChangeAllFlowSubmit: "[createStartList] saveChangeAllFlowSubmit",
  changePositionFlow: "[createStartList] changePositionFlow",
  changePositionBreak: "[createStartList] changePositionBreak",

  joinFlowInStartList: "[createStartList] joinFlowInStartList",
};

const mutations = {
  [mutationTypes.aboutCompStartListStart](state) {
    state.validationErrors = null;
    state.isLoading = true;
  },
  [mutationTypes.aboutCompStartListSuccess](state, response) {
    state.aboutCompetitionForStartList = response.result;
    state.isLoading = false;
  },
  [mutationTypes.aboutCompStartListFailure](state, payload) {
    state.validationErrors = payload;
    state.isLoading = false;
  },

  [mutationTypes.getNominationsListStart](state) {
    state.validationErrors = null;
    state.isLoading = true;
  },
  [mutationTypes.getNominationsListSuccess](state, response) {
    state.isLoading = false;
    state.listNomination = response.result;
    state.newList = state.listNomination.map((itemElem) => [
      ...itemElem.performedTypes.map((item) => {
        return {
          // ...item,
          name:
            "Вид: " +
            item.name +
            "; Возраст: " +
            itemElem.individualCategory.fromAge +
            "-" +
            itemElem.individualCategory.beforeAge +
            " " +
            "Категория " +
            itemElem.individualCategory.individualCategory,
          idCategory: itemElem.individualCategory.id,
          rank: itemElem.individualCategory.rank,
          fromAge: itemElem.individualCategory.fromAge,
          beforeAge: itemElem.individualCategory.beforeAge,
          individualCategory: itemElem.individualCategory.individualCategory,
          category: item.name,
          order: null,
          union: false,
          type: "Индивидуальная",
        };
      }),
    ]);
    state.newList = [].concat.apply([], state.newList);
  },
  [mutationTypes.getNominationsListFailure](state, payload) {
    state.validationErrors = payload;
    state.isLoading = false;
  },

  [mutationTypes.getNominationGroupListStart](state) {
    state.validationErrors = null;
    state.isLoading = true;
  },
  [mutationTypes.getNominationGroupListSuccess](state, response) {
    state.isLoading = false;
    state.listGroupNomination = response.result;
    state.newListGroup = state.listGroupNomination.map((itemElem) => [
      ...itemElem.performedTypes.map((item) => {
        return {
          name:
            "Вид: " +
            item.name +
            "; Возраст: " +
            itemElem.groupCategory.fromAge +
            "-" +
            itemElem.groupCategory.beforeAge +
            " " +
            "Категория " +
            itemElem.groupCategory.individualCategory,
          idCategory: itemElem.groupCategory.id,
          fromAge: itemElem.groupCategory.fromAge,
          rank: itemElem.groupCategory.rank,
          beforeAge: itemElem.groupCategory.beforeAge,
          individualCategory: itemElem.groupCategory.individualCategory,
          category: item.name,
          order: null,
          union: false,
          type: "Группы",
        };
      }),
    ]);
    state.newListGroup = [].concat.apply([], state.newListGroup);
  },
  [mutationTypes.getNominationGroupListFailure](state, payload) {
    state.validationErrors = payload;
    state.isLoading = false;
  },

  [mutationTypes.postAllInfoAboutStartListStart](state) {
    state.validationErrors = null;
    state.isLoading = true;
  },
  [mutationTypes.postAllInfoAboutStartListSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.postAllInfoAboutStartListFailure](state, payload) {
    state.validationErrors = payload;
    state.isLoading = false;
  },
  // addFlowInProtocol
  [mutationTypes.addFlowInProtocolStart](state) {
    state.validationErrors = null;
    state.isLoadingAddFlow = true;
  },
  [mutationTypes.addFlowInProtocolSuccess](state, count) {
    state.isLoadingAddFlow = false;
    state.countFreePerformance = count;
  },
  [mutationTypes.addFlowInProtocolFailure](state, payload) {
    state.validationErrors = payload;
    state.isLoadingAddFlow = false;
  },
  // deleteFlowProtocol
  [mutationTypes.deleteFlowProtocolStart](state) {
    state.validationErrors = null;
    state.isLoadingAddFlow = true;
  },
  [mutationTypes.deleteFlowProtocolSuccess](state) {
    state.isLoadingAddFlow = false;
    // state.listPerformance = flow;
  },
  [mutationTypes.deleteFlowProtocolFailure](state, payload) {
    state.validationErrors = payload;
    state.isLoadingAddFlow = false;
  },
  // getStartListPerformance
  [mutationTypes.getStartListPerformanceStart](state) {
    state.validationErrors = null;
    state.isLoadingAddFlow = true;
  },
  [mutationTypes.getStartListPerformanceSuccess](state, list) {
    state.isLoadingAddFlow = false;
    state.listPerformance = list;
  },
  [mutationTypes.getStartListPerformanceFailure](state, payload) {
    state.validationErrors = payload;
    state.isLoadingAddFlow = false;
  },
  [mutationTypes.addBreakFlowInProtocolStart](state) {
    state.validationErrorsAddBreak = null;
    state.isLoadingAddBreak = true;
  },
  [mutationTypes.addBreakFlowInProtocolSuccess](state,) {
    state.isLoadingAddBreak = false;
  },
  [mutationTypes.addBreakFlowInProtocolFailure](state, payload) {
    state.validationErrorsAddBreak = payload;
    state.isLoadingAddBreak = false;
  },
  // deleteBreakFlowProtocol
  [mutationTypes.deleteBreakFlowProtocolStart](state) {
    state.validationErrorsAddBreak = null;
    state.isLoadingDeleteBreak = true;
  },
  [mutationTypes.deleteBreakFlowProtocolSuccess](state,) {
    state.isLoadingDeleteBreak = false;
  },
  [mutationTypes.deleteBreakFlowProtocolFailure](state, payload) {
    state.validationErrorsAddBreak = payload;
    state.isLoadingDeleteBreak = false;
  },
  // saveChangeAllFlowSubmit
  [mutationTypes.saveChangeAllFlowSubmitStart](state) {
    state.validationErrorsAddBreak = null;
    state.isLoadingAddFlow = true;
  },
  [mutationTypes.saveChangeAllFlowSubmitSuccess](state,) {
    state.isLoadingAddFlow = false;
  },
  [mutationTypes.saveChangeAllFlowSubmitFailure](state, payload) {
    state.validationErrorsAddBreak = payload;
    state.isLoadingAddFlow = false;
  },
  // changePositionFlow
  [mutationTypes.changePositionFlowStart](state) {
    state.validationErrorsAddBreak = null;
    state.isLoadingAddFlow = true;
  },
  [mutationTypes.changePositionFlowSuccess](state,) {
    state.isLoadingAddFlow = false;
  },
  [mutationTypes.changePositionFlowFailure](state, payload) {
    state.validationErrorsAddBreak = payload;
    state.isLoadingAddFlow = false;
  },
  // changePositionBreak
  [mutationTypes.changePositionBreakStart](state) {
    state.validationErrorsAddBreak = null;
    state.isLoadingAddFlow = true;
  },
  [mutationTypes.changePositionBreakSuccess](state,) {
    state.isLoadingAddFlow = false;
  },
  [mutationTypes.changePositionBreakFailure](state, payload) {
    state.validationErrorsAddBreak = payload;
    state.isLoadingAddFlow = false;
  },
  // joinFlowInStartList
  [mutationTypes.joinFlowInStartListStart](state) {
    state.validationErrorsAddBreak = null;
    state.isLoadingAddFlow = true;
  },
  [mutationTypes.joinFlowInStartListSuccess](state) {
    state.isLoadingAddFlow = false;
  },
  [mutationTypes.joinFlowInStartListFailure](state, payload) {
    state.validationErrorsAddBreak = payload;
    state.isLoadingAddFlow = false;
  },
};
const actions = {
  [actionTypesStartList.aboutCompStartList](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.aboutCompStartListStart);
      createStartList
        .aboutCompStartList(credentials)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.aboutCompStartListSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.aboutCompStartListFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.aboutCompStartListFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  [actionTypesStartList.getNominationsList](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getNominationsListStart);
      createStartList
        .getNominationsList(credentials)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.getNominationsListSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.getNominationsListFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.getNominationsListFailure, err);
          console.log("ошибка", err);
        });
    });
  },

  [actionTypesStartList.getNominationGroupList](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getNominationGroupListStart);
      createStartList
        .getNominationGroupList(credentials)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.getNominationGroupListSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.getNominationGroupListFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.getNominationGroupListFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  [actionTypesStartList.postAllInfoAboutStartList](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.postAllInfoAboutStartListStart);
      createStartList
        .postAllInfoAboutStartList(credentials)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.postAllInfoAboutStartListSuccess);
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.postAllInfoAboutStartListFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.postAllInfoAboutStartListFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  //
  [actionTypesStartList.addFlowInProtocol](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.addFlowInProtocolStart);
      createStartList
          .addFlowInProtocol(credentials)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.addFlowInProtocolSuccess, response.data.result);
              resolve(response.data);
            } else {
              context.commit(
                  mutationTypes.addFlowInProtocolFailure,
                  response.data
              );
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.addFlowInProtocolFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // deleteFlowProtocol
  [actionTypesStartList.deleteFlowProtocol](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.deleteFlowProtocolStart);
      createStartList
          .deleteFlowProtocol(credentials)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.deleteFlowProtocolSuccess, response.data);
              resolve(response.data);
            } else {
              context.commit(
                  mutationTypes.deleteFlowProtocolFailure,
                  response.data
              );
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.deleteFlowProtocolFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // getStartListPerformance
  [actionTypesStartList.getStartListPerformance](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getStartListPerformanceStart);
      createStartList
          .getStartListPerformance(credentials)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.getStartListPerformanceSuccess, response.data.result);
              resolve(response.data);
            } else {
              context.commit(
                  mutationTypes.getStartListPerformanceFailure,
                  response.data
              );
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.getStartListPerformanceFailure, err);
            console.log("ошибка", err);
          });
    });
  },

  [actionTypesStartList.addBreakFlowInProtocol](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.addBreakFlowInProtocolStart);
      createStartList
          .addBreakFlowInProtocol(credentials)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.addBreakFlowInProtocolSuccess);
              resolve(response.data);
            } else {
              context.commit(
                  mutationTypes.addBreakFlowInProtocolFailure,
                  response.data
              );
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.addBreakFlowInProtocolFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // deleteBreakFlowProtocol
  [actionTypesStartList.deleteBreakFlowProtocol](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.deleteBreakFlowProtocolStart);
      createStartList
          .deleteBreakFlowProtocol(credentials)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.deleteBreakFlowProtocolSuccess);
              resolve(response.data);
            } else {
              context.commit(
                  mutationTypes.deleteBreakFlowProtocolFailure,
                  response.data
              );
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.deleteBreakFlowProtocolFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // saveChangeAllFlowSubmit
  [actionTypesStartList.saveChangeAllFlowSubmit](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.saveChangeAllFlowSubmitStart);
      createStartList
          .saveChangeAllFlowSubmit(credentials)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.saveChangeAllFlowSubmitSuccess);
              resolve(response.data);
            } else {
              context.commit(
                  mutationTypes.saveChangeAllFlowSubmitFailure,
                  response.data
              );
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.saveChangeAllFlowSubmitFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // changePositionFlow
  [actionTypesStartList.changePositionFlow](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.changePositionFlowStart);
      createStartList
          .changePositionFlow(credentials)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.changePositionFlowSuccess);
              resolve(response.data);
            } else {
              context.commit(
                  mutationTypes.changePositionFlowFailure,
                  response.data
              );
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.changePositionFlowFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  [actionTypesStartList.joinFlowInStartList](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.joinFlowInStartListStart);
      createStartList
          .joinFlowInStartList(credentials)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.joinFlowInStartListSuccess);
              resolve(response.data);
            } else {
              context.commit(
                  mutationTypes.joinFlowInStartListFailure,
                  response.data
              );
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.changePositionFlowFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // changePositionBreak
  [actionTypesStartList.changePositionBreak](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.changePositionBreakStart);
      createStartList
          .changePositionBreak(credentials)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.changePositionBreakSuccess);
              resolve(response.data);
            } else {
              context.commit(
                  mutationTypes.changePositionBreakFailure,
                  response.data
              );
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.changePositionBreakFailure, err);
            console.log("ошибка", err);
          });

    });
    //

  },
};
export default {
  state,
  mutations,
  actions,
};
