<template>
  <div class="routing-menu-container">
    <div class="routing-menu-in-page">
      <router-link to="/" class="routing-menu-in-page__link">
        <div class="routing-menu-in-page__block">
          <div class="routing-menu-in-page__img-wrap">
            <img src="../assets/svg/podium.svg" class="routing-menu-in-page__img" alt="podium">
          </div>
          <div class="routing-menu-in-page__text-wrap">
            <p class="routing-menu-in-page__text">Выбрать соревнования</p>
          </div>
        </div>
      </router-link>

      <router-link to="/applications" class="routing-menu-in-page__link">
        <div class="routing-menu-in-page__block">
          <div class="routing-menu-in-page__img-wrap">
            <img src="../assets/svg/menu.svg" class="routing-menu-in-page__img" alt="applications">
          </div>
          <div class="routing-menu-in-page__text-wrap">
            <p class="routing-menu-in-page__text">Мои заявки</p>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: "AppMiniRouting",
    data() {
      return {
      };
    },
    computed: {
      ...mapState({
        token: (state) => state.auth.token,

      }),
    },
  };
</script>

<style></style>
